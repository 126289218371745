/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState } from "react";

import FadeInSection from "../Utils/FadeInSection";

import "../../style/homepage.css";

/**
 * This component is used to manage the 'Modules' page of the application
 */
function Modules() {
    const [isNational, setNational] = useState(false);

    const nationalClass = isNational ? " not-national" : "";

    const returnTop = (
        <>
            &nbsp;
            <a href="#root" className="return-top">
                &uarr;
            </a>
        </>
    );

    return (
        <div className="widgets full-screen-widget">
            <div className="tout about">
                <div className="img larger-logo">
                    <img
                        src="../../img/logo_TerriSTORY_transparent.png"
                        alt="Logo de TerriSTORY®"
                    ></img>
                </div>
                <div className="texte-corps">
                    <div className="text-block">
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>L'outil</h1>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <h2>Vue d'ensemble</h2>
                                <p>
                                    TerriSTORY® a pour but de remplir les objectifs
                                    suivants :
                                </p>
                                <ol>
                                    <li>
                                        comprendre son territoire, réaliser un{" "}
                                        <span className="color1">
                                            <strong>diagnostic multi-thématique</strong>
                                        </span>{" "}
                                        à partir de données de qualité
                                    </li>
                                    <li>
                                        analyser les dynamiques à l'oeuvre sur
                                        territoire et les{" "}
                                        <span className="color2">
                                            <strong>trajectoires historiques</strong>
                                        </span>{" "}
                                        des principaux indicateurs autour du climat et
                                        de l'énergie
                                    </li>
                                    <li>
                                        <strong>
                                            <span className="color3">
                                                simuler des scénarios prospectifs
                                            </span>
                                        </strong>{" "}
                                        et d'en mesurer les impacts socio-économiques et
                                        environnementaux, pour{" "}
                                        <strong>
                                            construire une trajectoire territoriale
                                        </strong>{" "}
                                        à la hauteur des enjeux et des objectifs.
                                    </li>
                                </ol>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <img
                                    alt="Découvrez une interface : Interactive, Intuitive, Évolutive"
                                    title="Les avantages de l'interface de TerriSTORY"
                                    src="/img/national/interface.jpg"
                                    className="map"
                                />
                            </FadeInSection>
                        </div>
                        <div className="whole-width">
                            <FadeInSection>
                                <h2>Les modules</h2>
                                <p>
                                    Pour cela, l'outil est séparé en{" "}
                                    <strong>cinq modules principaux</strong> décrits
                                    ci-dessous. Ces modules sont déployés dans chaque
                                    région par l'équipe de développement de TerriSTORY®
                                    puis alimentés et personnalisés par les{" "}
                                    <strong>
                                        administrateurs régionaux de TerriSTORY®
                                    </strong>
                                    . Ceux-ci choisissent entre autres les données et
                                    indicateurs qui figurent dans l'outil. Cela peut
                                    expliquer des différences de fonctionnalités et de
                                    contenus d'une région à l'autre.
                                </p>
                                <div className="list-modules">
                                    <div className={nationalClass}>
                                        <img
                                            src="/img/national/icon_indicateur.svg"
                                            alt="indicateur"
                                        />
                                        <label>
                                            <a href="#module-indicateur">indicateurs</a>
                                        </label>
                                    </div>
                                    <div className={nationalClass}>
                                        <img
                                            src="/img/national/icon_installation.svg"
                                            alt="installation"
                                        />
                                        <label>
                                            <a href="#module-installation">
                                                équipements
                                            </a>
                                        </label>
                                    </div>
                                    <div className={nationalClass}>
                                        <img
                                            src="/img/national/icon_dashboard.svg"
                                            alt="dashboard"
                                        />
                                        <label>
                                            <a href="#module-dashboard">
                                                tableaux de bord
                                            </a>
                                        </label>
                                    </div>
                                    <div className={nationalClass}>
                                        <img
                                            src="/img/national/icon_analyse.svg"
                                            alt="analyse"
                                        />
                                        <label>
                                            <a href="#module-analyse">analyse</a>
                                        </label>
                                    </div>
                                    <div>
                                        <img
                                            src="/img/national/icon_plan_action.svg"
                                            alt="plan_action"
                                        />
                                        <label>
                                            <a href="#module-plan_action">
                                                simulateurs et stratégie territoriale
                                            </a>
                                        </label>
                                    </div>
                                </div>
                            </FadeInSection>
                        </div>
                        <div className="whole-width">
                            <FadeInSection>
                                <h2>Une version nationale</h2>
                                <p>
                                    Depuis septembre 2024, certains des modules suivants
                                    sont déployés pour n'importe quelle région de France
                                    métropolitaine, y compris les régions non membres du
                                    consortium TerriSTORY®. Pour visualiser quels sont
                                    les indicateurs présents, vous pouvez cliquer sur le
                                    bouton ci-dessous :
                                </p>
                                <div className="category-switch">
                                    <label
                                        key="before"
                                        className="switch-labels"
                                        htmlFor="national-version-switch-button"
                                    >
                                        Territoire d'une région membre
                                    </label>
                                    <label key="button" className="switch">
                                        <input
                                            type="checkbox"
                                            id="national-version-switch-button"
                                            name="national-version-switch-button"
                                            onChange={() => setNational(!isNational)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <label
                                        key="after"
                                        htmlFor="national-version-switch-button"
                                        className="switch-labels"
                                    >
                                        Territoire hors consortium
                                    </label>
                                </div>
                            </FadeInSection>
                        </div>
                    </div>
                    <div
                        className={"text-block" + nationalClass}
                        id="module-indicateur"
                    >
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>Le module d'indicateurs{returnTop}</h1>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <img
                                    alt="carte"
                                    src="/img/national/carte.png"
                                    title="Exemple de cartographie d'indicateurs en région Auvergne-Rhône-Alpes"
                                    className="map"
                                />
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <p>
                                    Ce module est le{" "}
                                    <strong>point d'entrée de TerriSTORY®</strong> et
                                    permet la visualisation d'indicateurs sur une
                                    représentation cartographique. Des diagrammes en bas
                                    de page donnent accès aux détails sur certaines
                                    données.
                                </p>
                            </FadeInSection>
                        </div>
                    </div>
                    <div
                        className={"text-block" + nationalClass}
                        id="module-installation"
                    >
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>
                                    Le module d'équipements
                                    {returnTop}
                                </h1>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <p>
                                    L'affichage de points d'intérêts (installations de
                                    production d'énergie, de traitement des déchets,
                                    réseaux de chaleur, projets marquants, etc.)
                                    enrichit la carte avec des informations
                                    géolocalisées.
                                </p>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <img
                                    alt="carte"
                                    src="/img/national/carte_poi.png"
                                    title="Exemple de carte d'équipements en région Bretagne"
                                    className="map"
                                />
                            </FadeInSection>
                        </div>
                    </div>
                    <div className={"text-block" + nationalClass} id="module-dashboard">
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>
                                    Le module de tableaux de bord
                                    {returnTop}
                                </h1>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <img
                                    alt="carte"
                                    src="/img/national/tableaux_bord.png"
                                    title="Exemple de tableaux de bord en région Nouvelle-Aquitaine"
                                    className="map"
                                />
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <p>
                                    Les tableaux de bord rassemblent une seule et même
                                    page un grand nombre d'indicateurs et de
                                    représentations qui fournissent un aperçu transverse
                                    ou encore une analyse précise sur une thématique au
                                    visiteur. Il est possible à un utilisateur connecté
                                    de se créer ses propres tableaux de bord en
                                    sélectionnant les indicateurs souhaités.
                                </p>
                            </FadeInSection>
                        </div>
                    </div>
                    <div className={"text-block" + nationalClass} id="module-analyse">
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>Les modules d'analyse{returnTop}</h1>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <p>
                                    Plusieurs outils différents permettent d'analyser
                                    dans le détail les{" "}
                                    <strong>trajectoires historiques</strong> des
                                    principaux indicateurs climat-air-énergie ou encore
                                    les diagrammes de flux énergétiques des territoires.
                                </p>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <img
                                    alt="carte"
                                    src="/img/national/suiv_traj.png"
                                    title="Exemple de suivi de trajectoire en région Occitanie"
                                    className="map"
                                />
                                <img
                                    alt="carte"
                                    src="/img/national/sankey.png"
                                    title="Exemple de diagramme Sankey en région Occitanie"
                                    className="map"
                                />
                            </FadeInSection>
                        </div>
                    </div>
                    <div className="text-block" id="module-plan_action">
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>
                                    Les modules de simulation et de stratégie
                                    {returnTop}
                                </h1>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <img
                                    alt="carte"
                                    src="/img/national/strategie.png"
                                    title="Exemple de trajectoire calculée en région Auvergne-Rhône-Alpes"
                                    className="map"
                                />
                                <img
                                    alt="carte"
                                    src="/img/national/simulateur.png"
                                    title="Exemple du simulateur mobilité en région Pays de la Loire"
                                    className="map"
                                />
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <p>
                                    Modules les plus complexes de TerriSTORY®, le
                                    simulateur et le module de stratégie territoriale
                                    fournissent une capacité de{" "}
                                    <strong>simuler la mise en place d'actions</strong>.
                                    Après avoir défini une trajectoire, élaboré un plan
                                    d'action, l'utilisateur peut alors en mesurer les
                                    impacts socio-économiques et environnementaux.
                                </p>
                            </FadeInSection>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modules;
