/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactDOM from "react-dom";
import ReactTable from "react-table-6";

import Api from "../Controllers/Api";

import config from "../settings.js";
import configData from "../settings_data.js";
import { buildRegionUrl } from "../utils.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { normalize } from "../utils.js";

/**
 * Ce composant permet de gérer les scénarios des plans d'actions
 */
class PlanActionsScenarii extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scenariiList: [],
            currScenario: undefined,
            statusType: "",
            status: "",
            years: [],
            is_national: this.props.parentApi.data.settings["is_national"],
        };

        if (this.props.parentApi.data.connected) {
            this.getScenarii();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.chargementFichier !== prevProps.chargementFichier) {
            let formData = new FormData();
            formData.append("fichier", this.props.fichierDonnees);
            formData.append("nomFichier", this.props.fichierDonnees.name);
            // Appel à l'api avec le fichier et les métadonnées saisies par l'utilisateur
            Api.callApi(
                buildRegionUrl(
                    config.api_charger_params_strategie,
                    this.props.parentApi.data.region
                ),
                formData,
                "POST",
                "default"
            ) // Appel à l'API nécessaire sinon, this.state n'est pas défini dans planAction.js
                .then((response) => {
                    for (let action of this.props.listeDesActions) {
                        let elem = document.getElementById(action);
                        elem.click();
                    }
                    this.props.chargerParametresStrategies();
                })
                .catch((e) => {
                    this.setState({
                        status: e.message,
                        statusType: "error",
                    });
                });
        }

        if (
            prevProps.parentApi.data.connected !== this.props.parentApi.data.connected
        ) {
            this.getScenarii();
        }
    }

    componentDidMount() {
        // get years from the API using conso-ener as a reference
        let url = buildRegionUrl(
            config.api_liste_annees_url.replace(
                "#table#",
                "DataSet.CONSUMPTION" // we use conso_ener as a reference
            ),
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            let years = [];
            // start with the year following the year of data availability
            for (
                let y = response[response.length - 1].value + 1;
                y <= configData.planActionDerniereAnnee;
                y++
            ) {
                years.push(y);
            }

            // Set states
            this.setState({
                years: years,
            });
        });
    }

    // Get scenarii list for the current user
    getScenarii() {
        let url = buildRegionUrl(
            config.user_scenario_list_url,
            this.props.parentApi.data.region
        );
        if (this.state.is_national) {
            url = buildRegionUrl(
                config.user_scenario_list_national_url.replace(
                    "#territory#",
                    this.props.parentApi.data.region
                ),
                "national"
            );
        }
        Api.callApi(url, null, "GET")
            .then((response) => {
                this.setState({ scenariiList: response });
            })
            .catch((e) =>
                this.setState({
                    status: "Impossible de récupérer la liste des scénarii.",
                    statusType: "error",
                })
            );
    }

    render() {
        if (!this.props.parentApi.data.connected) return "";

        // gather params and advanced params
        const gatherParams = (params) => {
            this.props.coeffs.forEach((coeff) => {
                params[coeff.nom] = coeff.valeur_annee;
            });

            params["advanced"] = this.props.coeffsAdvanced;

            // On transforme les trajectoiresCibles en dictionnaire (s'il ne l'est pas déjà)
            let trajectoires = {};
            for (let traj of Object.keys(this.props.trajectoiresCibles)) {
                if (this.props.trajectoiresCibles[traj].annees) {
                    trajectoires[traj] = {};
                    trajectoires[traj].annees_valeurs = {};
                    trajectoires[traj].annees_modifiees = {};
                    for (let a in this.props.trajectoiresCibles[traj].annees) {
                        trajectoires[traj].annees_valeurs[
                            this.props.trajectoiresCibles[traj].annees[a]
                        ] = this.props.trajectoiresCibles[traj].valeurs[a];
                        trajectoires[traj].annees_modifiees[
                            this.props.trajectoiresCibles[traj].annees[a]
                        ] = this.props.trajectoiresCibles[traj].annees_modifiees[a];
                    }
                } else {
                    trajectoires[traj] = this.props.trajectoiresCibles[traj];
                }
            }

            // Si aucune trajectoire n'a été saisie, il faut quand même enregistrer celles par défaut
            if (!trajectoires["emission_ges"]) {
                trajectoires["emission_ges"] = {};
                trajectoires["emission_ges"].annees_valeurs = {};
                trajectoires["emission_ges"].annees_modifiees = {};
                for (let a of this.state.years) {
                    trajectoires["emission_ges"].annees_valeurs[a] =
                        this.props.trajectoiresCiblesReference.emission_ges?.ref;
                }
            }
            if (!trajectoires["energie_economisee"]) {
                trajectoires["energie_economisee"] = {};
                trajectoires["energie_economisee"].annees_valeurs = {};
                trajectoires["energie_economisee"].annees_modifiees = {};
                for (let a of this.state.years) {
                    trajectoires["energie_economisee"].annees_valeurs[a] =
                        this.props.trajectoiresCiblesReference.energie_economisee?.ref;
                }
            }
            if (!trajectoires["energie_produite"]) {
                trajectoires["energie_produite"] = {};
                trajectoires["energie_produite"].annees_valeurs = {};
                trajectoires["energie_produite"].annees_modifiees = {};
                for (let a of this.state.years) {
                    trajectoires["energie_produite"].annees_valeurs[a] =
                        this.props.trajectoiresCiblesReference.energie_produite?.ref;
                }
            }

            params["trajectoires"] = trajectoires;

            return params;
        };

        // New scenario
        const newScenario = (e) => {
            // Get title and description (optionnal)
            let titre = ReactDOM.findDOMNode(this.refs["titre"]).value;
            let description = ReactDOM.findDOMNode(this.refs["description"]).value;
            if (titre === "") {
                alert("Vous devez renseigner un titre");
                return;
            }

            let url = buildRegionUrl(
                config.user_scenario_url,
                this.props.parentApi.data.region
            );
            if (this.state.is_national) {
                url = buildRegionUrl(
                    config.user_scenario_national_url.replace(
                        "#territory#",
                        this.props.parentApi.data.region
                    ),
                    "national"
                );
            }
            let params = {};

            let actions = [];
            this.props.actions.forEach((action) => {
                if (action.enabled) actions.push(action.numero);
            });

            params = gatherParams(params);

            params["actions"] = actions;
            params["titre"] = titre;
            params["description"] = description;
            params["zone_type"] =
                this.props.parentApi.data.zone.zone +
                "#" +
                this.props.parentApi.data.zone.maille;
            let zoneId = this.props.parentApi.data.currentZone;
            if (
                this.props.parentApi.data.zone.zone === "region" &&
                !this.state.is_national
            ) {
                zoneId = this.props.parentApi.data.regionCode;
            }
            params["zone_id"] = zoneId;
            let nomCurrentZone = this.state.is_national
                ? this.props.parentApi.data.region
                : this.props.parentApi.controller.zonesManager.zoneLists[
                      params["zone_type"].split("#")[0]
                  ].find((zone) => zone.code === zoneId).label;
            params["nom_territoire"] = nomCurrentZone;

            if (params["zone_id"] === "") {
                params["zone_id"] = null;
            }
            params["region"] = this.props.parentApi.data.region;
            const body = JSON.stringify(params);
            Api.callApi(url, body, "POST")
                .then((response) => {
                    // reload list
                    this.getScenarii();
                    this.setState({
                        status: "Nouvelle stratégie territoriale ajoutée !",
                        currScenario: {
                            id: response.scenario_id,
                            titre: titre,
                        },
                        statusType: "success",
                    });
                    window.setTimeout(() => {
                        this.setState({
                            status: "",
                            statusType: "",
                        });
                    }, 3000);
                })
                .catch((e) =>
                    this.setState({ status: e.message, statusType: "danger" })
                );
        };

        // Save current scenario
        const saveScenario = (e) => {
            let titre = ReactDOM.findDOMNode(this.refs["titre"]).value;
            let description = ReactDOM.findDOMNode(this.refs["description"]).value;
            if (!this.state.currScenario) {
                this.setState({
                    status: "Il n'y a pas de stratégie territoriale active",
                    statusType: "warning",
                });
                return;
            }

            let url =
                buildRegionUrl(
                    config.user_scenario_url,
                    this.props.parentApi.data.region
                ) +
                "/" +
                this.state.currScenario.id;
            if (this.state.is_national) {
                url =
                    buildRegionUrl(
                        config.user_scenario_national_url.replace(
                            "#territory#",
                            this.props.parentApi.data.region
                        ),
                        "national"
                    ) +
                    "/" +
                    this.state.currScenario.id;
            }
            let params = {};

            let actions = [];
            this.props.actions.forEach((action) => {
                if (action.enabled) {
                    actions.push(action.numero);
                }
            });

            params = gatherParams(params);
            params["actions"] = actions;
            params["titre"] = titre;
            params["description"] = description;
            const body = JSON.stringify(params);
            Api.callApi(url, body, "PUT")
                .then((response) => {
                    // reload list
                    this.getScenarii();
                    this.setState({
                        status:
                            "Stratégie territoriale " +
                            this.state.currScenario.titre +
                            " mise à jour !",
                        statusType: "success",
                    });
                    window.setTimeout(() => {
                        this.setState({
                            status: "",
                            statusType: "",
                        });
                    }, 3000);
                })
                .catch((e) =>
                    this.setState({ status: e.message, statusType: "danger" })
                );
        };

        // Delete current scenario
        const delScenario = (s) => {
            let r = window.confirm("Attention, cette action est irréversible");
            if (r !== true) {
                return;
            }
            let url =
                buildRegionUrl(
                    config.user_scenario_url,
                    this.props.parentApi.data.region
                ) +
                "/" +
                s.props.row.id;
            if (this.state.is_national) {
                url =
                    buildRegionUrl(
                        config.user_scenario_national_url.replace(
                            "#territory#",
                            this.props.parentApi.data.region
                        ),
                        "national"
                    ) +
                    "/" +
                    s.props.row.id;
            }

            const body = {};
            Api.callApi(url, body, "DELETE")
                .then((response) => {
                    // reload list
                    this.getScenarii();
                    this.setState({
                        status: "Stratégie territoriale supprimée !",
                        statusType: "success",
                    });
                    window.setTimeout(() => {
                        this.setState({
                            status: "",
                            statusType: "",
                        });
                    }, 3000);
                })
                .catch((e) =>
                    this.setState({ status: e.message, statusType: "danger" })
                );
        };

        // Load scenario
        const loadScenario = (row) => {
            // Récupérer les paramètres pour ce scénario depuis l'API
            let params = [];
            let advanced = [];
            let actions = [];
            let trajectoires = undefined;
            let zone_type = "";
            let zone_id = "";
            let description = "";
            let titre = "";
            this.props.parentApi.callbacks.updateDataLoaded(false);
            let url =
                buildRegionUrl(
                    config.user_scenario_url,
                    this.props.parentApi.data.region
                ) +
                "/" +
                row.props.row.id;
            if (this.state.is_national) {
                url =
                    buildRegionUrl(
                        config.user_scenario_national_url.replace(
                            "#territory#",
                            this.props.parentApi.data.region
                        ),
                        "national"
                    ) +
                    "/" +
                    row.props.row.id;
            }
            Api.callApi(url, null, "GET")
                .then((response) => {
                    params = response.params;
                    advanced = response.advanced;
                    actions = response.actions;
                    trajectoires = response.trajectoires;
                    zone_type = response.zone_type;
                    zone_id = response.zone_id;
                    description = response.description;
                    titre = response.titre;
                    // Load scenario
                    // Set current scenario
                    this.setState({
                        currScenario: {
                            id: row.props.row.id,
                            titre: row.props.row.titre,
                            partage_par: row.props.row.partage_par,
                        },
                    });
                    this.props.loadParams(
                        actions,
                        params,
                        advanced,
                        trajectoires,
                        zone_type,
                        zone_id,
                        description,
                        titre,
                        url,
                        this.state.is_national
                    );
                })
                .catch((e) => {
                    console.error(e);
                    this.setState({ status: "", statusType: "" });
                });
        };

        // Partager scenario
        const shareScenario = (row) => {
            // Ouvrir une popup pour saisir les adresses emails
            this.setState({
                partagePopupOn: true,
                currentScenario: row.props.row.id,
                currentShare: row.props.row.partage,
            });
        };

        const handleShare = (e) => {
            // Appel API pour enregistrer le partage
            let emails = ReactDOM.findDOMNode(this.refs["emails_partage"]).value;

            const headers = new Headers();
            const body = JSON.stringify({ emails });
            headers.append("Content-Type", "application/json");
            let url = buildRegionUrl(
                config.user_scenario_partage_url,
                this.props.parentApi.data.region
            ).replace("#id#", this.state.currentScenario);
            Api.callApi(url, body, "POST").then(() => {
                // Mettre à jour la liste (indiquer que le scénario est partagé)
                this.getScenarii();
                this.setState({
                    partagePopupOn: false,
                });
            });
        };

        const handleCancelShare = (e) => {
            this.setState({
                partagePopupOn: false,
                currentScenario: undefined,
            });
        };

        // Publier un scenario
        const publishScenario = (row) => {
            let url = buildRegionUrl(
                config.user_scenario_partage_publier_url,
                this.props.parentApi.data.region
            ).replace("#id#", row.props.row.id);
            const body = JSON.stringify({
                id: row.props.row.id,
                publique: row.props.row.publique,
            });
            Api.callApi(url, body, "POST")
                .then((response) => {
                    // Rafraichir la liste
                    this.getScenarii();
                })
                .catch((e) =>
                    this.setState({ status: e.message, statusType: "error" })
                );
        };

        // Popup de partage
        let partagePopup = "";
        if (this.state.partagePopupOn) {
            partagePopup = (
                <div className="popup partage-strategie">
                    <div className="panel-body user-partage-form">
                        <div className="form-group">
                            <label>
                                Saisissez les emails des personnes avec qui vous voulez
                                partager (un email par ligne)
                            </label>
                        </div>
                        <div className="form-group">
                            <textarea
                                ref="emails_partage"
                                id="emails_partage"
                                rows="5"
                                cols="50"
                                defaultValue={
                                    this.state.currentShare
                                        ? this.state.currentShare.join("\n")
                                        : ""
                                }
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleShare}
                            >
                                Valider
                            </button>
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={handleCancelShare}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        const manageButtonVisibility = (type, row, props) => {
            let classe = "";
            switch (type) {
                case "supprimer":
                case "partager":
                    if (row.partage_par) {
                        // Si c'est partagé, on ne doit pas proposer le repartage
                        // Si c'est partagé, on ne doit pas pouvoir supprimer
                        classe = "hide";
                    }
                    break;
                case "publier":
                    if (!props.publication || row.partage_par) {
                        classe = "hide";
                    }
                    break;
                default:
                    break;
            }
            return classe;
        };

        const getPublicButtonlabel = (row, props) => {
            let label = "Publier";
            if (row.publique) {
                label = "Dé-publier";
            }
            return label;
        };

        // Scenarii list
        const columns = [
            {
                Header: (
                    <div className="colonne_table">
                        {"Id stratégie"}
                        <div className="tri"></div>
                    </div>
                ),
                accessor: "id",
                filterable: false,
            },
            {
                Header: (
                    <div className="colonne_table">
                        {"Titre"}
                        <div className="tri"></div>
                    </div>
                ),
                accessor: "titre",
            },
            {
                Header: (
                    <div className="colonne_table">
                        {"Description"}
                        <div className="tri"></div>
                    </div>
                ),
                accessor: "description",
            },
            {
                Header: (
                    <div className="colonne_table">
                        {"Type territoire"}
                        <div className="tri"></div>
                    </div>
                ),
                accessor: "zone_type",
            },
            {
                Header: (
                    <div className="colonne_table">
                        {"Territoire"}
                        <div className="tri"></div>
                    </div>
                ),
                accessor: "nom_territoire",
            },
            {
                Header: (
                    <div className="colonne_table">
                        {"Partagé avec"}
                        <div className="tri"></div>
                    </div>
                ),
                accessor: "partage",
                getProps: (state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            style: {
                                backgroundColor:
                                    rowInfo.row.partage &&
                                    rowInfo.row.partage.length > 0
                                        ? "#d1f4f9"
                                        : null,
                            },
                        };
                    }
                    return {};
                },
                Cell: (props) => {
                    const items = [];
                    if (props.row.partage) {
                        for (const partage of props.row.partage) {
                            items.push(
                                <li
                                    className="list-simple"
                                    key={props.row.id + "_" + partage}
                                >
                                    {partage}
                                </li>
                            );
                        }
                    }

                    return (
                        <div>
                            <div className="multi-line">{items}</div>
                        </div>
                    );
                },
            },
            {
                Header: (
                    <div className="colonne_table">
                        {"Partagé par"}
                        <div className="tri"></div>
                    </div>
                ),
                accessor: "partage_par",
                getProps: (state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            style: {
                                backgroundColor: rowInfo.row.partage_par
                                    ? "#d1f9de"
                                    : null,
                            },
                        };
                    }
                    return {};
                },
            },
            {
                Header: "Publique",
                accessor: "publique",
                getProps: (state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            style: {
                                backgroundColor: rowInfo.row.publique
                                    ? "rgb(138, 192, 249)"
                                    : null,
                            },
                        };
                    }
                    return {};
                },
                Cell: (props) => <div>{props.row.publique ? "Oui" : ""}</div>,
                filterable: true,
            },
            {
                Header: "Dernière modification",
                accessor: "derniere_modif",
                filterable: false,
            },
            {
                width: 300,
                Cell: (props) => (
                    <div className="actions">
                        <button
                            className="btn btn-success"
                            onClick={() => loadScenario({ props })}
                        >
                            Charger
                        </button>
                        {!this.state.is_national && (
                            <button
                                className={
                                    "btn btn-info " +
                                    manageButtonVisibility(
                                        "partager",
                                        props.row,
                                        this.props.parentApi.data
                                    )
                                }
                                onClick={() => shareScenario({ props })}
                            >
                                Partager
                            </button>
                        )}
                        <button
                            className={
                                "btn btn-primary " +
                                manageButtonVisibility(
                                    "publier",
                                    props.row,
                                    this.props.parentApi.data
                                )
                            }
                            onClick={() => publishScenario({ props })}
                        >
                            {getPublicButtonlabel(props.row, this.props.parentApi.data)}
                        </button>
                        <button
                            className={
                                "btn btn-danger " +
                                manageButtonVisibility(
                                    "supprimer",
                                    props.row,
                                    this.props.parentApi.data
                                )
                            }
                            onClick={() => delScenario({ props })}
                        >
                            Supprimer
                        </button>
                    </div>
                ),
                filterable: false,
            },
        ];
        let scenariiList = "";
        if (this.props.displayListScenarii) {
            scenariiList = (
                <div className="panel-body user-scenarii">
                    <ReactTable // Version 6 de ReactTable
                        data={this.state.scenariiList}
                        columns={columns}
                        className="-striped"
                        defaultPageSize={10}
                        parent={this}
                        filterable={true}
                        defaultFilterMethod={(filter, row) => {
                            // On applique la même méthode de filtre quelle que soit la colonne concernée.
                            /**
                             * Cette méthode permet de personnaliser le filtre.
                             * @param {object} filter : objet de type tableau clé - valeur [id: nom de la colonne sur laquelle on applique le filtre, value: valeur saisie]
                             * @param {object} row : objet qui correpond à la ligne sous forme de tableau clé valeur ["titre": Titre, "description": description ... ]
                             **/
                            // Pour plus d'informations : https://github.com/tannerlinsley/react-table/tree/v6#columns
                            let colonne = filter.id.toString(); // On récupère le nom de la colonne sur laquelle la personne exécute son filtre
                            let valeurAComparer = "";

                            if (row[colonne] === null) {
                                // Si la valeur de la ligne courante est vide, on ne la montre pas.
                                return false; // Pour rappel et exemple, si la variable colonne est égale à  "titre", row[colonne] = row["titre"] et on accède au titre de la stratégie courante
                            } else if (typeof row[colonne] === "boolean") {
                                if (row[colonne] === true) {
                                    valeurAComparer = "Oui";
                                }
                            } else if (typeof row[colonne] === "object") {
                                // Si la valeur de la ligne courante est un tableau (typiquement, les adresses mail avec qui on partage)
                                valeurAComparer = row[colonne].join(" "); // On transforme le tableau en une chaine de caractères qui est la concaténation de tous les éléments du tableau.
                            } else {
                                valeurAComparer = row[colonne].toString(); // Sinon, on la convertit en chaine pour traiter le cas où les valeurs de la colonne seraient de type entier (les id)
                            }
                            // normalize supprime les accents de toutes les chaines et les convertit en minuscule (Cf. ../utils.js).
                            // On rend ainsi les chaines normées avant de les comparer.
                            if (
                                normalize(valeurAComparer).match(
                                    normalize(filter.value)
                                )
                            ) {
                                // On vérifie s'il existe une corresponance entre
                                return true; // la valeur saisie et la valeur de la ligne courante
                            } else {
                                return false;
                            }
                        }}
                    />
                </div>
            );
        }

        let buttonUpdate = (
            <button
                type="button"
                className="btn btn-info block-row"
                onClick={() => saveScenario()}
            >
                Enregistrer les modifications de la stratégie territoriale
            </button>
        );
        if (this.state.currScenario && this.state.currScenario.partage_par) {
            // On ne peut pas modifier une stratégie qui nous est partagée (l'interdiction est déjà faite côté API)
            buttonUpdate = "";
        }

        let texteIntro = "";
        let titreMesStrategies = "";
        if (this.props.texteIntro) {
            titreMesStrategies = (
                <h3 className="centered-row">Mes stratégies territoriales</h3>
            );
            texteIntro = (
                <div className="options actions centered-widget" id="listeStrategies">
                    <label>
                        <p>
                            Le tableau Mes stratégies territoriales présente l’ensemble
                            des stratégies auxquelles vous avez accès (créées par vous
                            ou par d’autres utilisateurs) et vous permet de les
                            consulter, partager et/ou publier.
                        </p>
                        <ul>
                            <li>
                                Pour <b>accéder à une stratégie</b>, cliquez sur
                                Charger.
                            </li>
                            <li>
                                Pour{" "}
                                <b>partager une stratégie avec d’autres utilisateurs</b>
                                , cliquez sur Partager puis renseignez les adresses
                                email des utilisateurs avec lesquels vous souhaitez la
                                partager.
                            </li>
                            <li>
                                Pour{" "}
                                <b>
                                    partager une stratégie finalisée avec l’ensemble des
                                    utilisateurs
                                </b>{" "}
                                connectés cliquez sur Publier (fonctionnalité réservée
                                aux territoires).
                            </li>
                        </ul>
                        <p>
                            Vous pouvez{" "}
                            <b>personnaliser l’ordre des lignes dans le tableau</b> en
                            triant ou filtrant sur les entêtes de colonne (Pour trier,
                            cliquez sur la flèche et pour filtrer, renseignez un mot clé
                            dans la case vide en dessous de l’entête de colonne).
                        </p>
                    </label>
                </div>
            );
        }
        let scenariiActions = "";
        let description = this.props.description;
        let titre = this.props.titre;
        if (!this.props.displayListScenarii) {
            let alertBox =
                this.state.statusType !== ""
                    ? "alert alert-" + this.state.statusType
                    : "";
            scenariiActions = (
                <div className="panel-body">
                    <div className="form-group new-scenario">
                        <label>Titre</label>
                        <input
                            type="text"
                            className="form-inline input"
                            id="titre"
                            ref="titre"
                            defaultValue={titre}
                        />
                        <label>Description</label>
                        <input
                            type="text"
                            className="form-inline input"
                            id="description"
                            ref="description"
                            defaultValue={description}
                        />
                        <button
                            type="button"
                            className="btn btn-info block-row"
                            onClick={() => newScenario()}
                        >
                            Enregistrer une nouvelle stratégie territoriale
                        </button>
                    </div>
                    <div className="form-group">{buttonUpdate}</div>
                    <div className={alertBox}>{this.state.status}</div>
                </div>
            );
        }

        return (
            <div className="">
                {titreMesStrategies}
                {texteIntro}
                <div className="centered-row">
                    <div className="plan-actions-scenarii">
                        {scenariiList}
                        {scenariiActions}
                        {partagePopup}
                    </div>
                </div>
            </div>
        );
    }
}

export default PlanActionsScenarii;
