/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useEffect, useState } from "react";
import { ReactGrid, TextCellTemplate, keyCodes } from "@silevis/reactgrid";
import getCharFromKeyCode from "../Utils/getCharFromKeyCode";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import configData from "../../settings_data";

import "@silevis/reactgrid/styles.css";

const paramTypes = {
    "1_nb_logements": "integer",
    "1_surface_moy_logement": "",
    "2_surface_bat_tertiaire": "",
    "2_part_niveau_renov_faible": "repartition",
    "2_part_niveau_renov_perf": "repartition",
    "3_puiss_crete": "",
    "3b_puiss_install": "",
    "4_nb_batiment_collectif_renouv": "integer",
    "4_surf_capteurs_bati": "",
    "4b_nb_batiment_collectif_renouv": "integer",
    "4b_surf_capteurs_bati": "",
    "5_conso_totale_chauffage_resi": "",
    "5_conso_totale_chauffage_tertiaire": "",
    "5_part_conso_resi_racc": "percent",
    "5_part_conso_tertiaire_racc": "percent",
    "6_nb_methaniseur": "integer",
    "6_puiss_moy_methaniseurs": "",
    "6b_nb_methaniseur": "integer",
    "6b_capacite_injec_biometh": "",
    "10a_puiss_crete": "",
    "10b_puiss_crete": "",
    "10c_puiss_crete": "",
    "12_puiss_installee": "",
    "13_conso_totale": "",
    "14_conso_indus_totale": "sum_60",
    "15_conso_agri_totale": "sum_60",
    "16_chauffage_elec": "repartition",
    "16_chaudiere_gaz": "repartition",
    "16_chaudiere_fioul_gpl": "repartition",
    "16_chauffage_biomasse": "repartition",
    "16_chauffage_rdc": "repartition",
    "16_chauffage_pac": "repartition",
    "17_chauffage_elec": "repartition",
    "17_chaudiere_gaz": "repartition",
    "17_chaudiere_fioul_gpl": "repartition",
    "17_chauffage_biomasse": "repartition",
    "17_chauffage_rdc": "repartition",
    "17_chauffage_pac": "repartition",
    "18_reduction_dist_traj_domicile_travail": "sum_percent",
    "19_nb_sensibilisation_actif_covoit": "integer",
    "19_pourc_sensibilisation_actif_covoit": "percent",
    "20_bus_elec": "sum_percent",
    "20_bus_gnv": "sum_percent",
    "20_bus_diesel": "sum_percent",
    "21_emissions_non_eneg_indus": "sum_percent",
    "22_emissions_non_eneg_agri": "sum_percent",
};

const numberFormat = Intl.NumberFormat(undefined, { maximumSignificantDigits: 4 });
const integerFormat = Intl.NumberFormat(undefined, { maximumFractionDigits: 0 });
const percentFormat = Intl.NumberFormat(undefined, { maximumFractionDigits: 2 });

// https://github.com/silevis/reactgrid/tree/d9842605130a1c12d646771a6121dc4df3bae6c2
TextCellTemplate.prototype.handleKeyDown = function (cell, keyCode, ctrl, shift, alt) {
    const char = getCharFromKeyCode(keyCode, shift, navigator.language);
    if (!ctrl && char !== "" && !(shift && keyCode === keyCodes.SPACE))
        return {
            cell: this.getCompatibleCell({ ...cell, text: char }),
            enableEditMode: true,
        };
    return {
        cell,
        enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
};

/**
 * Actions page, where user can edit actions parameters and advanced parameters.
 *
 * @param {Object} props
 * @param {{ referenceYear: number }} props.strategy
 * @param {Array<{ numero: string, categorie: string, name: string }>} props.actionsMeta
 * @param {Array<{ id: number, nom: string, action: string, label: string, unite: string, valeur: {}? }>} props.params
 * @param {(params: Array|() => Array) => void} props.setParams
 * @param {Array<{ action: string, params_avances: { economique: Array, autres: {}, others_years: {} } }>} props.advancedParams
 * @param {(advancedParams: Array|() => Array) => void} props.setAdvancedParams
 */
export default function Actions({
    strategy,
    actionsMeta,
    params,
    setParams,
    advancedParams,
    setAdvancedParams,
    activeActions,
    setActionErrors,
}) {
    const categories = [...new Set(actionsMeta.map((a) => a.categorie))];
    return (
        <div>
            <p>Ici, vous définissez des leviers d'action pour votre territoire</p>
            <p>
                Les actions proposées ne sont pas exhaustives. D'autres actions sont à
                imaginer en dehors de TerriSTORY.
            </p>
            <p>Conseils</p>
            <ul>
                <li>Plus vous réalisez les actions tôt, plus elles ont d'impact</li>
                <li>
                    Les actions combinées accélèrent l'efficacité de votre stratégie
                </li>
            </ul>
            {!actionsMeta?.length || !strategy?.referenceYear ? (
                <div className="loader" />
            ) : (
                <Tabs>
                    <TabList className="tabs-secondary">
                        {categories.map((cat) => (
                            <Tab key={cat}>
                                <span>{cat.replace(/^Actions /, "")}</span>
                            </Tab>
                        ))}
                    </TabList>
                    {categories.map((cat) => (
                        <TabPanel key={cat}>
                            <h2 className="tstitle2">
                                Actions {cat.replace(/^Actions /, "")}
                            </h2>
                            {actionsMeta
                                .filter((a) => a.categorie === cat)
                                .map((action) => (
                                    <Action
                                        key={action.numero}
                                        meta={{
                                            ...action,
                                            first_year: strategy.referenceYear + 1,
                                        }}
                                        params={params}
                                        setParams={setParams}
                                        advancedParams={advancedParams}
                                        setAdvancedParams={setAdvancedParams}
                                        isActive={activeActions[action.numero]}
                                        setActionErrors={setActionErrors}
                                    />
                                ))}
                        </TabPanel>
                    ))}
                </Tabs>
            )}
        </div>
    );
}

/**
 * Modifiable action, with its title, description, parameters table and advanced parameters
 *
 * @param {Object} props
 * @param {{ numero: string, categorie: string, name: string }} props.meta
 * @param {Array<{ id: number, nom: string, action: string, label: string, unite: string, valeur: {}? }>} props.params
 * @param {(params: Array|() => Array) => void} props.setParams
 * @param {Array<{ action: string, params_avances: { economique: Array, autres: {}, others_years: {} } }>} props.advancedParams
 * @param {(advancedParams: Array|() => Array) => void} props.setAdvancedParams
 */
function Action({
    meta,
    params,
    setParams,
    advancedParams,
    setAdvancedParams,
    isActive,
    setActionErrors,
}) {
    const myParams = params
        .filter((p) => p.action === meta.numero)
        .sort((p1, p2) => p1.id - p2.id);
    const myAdvancedParam = advancedParams.filter((p) => p.action === meta.numero)?.[0]
        ?.params_avances;

    const hasRepartition = myParams.some(
        ({ nom }) => paramTypes[nom] === "repartition"
    );

    const [isOpen, setIsOpen] = useState(isActive !== undefined);

    const years = [];
    if (hasRepartition && meta.coeffs_ref) {
        years.push(meta.first_year - 1);
    }
    for (let y = meta.first_year; y <= configData.planActionDerniereAnnee; y++) {
        years.push(y);
    }

    if (hasRepartition && meta.coeffs_ref) {
        const refYear = meta.first_year - 1;
        let idOfMax = 0;
        for (const i in myParams) {
            let param = myParams[i];
            if (paramTypes[param.nom] !== "repartition") continue;
            if (!(param.nom in meta.coeffs_ref))
                throw Error(
                    "Erreur dans les paramètres par défaut de l'action " + meta.numero
                );
            myParams[i] = param = { ...param, valeur: { ...(param.valeur ?? {}) } };
            param.valeur[refYear] = parseFloat(meta.coeffs_ref[param.nom].toFixed(2));
            if (param.valeur[refYear] > myParams[idOfMax].valeur[refYear]) {
                idOfMax = parseInt(i);
            }
        }
        // To have a total of exactly 100, recompute the greatest value
        let sumWithoutMax = myParams
            .filter(({ nom }, i) => paramTypes[nom] === "repartition" && i !== idOfMax)
            .map(({ valeur }) => valeur[refYear])
            .reduce((a, b) => a + b, 0);
        if (myParams[idOfMax]) myParams[idOfMax].valeur[refYear] = 100 - sumWithoutMax;
    }

    let errorMessage = undefined;

    const displayedValues = {};
    const classNames = {};
    let sum = 0;
    for (const param of myParams) {
        displayedValues[param.nom] = {};
        classNames[param.nom] = {};
        const paramType = paramTypes[param.nom];
        for (const year of years) {
            let value = 0;
            if (paramType === "repartition") {
                value = displayedValues[param.nom][year - 1] ?? value;
            }
            if (param.valeur?.[year] != null && !isNaN(param.valeur[year])) {
                value = param.valeur[year] ?? value;
            }
            displayedValues[param.nom][year] = value;
            if (paramType === "sum_percent" || paramType === "sum_60") {
                sum += value;
                if (sum > (paramType === "sum_60" ? 60 : 100)) {
                    classNames[param.nom][year] = "cell-error";
                    errorMessage =
                        errorMessage ??
                        `La somme des valeurs ne doit pas dépasser ${
                            paramType === "sum_60" ? 60 : 100
                        }%`;
                }
            }
        }
    }

    const columns = [
        { columnId: "header", width: 300 },
        ...years.map((year) => ({ columnId: `${year}`, width: 60 })),
    ];
    const rows = [
        {
            rowId: "years",
            cells: [
                { type: "header", text: "" },
                ...years.map((year) => ({ type: "header", text: "" + year })),
            ],
        },
        ...myParams.map((param) => {
            let formatter = numberFormat;
            if (paramTypes[param.nom] === "integer") formatter = integerFormat;
            if (paramTypes[param.nom]?.includes("percent")) formatter = percentFormat;
            if (paramTypes[param.nom] === "repartition") formatter = percentFormat;

            return {
                rowId: param.nom,
                cells: [
                    {
                        type: "header",
                        text: `${param.label} (${param.unite})`,
                        className: "cell-param-label",
                    },
                    ...years.map((year) => ({
                        type: "text",
                        text: formatter.format(displayedValues[param.nom][year]),
                        className:
                            param.valeur?.[year] == null
                                ? "cell-placeholder"
                                : classNames[param.nom]?.[year],
                        nonEditable: year < meta.first_year,
                    })),
                ],
            };
        }),
    ];
    if (hasRepartition) {
        rows.push({
            rowId: "sum",
            cells: [
                { type: "header", text: "Total (%)" },
                ...years.map((year) => {
                    if (myParams.every(({ valeur }) => valeur?.[year] == null)) {
                        return { type: "text", text: "", nonEditable: true };
                    }
                    const sum = myParams
                        .map((param) =>
                            paramTypes[param.nom] === "repartition"
                                ? displayedValues[param.nom][year]
                                : 0
                        )
                        .reduce((a, b) => a + b, 0);
                    let className = "cell-success";
                    if (Math.abs(sum - 100) > 1e-5) {
                        errorMessage =
                            errorMessage ??
                            `La somme des valeurs à l'année ${year} doit être exactement 100%`;
                        className = "cell-error";
                    }
                    return {
                        type: "text",
                        text: percentFormat.format(sum),
                        nonEditable: true,
                        className,
                    };
                }),
            ],
        });
    }

    useEffect(() => {
        setActionErrors((prevState) => {
            if (errorMessage === prevState[meta.numero]) return prevState;
            if (errorMessage === undefined) {
                const newState = { ...prevState };
                delete newState[meta.numero];
                return newState;
            }
            return { ...prevState, [meta.numero]: errorMessage };
        });
    }, [errorMessage, meta.numero, setActionErrors]);

    const convertValue = (text, paramType) => {
        let value = parseFloat(text.replace(/\s/, "").replace(",", "."));
        if (isNaN(value) || value < 0) return 0;
        if (
            ["percent", "sum_percent", "repartition"].includes(paramType) &&
            value > 100
        ) {
            return 100;
        }
        if (paramType === "integer") return Math.floor(value);
        return value;
    };
    const handleChange = (changes) => {
        setParams((prevParams) => {
            let newParams = [...prevParams];
            changes.forEach(({ rowId, columnId, newCell }) => {
                // rowId = id of the param, columnId = year
                const paramIndex = newParams.findIndex((p) => p.nom === rowId);
                const valeur = { ...newParams[paramIndex].valeur };
                if (newCell.text == null || newCell.text === "") {
                    delete valeur[columnId];
                } else {
                    valeur[columnId] = convertValue(newCell.text, paramTypes[rowId]);
                }
                newParams[paramIndex] = { ...newParams[paramIndex], valeur };
            });
            return newParams;
        });
    };
    const handleAdvParamChange = (name, changes, key = undefined) => {
        setAdvancedParams((prevAdvParams) => {
            let newParams = [...prevAdvParams];
            changes.forEach(({ rowId, columnId, newCell }) => {
                // rowId = id of the adv param in the main params
                const paramIndex = newParams.findIndex((p) => p.action === meta.numero);
                const advParams = { ...newParams[paramIndex].params_avances };
                if (!(name in advParams)) {
                    return;
                }

                const newValue = convertValue(newCell.text, "number");
                // economy parameters case
                if (name === "economique") {
                    // we have a list of parameters and just need to edit the right one
                    const paramIndex = advParams[name].findIndex((p) => p.id === rowId);
                    advParams[name][paramIndex][columnId] = newValue;
                }
                // other parameters case
                else {
                    const currentAdvParam =
                        key && key in advParams[name]
                            ? advParams[name][key]
                            : advParams[name];
                    // annually parameters case
                    if (name === "others_years") {
                        if (newCell.text == null || newCell.text === "") {
                            delete currentAdvParam.valeur_annee[columnId];
                        } else {
                            currentAdvParam.valeur_annee[columnId] = newValue;
                        }
                    } else {
                        currentAdvParam.data[currentAdvParam.index.indexOf(rowId)][
                            currentAdvParam.columns.indexOf("valeur")
                        ] = newValue;
                    }
                    if (key) {
                        advParams[name] = {
                            ...advParams[name],
                            [key]: currentAdvParam,
                        };
                    } else {
                        advParams[name] = {
                            ...advParams[name],
                            ...currentAdvParam,
                        };
                    }
                }
                newParams[paramIndex].params_avances = {
                    ...newParams[paramIndex].params_avances,
                    ...advParams,
                };
            });
            return newParams;
        });
    };

    return (
        <details
            className="tsdetails"
            open={isOpen}
            onToggle={(e) => e.target === e.currentTarget && setIsOpen(e.target.open)}
        >
            <summary>{meta.name}</summary>
            <div>
                <p>{meta.description}</p>
                {myParams.length === 0 || !isOpen ? (
                    <div className="loader" />
                ) : (
                    <div className="scrollable-grid-container">
                        <ReactGrid
                            rows={rows}
                            columns={columns}
                            onCellsChanged={handleChange}
                            enableFillHandle
                            enableRangeSelection
                        />
                    </div>
                )}
                {errorMessage ? (
                    <div className="alert alert-warning mt-2">{errorMessage}</div>
                ) : null}
                {myAdvancedParam ? (
                    <details className="tsdetails">
                        <summary>Paramètres avancés</summary>
                        <div>
                            {Object.entries(myAdvancedParam).map(([name, params]) => (
                                <details key={name} className="tsdetails">
                                    {name === "others_years" ? (
                                        <AdvancedYearlyParam
                                            handleChange={(...args) =>
                                                handleAdvParamChange(name, ...args)
                                            }
                                            params={params}
                                            meta={meta}
                                        />
                                    ) : name === "economique" ? (
                                        <AdvancedEcoParam
                                            handleChange={(...args) =>
                                                handleAdvParamChange(name, ...args)
                                            }
                                            params={params}
                                        />
                                    ) : (
                                        <AdvancedParam
                                            handleChange={(...args) =>
                                                handleAdvParamChange(name, ...args)
                                            }
                                            params={params}
                                        />
                                    )}
                                </details>
                            ))}
                        </div>
                    </details>
                ) : null}
            </div>
        </details>
    );
}

function AdvancedEcoParam({ params, handleChange }) {
    const columns = [
        { columnId: "phase_projet", width: 200, name: "Grandes phases projet" },
        { columnId: "maillon", width: 200, name: "Maillon détaillé" },
        { columnId: "part_france", width: 250, name: "Part captée par la France (%)" },
        { columnId: "part", width: 250, name: "Part captée par le territoire (%)" },
    ];
    const rows = [
        {
            rowId: "headers",
            cells: [
                ...columns.map((col) => ({
                    type: "header",
                    text: col.name,
                    className: "cell-advanced-param-header",
                })),
            ],
        },
        ...params.map((param) => {
            return {
                rowId: param.id,
                cells: [
                    {
                        type: "header",
                        text: param.phase_projet,
                        className: "cell-param-label",
                    },
                    {
                        type: "header",
                        text: param.maillon,
                        className: "cell-param-label",
                    },
                    {
                        type: "header",
                        text: percentFormat.format(param.part_france),
                        className: "cell-param-label cell-centered-val",
                    },
                    {
                        type: "text",
                        text: percentFormat.format(param.part),
                    },
                ],
            };
        }),
    ];

    return (
        <>
            <summary>Paramètres économiques</summary>
            <p className="disclaimer">
                Part calculée par défaut à partir de données statistiques, à modifier
                pour votre territoire si nécessaire.
            </p>

            <ReactGrid
                rows={rows}
                columns={columns}
                onCellsChanged={handleChange}
                enableFillHandle
                enableRangeSelection
            />
        </>
    );
}

function AdvancedParam({ params, handleChange }) {
    const verboseNames = {
        parametres_avances: "Paramètres techniques",
        rendement_combine: "Rendement combiné",
        rendement_ecs: "Rendement ECS",
    };

    return (
        <>
            <summary>Autres paramètres</summary>
            <p className="disclaimer">
                Paramètres remplis par défaut, à modifier pour votre territoire si
                nécessaire.
            </p>
            {Object.entries(params).map(([key, param]) => {
                if (key === "Modifier les performances des appareils") {
                    return "NOT AVAILABLE YET";
                }

                const columns = [
                    { columnId: "name", width: 250, name: "Nom" },
                    { columnId: "unit", width: 100, name: "Unité" },
                    { columnId: "value", width: 100, name: "Valeur" },
                ];

                const rows = [
                    {
                        rowId: "headers",
                        cells: [
                            ...columns.map((col) => ({
                                type: "header",
                                text: col.name,
                                className: "cell-advanced-param-header",
                            })),
                        ],
                    },
                    ...param.data.map((row, i) => {
                        let formatter = numberFormat;
                        if (row[1] === "%") formatter = percentFormat;

                        return {
                            rowId: param.index[i],
                            cells: [
                                {
                                    type: "header",
                                    text: row[0],
                                    className: "cell-param-label",
                                },
                                {
                                    type: "header",
                                    text: row[1] ?? "-",
                                    className: "cell-param-label cell-centered-val",
                                },
                                {
                                    type: "text",
                                    text: formatter.format(row[2]),
                                },
                            ],
                        };
                    }),
                ];

                return (
                    <div key={key} className="advanced-params">
                        <h4>{verboseNames[key] ?? key}</h4>

                        <ReactGrid
                            rows={rows}
                            columns={columns}
                            onCellsChanged={(e) => handleChange(e, key)}
                            enableFillHandle
                            enableRangeSelection
                        />
                    </div>
                );
            })}
        </>
    );
}

function AdvancedYearlyParam({ meta, params, handleChange }) {
    const years = [];
    for (let y = meta.first_year; y <= configData.planActionDerniereAnnee; y++) {
        years.push(y);
    }

    return (
        <>
            <summary>Paramètres annualisés</summary>
            <p className="disclaimer">
                Paramètres remplis par défaut, à modifier pour votre territoire si
                nécessaire.
            </p>
            {Object.entries(params).map(([key, param]) => {
                const columns = [
                    { columnId: "name", width: 250, name: "Nom" },
                    ...years.map((year) => ({ columnId: `${year}`, width: 60 })),
                ];

                const rows = [
                    {
                        rowId: "years",
                        cells: [
                            { type: "header", text: "" },
                            ...years.map((year) => ({
                                type: "header",
                                text: "" + year,
                            })),
                        ],
                    },
                    ...param.data.map((row) => {
                        const name = row[param.columns.indexOf("nom")];

                        return {
                            rowId: name,
                            cells: [
                                {
                                    type: "header",
                                    text: `${name}`,
                                    className: "cell-param-label",
                                },
                                ...years.map((year) => ({
                                    type: "text",
                                    text: numberFormat.format(
                                        param.valeur_annee[year] ?? 0
                                    ),
                                    className:
                                        param.valeur_annee[year] == null
                                            ? "cell-placeholder"
                                            : null,
                                })),
                            ],
                        };
                    }),
                ];

                return (
                    <div key={key} className="advanced-params">
                        <h4>{key}</h4>

                        <ReactGrid
                            rows={rows}
                            columns={columns}
                            onCellsChanged={(e) => handleChange(e, key)}
                            enableFillHandle
                            enableRangeSelection
                        />
                    </div>
                );
            })}
        </>
    );
}
