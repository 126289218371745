/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Chart } from "react-chartjs-2";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import chroma from "chroma-js";
import "chartjs-plugin-annotation";
import pattern from "patternomaly";
import { saveAs } from "file-saver";
import { buildRegionUrl, createPdfMethodoLink } from "../utils.js";
import annotationPlugin from "chartjs-plugin-annotation";

import Api from "../Controllers/Api";

import {
    Accordion,
    AccordionItem,
    AccordionItemPanel,
    AccordionItemHeading,
    AccordionItemButton,
} from "react-accessible-accordion";

import { trim } from "../utils.js";
import config from "../settings.js";
import configData from "../settings_data.js";

import PlanActionsScenarii from "./PlanActionsScenarii";
import PlanActionsTrajectoiresCibles from "./PlanActionsTrajectoiresCibles";
import DetailsPopup from "./DetailsPopup.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-tabs/style/react-tabs.css";
import "chartjs-adapter-moment";

Chart.register(annotationPlugin);

/**
 * This component is used to manage action plans
 * and contains all the functionalities of the territorial strategy page.
 */
class PlanActions extends React.Component {
    constructor(props) {
        super(props);
        this.trajectoires = {};
        this.loadedScenarioData = {};

        this.state = {
            actions: [],
            listeDesActions: [],
            disabledActions: [],
            warningDisabledActions: "",
            updated_params_action: undefined,
            coeffs: [],
            data: undefined,
            dataLoaded: true,
            runningPlan: false,
            directChecked: true,
            indirectChecked: true,
            showTrajectoireCibleEnergieClimat: false,
            showTrajectoireCibleQualiteAir: false,
            years: [],
            titre: "",
            description: "",
            pcaetAdeme: true,
            affichagePcaetAdeme: true,
            retourEtatDefaut: false,
            displayAirQualityChart: false,
            displayEnergyTrajectoryButton: false,
            displayAirQualityTrajectoryButton: false,
            anneeRefDataConso: undefined,
            dataScenario: false,
            referencesTrajectoiresCibles: {},
            referencesTrajectoiresCiblesReference: {},
            anneeConsoGes: undefined,
            anneeProd: undefined,
            estimatedYears: {},
            referenceYear: undefined,
            fichierDonnees: "",
            status: undefined,
            erreur: undefined,
            is_national: this.props.parentApi.data.settings["is_national"],
        };
    }

    componentDidMount() {
        let tablesProjection = [
            "DataSet.CONSUMPTION",
            "DataSet.EMISSIONS",
            "DataSet.PRODUCTION",
        ];
        for (let table of tablesProjection) {
            this.premiereAnneeProjection(table);
        }

        this.loadActionsData(
            this.props.parentApi.data.zone,
            this.props.parentApi.data.currentZone,
            true
        );
    }

    loadActionsDataRef(zone, zoneId, ref) {
        // Get actions 16 and 17 whith updated coefficients from API
        if (
            zone.zone === "region" &&
            !this.props.parentApi.data.settings["is_national"]
        ) {
            zoneId = this.props.parentApi.data.regionCode;
        }
        if (!zone.zone || !zoneId) {
            return;
        }
        let dataSource = buildRegionUrl(
            config.api_plan_actions_coeffs_reference_url +
                "?zone=" +
                zone.zone +
                "&zone_id=" +
                zoneId,
            this.props.parentApi.data.region
        );
        Api.callApi(dataSource, JSON.stringify(ref), "POST")
            .then((json) => {
                // Display results
                let tempList = [];
                for (let a of json.actions) {
                    // loop on actions, with dynamic values
                    if (["16", "17", "18", "19"].includes(a.numero)) {
                        tempList.push(a);
                    }
                }
                this.setState({ updated_params_action: tempList });
            })
            .catch((error) => {
                this.setState({ error: error.message });
            });
    }

    premiereAnneeProjection(table) {
        let url = buildRegionUrl(
            config.api_liste_annees_url.replace("#table#", table),
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            let years = [];
            // start with the year following the year of data availability
            for (
                let y = response[response.length - 1].value + 1;
                y <= configData.planActionDerniereAnnee;
                y++
            ) {
                years.push(y);
            }
            if (table === "DataSet.EMISSIONS" || table === "DataSet.CONSUMPTION") {
                this.setState({
                    anneeConsoGes: response[response.length - 1].value,
                    referenceYear: response[response.length - 1].value, // we use conso_ener as a reference
                    yearsConsoGes: years,
                });
            } else {
                this.setState({
                    anneeProd: response[response.length - 1].value,
                    yearsProd: years,
                });
            }
        });
    }

    loadActionsData(zone, zoneId, chargerCoefficients) {
        // Get actions from API
        if (
            zone.zone === "region" &&
            !this.props.parentApi.data.settings["is_national"]
        ) {
            zoneId = this.props.parentApi.data.regionCode;
        }

        if (!zone.zone || !zoneId) {
            return;
        }

        let dataSource =
            config.api_plan_actions_actions_old_url +
            "?zone=" +
            zone.zone +
            "&zone_id=" +
            zoneId +
            "&pcaetAdeme=" +
            this.state.pcaetAdeme;
        Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region),
            null,
            "GET"
        )
            .then((json) => {
                // Retreive categories from actions
                let categories = [];
                json.actions.forEach((c) => {
                    if (!categories.some((cat) => cat.name === c.categorie)) {
                        categories.push({
                            name: c.categorie,
                            className: c.category_class,
                        });
                    }
                });
                this.setState({
                    categories: categories,
                });
                if (chargerCoefficients) {
                    this.setState({
                        actions: json.actions,
                    });
                }
                let referencesTrajectoiresCibles = {};
                /* If the json returns 'pcaet_ademe_vide' it means that there
                are no pcaets ademe in DB for this EPCI. In this case, the API
                returns a trajectoires dict containing only ref values in the
                form { "emission_ges": 253 }. On the other hand, if PCAET are
                available, the trajectoires dict have the structure
                { "emission_ges": { "ref": 253, "2030": 137 } }, which is the
                same as what we want in the front-end. */
                if (
                    json.pcaetAdemeIncorrect === "pcaet_ademe_vide" ||
                    !this.state.pcaetAdeme
                ) {
                    if (json.pcaetAdemeIncorrect === "pcaet_ademe_vide") {
                        this.setState({
                            affichagePcaetAdeme: false,
                        });
                    }
                    referencesTrajectoiresCibles = {
                        energie_economisee: {
                            ref: json.trajectoires.energie_economisee,
                        },
                        energie_produite: {
                            ref: json.trajectoires.energie_produite,
                        },
                        emission_ges: {
                            ref: json.trajectoires.emission_ges,
                        },
                        nox: { ref: json.trajectoires.nox },
                        sox: { ref: json.trajectoires.sox },
                        pm10: { ref: json.trajectoires.pm10 },
                        pm25: { ref: json.trajectoires.pm25 },
                        covnm: { ref: json.trajectoires.covnm },
                        nh3: { ref: json.trajectoires.nh3 },
                    };
                } else {
                    // Values of pcaets ademe
                    referencesTrajectoiresCibles = {
                        energie_economisee: json.trajectoires.consommation_ener,
                        energie_produite: json.trajectoires.enr_production,
                        emission_ges: json.trajectoires.emission_ges,
                        nox: json.trajectoires.nox,
                        sox: json.trajectoires.sox,
                        pm10: json.trajectoires.pm10,
                        pm25: json.trajectoires.pm25,
                        covnm: json.trajectoires.covnm,
                        nh3: json.trajectoires.nh3,
                    };
                }

                // display or not the air pollutants target trajectories button
                if (
                    referencesTrajectoiresCibles.nox &&
                    referencesTrajectoiresCibles.sox &&
                    referencesTrajectoiresCibles.pm10 &&
                    referencesTrajectoiresCibles.pm25 &&
                    referencesTrajectoiresCibles.covnm &&
                    referencesTrajectoiresCibles.nh3 &&
                    (referencesTrajectoiresCibles.nox.ref ||
                        referencesTrajectoiresCibles.sox.ref ||
                        referencesTrajectoiresCibles.pm10.ref ||
                        referencesTrajectoiresCibles.pm25.ref ||
                        referencesTrajectoiresCibles.covnm.ref ||
                        referencesTrajectoiresCibles.nh3.ref)
                ) {
                    this.setState({
                        displayAirQualityTrajectoryButton: true,
                    });
                }
                // bool which resets the values of the default target trajectories
                if (json.retourEtatDefaut === true) {
                    this.setState({
                        retourEtatDefaut: true,
                    });
                }

                // Infos for reference trajectories entered through admin interfaces
                let referencesTrajectoiresCiblesReference = {
                    energie_economisee:
                        json.trajectoires_reference.energie_economisee_ref,
                    energie_produite: json.trajectoires_reference.energie_produite_ref,
                    emission_ges: json.trajectoires_reference.emission_ges_ref,
                    covnm: json.trajectoires_reference.covnm_ref,
                    nox: json.trajectoires_reference.nox_ref,
                    sox: json.trajectoires_reference.sox_ref,
                    pm10: json.trajectoires_reference.pm10_ref,
                    pm25: json.trajectoires_reference.pm25_ref,
                    nh3: json.trajectoires_reference.nh3_ref,
                };
                // Info for history trajectories
                let referencesHistoryTrajectories = {
                    energie_economisee: json.historical_trajectories.energie_economisee,
                    energie_produite: json.historical_trajectories.energie_produite,
                    emission_ges: json.historical_trajectories.emission_ges,
                    nox: json.historical_trajectories.nox,
                    sox: json.historical_trajectories.sox,
                    pm10: json.historical_trajectories.pm10,
                    pm25: json.historical_trajectories.pm25,
                    covnm: json.historical_trajectories.covnm,
                    nh3: json.historical_trajectories.nh3,
                };
                this.setState({
                    referencesTrajectoiresCibles: referencesTrajectoiresCibles,
                    referencesTrajectoiresCiblesReference:
                        referencesTrajectoiresCiblesReference,
                    referencesHistoryTrajectories: referencesHistoryTrajectories,
                    displayEnergyTrajectoryButton: true,
                });
                // Set states
                this.props.parentApi.callbacks.mettreAJourParametresUrls(
                    "?zone=" +
                        zone.zone +
                        "&maille=" +
                        zone.maille +
                        "&zone_id=" +
                        zoneId
                );
                this.props.parentApi.callbacks.updateAnalysis("");
            })
            .catch((error) => {
                this.setState({ dataLoaded: true, error: error.message });
            });
        // Get coeffs from API
        dataSource = buildRegionUrl(
            config.api_plan_actions_coeffs_url,
            this.props.parentApi.data.region
        );
        if (chargerCoefficients) {
            Api.callApi(dataSource)
                .then((json) => {
                    let fields = [];
                    json.map(function (c) {
                        fields.push(c.nom);
                        return c.nom;
                    });

                    // get years from the API using conso-ener as a reference
                    let url = buildRegionUrl(
                        config.api_liste_annees_url.replace(
                            "#table#",
                            "DataSet.CONSUMPTION"
                        ),
                        this.props.parentApi.data.region
                    );
                    Api.callApi(url, null, "GET").then((response) => {
                        let years = [];
                        // start with the year following the year of data availability
                        for (
                            let y = response[response.length - 1].value + 1;
                            y <= configData.planActionDerniereAnnee;
                            y++
                        ) {
                            years.push(y);
                        }
                        // Add valeur_annee in json, so we can store each value for each year
                        for (let c of json) {
                            c.valeur_annee = {};
                            for (let y of years) {
                                if (c.choices) {
                                    // Default value is first element
                                    c.valeur_annee[y] = c.choices[0];
                                } else {
                                    c.valeur_annee[y] = 0; // Default value is 0
                                }
                            }
                        }
                        // Set states
                        this.setState({
                            years: years,
                            referenceYear: response[response.length - 1].value,
                            coeffs: json,
                            coeffsFields: fields,
                            error: "",
                        });

                        // Get advanced params from API
                        dataSource = buildRegionUrl(
                            config.api_plan_actions_advanced_params_url,
                            this.props.parentApi.data.region
                        );
                        dataSource += "?zone=" + zone.zone + "&zone_id=" + zoneId;
                        Api.callApi(dataSource)
                            .then((json) => {
                                let coeffsAdvancedByAction = [];
                                let globalParams = json.global_params;
                                for (let c of json.actions) {
                                    coeffsAdvancedByAction.push({
                                        action: c.action,
                                        params_avances: c.params_avances,
                                        params_avances_original: JSON.parse(
                                            JSON.stringify(c.params_avances)
                                        ),
                                    });
                                }
                                // Add valeur_annee in json, so we can store each value for each year when there is a advenced params with years
                                for (let c of coeffsAdvancedByAction) {
                                    if ("others_years" in c.params_avances) {
                                        Object.keys(
                                            c.params_avances.others_years
                                        ).forEach((key) => {
                                            let param =
                                                c.params_avances.others_years[key];
                                            param.valeur_annee = {};
                                            for (let y of years) {
                                                param.valeur_annee[y] = 0; // Default value is 0
                                            }
                                        });
                                    }
                                }
                                // Set states
                                this.setState({
                                    coeffsAdvanced: coeffsAdvancedByAction,
                                    coeffsAdvancedOriginal: JSON.parse(
                                        JSON.stringify(coeffsAdvancedByAction)
                                    ),
                                    globalParams: globalParams,
                                    error: "",
                                });
                            })
                            .catch((error) => {
                                this.setState({
                                    dataLoaded: true,
                                    error: error.message,
                                });
                            });
                    });
                })
                .catch((error) => {
                    this.setState({ dataLoaded: true, error: error.message });
                });
        }
        // let estimatedYears = {};
        // const analysisManager = this.props.parentApi.controller.analysisManager;
        // for (const table of ["DataSet.CONSUMPTION", "DataSet.EMISSIONS", "DataSet.PRODUCTION"]) {
        //     const analysis_id = analysisManager.obtenirIdentifiantAnalyse(table);
        //     estimatedYears[table] =
        //         analysisManager.getAnalysisEstimatedYears(analysis_id);
        // }
        // for (const table of ["DataSet.POLLUTANT_PM10", "DataSet.POLLUTANT_PM25", "DataSet.POLLUTANT_NOX", "DataSet.POLLUTANT_SOX", "DataSet.POLLUTANT_COVNM", "DataSet.POLLUTANT_NH3"]) {
        //     const analysis_id = analysisManager.obtenirIdentifiantAnalyse(
        //         "air_polluant_" + table
        //     );
        //     estimatedYears[table] =
        //         analysisManager.getAnalysisEstimatedYears(analysis_id);
        // }
        // estimatedYears["prod_enr_ratio"] = analysisManager.getAnalysisEstimatedYears(
        //     analysisManager.obtenirIdentifiantAnalyse("prod_enr", true)
        // );
        // this.setState({ estimatedYears: estimatedYears });
    }

    componentDidUpdate(prevProps, prevState) {
        // Reload data if the zone changes
        if (
            this.props.parentApi.data.currentZone !==
            prevProps.parentApi.data.currentZone
        ) {
            this.loadActionsData(
                this.props.parentApi.data.zone,
                this.props.parentApi.data.currentZone,
                true
            );
        }

        if (
            Object.keys(this.loadedScenarioData).length !== 0 &&
            this.loadedScenarioData.constructor === Object &&
            this.state.actions.length > 0 &&
            this.state.coeffs &&
            this.state.coeffsAdvanced
        ) {
            this.loadSavedActions();
            this.loadedScenarioData = {};
        }
    }

    /**
     * Get the actions coeffs from actions checked in local state element.
     *
     * @returns an object of checked actions
     */
    getCheckedActionsCoeffs() {
        // we first check which actions are checked
        let actionCochee = {};
        for (let a of this.state.actions) {
            let cochee = false;
            if (a.enabled) {
                cochee = true;
            }
            actionCochee[a.numero] = cochee;
        }
        // we make a copy of original array to be able to edit it without
        // breaking current coeffs
        let actionsCoeffs = JSON.parse(JSON.stringify(this.state.coeffs));
        // we then affect checked to coeffs
        for (let a of actionsCoeffs) {
            a.cochee = actionCochee[a.action];
        }
        return actionsCoeffs;
    }

    /**
     * Export the results inside an excel file from backend
     */
    exportResults(e) {
        let params = this.state.data;
        if (params === undefined || !params) {
            params = {};
        }
        if (params === undefined || !params || Object.keys(params).length === 0) {
            this.setState({
                error: "Il vous faut lancer le calcul avant d'en exporter les résultats.",
            });
        } else {
            // build URL
            let url = config.api_export_strategy_results;
            let urlIdUtilisateur =
                "&id_utilisateur=" +
                this.props.parentApi.controller.gestionSuiviConsultations.idUtilisateur;
            let zone = this.props.parentApi.data.zone;
            let dataSource =
                url +
                "?zone=" +
                zone.zone +
                "&zone_id=" +
                this.props.parentApi.data.currentZone +
                urlIdUtilisateur;
            let source_suivi_traj = JSON.parse(
                this.props.parentApi.data.settings["source_suivi_traj"]
            );
            let source = source_suivi_traj["source"];

            let territoryName =
                this.props.parentApi.controller.zonesManager.getZoneName(
                    this.props.parentApi.data.currentZone,
                    this.props.parentApi.data.zone
                );
            if (
                this.props.parentApi.data.zone.zone === "region" &&
                !this.props.parentApi.data.settings["is_national"]
            ) {
                territoryName = this.props.parentApi.data.regionLabel;
            }

            function flattenYearDict(dictOfDict) {
                // Convert trajectory data to flatten dict with keys similar to database content
                const flattenDict = {};
                for (const category in dictOfDict) {
                    for (const year in dictOfDict[category]) {
                        const newCategory = category
                            .replace("energie_economisee", "consommation_ener")
                            .replace("energie_produite", "enr_production");
                        const key = newCategory + "_annee_" + year;
                        // TODO: interpolate ?
                        flattenDict[key] = dictOfDict[category][year];
                    }
                }
                return flattenDict;
            }

            // prepare parameters used to export the results
            let trajectories = {
                custom: this.trajectoires,
                target: flattenYearDict(this.state.referencesTrajectoiresCibles),
                target_ref: this.state.referencesTrajectoiresCiblesReference,
                pcaet: this.state.pcaetAdeme,
            };

            let requestParams = {
                results: params,
                trajectories: trajectories,
                title: this.state.titre,
                parameters: {
                    actions: this.state.actions,
                    coeffs: this.getCheckedActionsCoeffs(),
                    years: this.state.years,
                    coeffsAdvanced: this.state.coeffsAdvanced,
                },
                source: source,
                territory_name: territoryName,
            };

            // fetch and then display file
            fetch(buildRegionUrl(dataSource, this.props.parentApi.data.region), {
                method: "post",
                body: JSON.stringify(requestParams),
                credentials: "same-origin",
                mode: "cors",
            }).then((response) => {
                if (response.ok) {
                    // Display file resulting from the export
                    return response.blob().then((data) => {
                        saveAs(data, "export_impact_strategie_territoriale.xlsx");
                    });
                } else {
                    return response.text().then((text) => {
                        alert(text);
                    });
                }
            });
        }
    }

    launchPlanActions(e) {
        this.processPlanActions(false, false);
    }

    exportPlanActions(e) {
        this.processPlanActions(true, false);
    }

    exportParamsStrategie(e) {
        this.processPlanActions(false, true);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // when we are running a plan, we do not render the data
        // (or when we leave the export)
        if (
            nextState.runningPlan ||
            (!nextState.runningPlan && this.state.runningPlan)
        ) {
            return false;
            // when importing a file
        } else if (
            nextState.fichierDonnees !== "" &&
            !nextState.status &&
            !nextState.erreur
        ) {
            return false;
        } else {
            return true;
        }
    }

    processPlanActions(xls, exportParams) {
        // Call API with goods params
        let url = config.api_plan_actions_running_plan;

        if (xls) {
            url += "/export";
            this.setState({ runningPlan: true });
        } else if (exportParams) {
            url += "/export_params";
            this.setState({ runningPlan: true });
        } else {
            this.setState({ data: undefined });
        }

        // delete the error message if it exists each time the calculation is launched
        let planActionObject = this;
        if (this.state.error !== "") {
            this.setState({ error: "" });
        }

        let zone = this.props.parentApi.data.zone;

        if (
            zone.zone === "region" &&
            !this.props.parentApi.data.settings["is_national"]
        ) {
            // the region is unique, it is not selected in a list
            this.props.parentApi.data.currentZone =
                this.props.parentApi.data.regionCode;
        }

        // Get coeffs and actions
        let params = {};
        let actions = {};
        let paramsRef = {};

        let invalidValue = false;
        // Get advanced coeffs
        let advanced = {};
        // Get advanced coeffs with years
        let yearAdvancedParams = {};
        let update = false;
        let invalidValueForadvancedParms = false;
        let noValueForCustomYears = false;
        let advancedActionError;
        let lastFilledYearResidentialHeatingAction;
        let lastFilledYearTertiaryHeatingAction;
        let filledYearTertiaryHeatingActionParams = [];
        let filledYearResidentialHeatingActionParams = [];
        for (let a of this.state.actions) {
            if (a !== undefined && a.enabled !== undefined && a.enabled) {
                for (let coeff of this.state.coeffs) {
                    // we skip the coefficients of other actions
                    if (coeff.action !== a.numero) {
                        continue;
                    }
                    // We have to get all fields (years)
                    let values = {};
                    for (let y of this.state.years) {
                        let value = String(coeff.valeur_annee[y]);
                        // Get the corresponding action
                        // ex:  {2018: [3,4], 2019: [2]}
                        if (value.trim().length !== 0 || value !== "") {
                            values[y] = value;
                            if (!actions[y]) actions[y] = [];
                            if (!actions[y].includes(a.numero))
                                actions[y].push(a.numero);

                            // Get the last year filled for action 16 and 17 to check if the advanced parameter with years is filled correctly
                            if (
                                a.numero === "16" &&
                                parseFloat(value) !== 0 &&
                                a.annee_ref !== y
                            ) {
                                lastFilledYearResidentialHeatingAction = y;
                            }
                            if (
                                a.numero === "17" &&
                                parseFloat(value) !== 0 &&
                                a.annee_ref !== y
                            ) {
                                lastFilledYearTertiaryHeatingAction = y;
                            }
                        }
                    }
                    params[coeff.nom] = values;
                }

                for (let c of this.state.coeffsAdvanced) {
                    if (c.action === a.numero) {
                        // Economic
                        let paramsEconomique = {};
                        for (let phase of c.params_avances.economique) {
                            // get the value (proportion)
                            let value = phase.part;
                            if (value === "") {
                                invalidValue = true;
                                break;
                            }

                            paramsEconomique[Number(phase.id)] = Number(value);
                        }
                        params["economique"] = {
                            ...(params["economique"] ?? {}),
                            ...paramsEconomique,
                        };

                        // Others
                        advanced[a.numero] = {};
                        for (let subkey in c.params_avances.autres) {
                            let paramsAutres = {};
                            for (let i in c.params_avances.autres[subkey].index) {
                                let idxValeur =
                                    c.params_avances.autres[subkey].columns.indexOf(
                                        "valeur"
                                    );
                                let idxName =
                                    c.params_avances.autres[subkey].columns.indexOf(
                                        "nom"
                                    );
                                let value =
                                    c.params_avances.autres[subkey].data[i][idxValeur];
                                if (value === "") {
                                    invalidValue = true;
                                    break;
                                }
                                if (["16", "17", "18", "19"].includes(a.numero)) {
                                    // update the coefficients of the reference year
                                    // for actions 16 and 17: conversion of heating equipment in the residential and tertiary sector
                                    // and 18 and 19 : mobility actions
                                    update = true;
                                }
                                if (idxName !== -1) {
                                    paramsAutres[
                                        c.params_avances.autres[subkey].data[i][idxName]
                                    ] = Number(value);
                                } else {
                                    paramsAutres[
                                        Number(c.params_avances.autres[subkey].index[i])
                                    ] = Number(value);
                                }
                            }
                            advanced[a.numero][subkey] = paramsAutres;
                        }
                        if ("others_years" in c.params_avances) {
                            yearAdvancedParams[a.numero] = {};
                            for (let subkey in c.params_avances.others_years) {
                                let otherYearParams = {};
                                for (let subParam in c.params_avances.others_years[
                                    subkey
                                ].index) {
                                    // We have to get all fields (years)
                                    let Advancedvalues = {};
                                    for (let y of this.state.years) {
                                        let value = String(
                                            c.params_avances.others_years[subkey]
                                                .valeur_annee[y]
                                        );

                                        if (value.trim().length !== 0 || value !== "") {
                                            Advancedvalues[y] = value;
                                        }
                                        // Get params years filled for action 16 and 17 to check if the advanced parameter with years is filled correctly
                                        if (
                                            a.numero === "16" &&
                                            parseFloat(value) !== 0 &&
                                            !filledYearResidentialHeatingActionParams.includes(
                                                y
                                            ) &&
                                            a.annee_ref !== y
                                        ) {
                                            filledYearResidentialHeatingActionParams.push(
                                                y
                                            );
                                        }
                                        if (
                                            a.numero === "17" &&
                                            parseFloat(value) !== 0 &&
                                            !filledYearTertiaryHeatingActionParams.includes(
                                                y
                                            ) &&
                                            a.annee_ref !== y
                                        ) {
                                            filledYearTertiaryHeatingActionParams.push(
                                                y
                                            );
                                        }
                                    }
                                    // add the reference year if not exists
                                    if (
                                        ["16", "17"].includes(a.numero) &&
                                        !Object.keys(Advancedvalues).includes(
                                            a.annee_ref
                                        )
                                    ) {
                                        Advancedvalues[a.annee_ref] = String(0);
                                    }
                                    otherYearParams[
                                        Number(
                                            c.params_avances.others_years[subkey].index[
                                                subParam
                                            ]
                                        )
                                    ] = Advancedvalues;
                                }
                                yearAdvancedParams[a.numero][subkey] = otherYearParams;
                            }

                            // check if the params filled correctly
                            for (let subkey in c.params_avances.others_years) {
                                // the value of "year + 1" should be greater than the value "year" for  action 16 and 17
                                if (a.numero === "16") {
                                    if (
                                        filledYearResidentialHeatingActionParams.length >
                                        1
                                    ) {
                                        if (
                                            lastFilledYearResidentialHeatingAction &&
                                            !filledYearResidentialHeatingActionParams.includes(
                                                lastFilledYearResidentialHeatingAction
                                            )
                                        ) {
                                            // means that the value of last year is 0
                                            noValueForCustomYears = true;
                                            advancedActionError = a.name;
                                            break;
                                        }
                                        for (let index in filledYearResidentialHeatingActionParams) {
                                            for (
                                                let nextIndex = parseInt(index) + 1;
                                                nextIndex <=
                                                filledYearResidentialHeatingActionParams.length;
                                                nextIndex++
                                            ) {
                                                const firstValue = parseFloat(
                                                    c.params_avances.others_years[
                                                        subkey
                                                    ].valeur_annee[
                                                        filledYearResidentialHeatingActionParams[
                                                            index
                                                        ]
                                                    ]
                                                );
                                                const nextValue = parseFloat(
                                                    c.params_avances.others_years[
                                                        subkey
                                                    ].valeur_annee[
                                                        filledYearResidentialHeatingActionParams[
                                                            nextIndex
                                                        ]
                                                    ]
                                                );
                                                if (nextValue < firstValue) {
                                                    invalidValueForadvancedParms = true;
                                                    advancedActionError = a.name;
                                                    break;
                                                }
                                            }

                                            if (invalidValueForadvancedParms) break;
                                        }
                                    } else {
                                        const firstValue = parseFloat(
                                            c.params_avances.others_years[subkey]
                                                .valeur_annee[
                                                filledYearResidentialHeatingActionParams[0]
                                            ]
                                        );
                                        const nextValue = parseFloat(
                                            c.params_avances.others_years[subkey]
                                                .valeur_annee[
                                                lastFilledYearResidentialHeatingAction
                                            ]
                                        );
                                        if (nextValue < firstValue) {
                                            invalidValueForadvancedParms = true;
                                            advancedActionError = a.name;
                                            break;
                                        }
                                    }
                                }

                                if (a.numero === "17") {
                                    if (
                                        filledYearTertiaryHeatingActionParams.length > 1
                                    ) {
                                        if (
                                            lastFilledYearTertiaryHeatingAction &&
                                            !filledYearTertiaryHeatingActionParams.includes(
                                                lastFilledYearTertiaryHeatingAction
                                            )
                                        ) {
                                            // means that the value of last year is 0
                                            noValueForCustomYears = true;
                                            advancedActionError = a.name;
                                            break;
                                        }
                                        for (let index in filledYearTertiaryHeatingActionParams) {
                                            for (
                                                let nextIndex = parseInt(index) + 1;
                                                nextIndex <
                                                filledYearTertiaryHeatingActionParams.length;
                                                nextIndex++
                                            ) {
                                                const firstValue = parseFloat(
                                                    c.params_avances.others_years[
                                                        subkey
                                                    ].valeur_annee[
                                                        filledYearTertiaryHeatingActionParams[
                                                            index
                                                        ]
                                                    ]
                                                );
                                                const nextValue = parseFloat(
                                                    c.params_avances.others_years[
                                                        subkey
                                                    ].valeur_annee[
                                                        filledYearTertiaryHeatingActionParams[
                                                            nextIndex
                                                        ]
                                                    ]
                                                );
                                                if (nextValue < firstValue) {
                                                    invalidValueForadvancedParms = true;
                                                    advancedActionError = a.name;
                                                    break;
                                                }
                                            }

                                            if (invalidValueForadvancedParms) break;
                                        }
                                    } else {
                                        const firstValue = parseFloat(
                                            c.params_avances.others_years[subkey]
                                                .valeur_annee[
                                                filledYearTertiaryHeatingActionParams[0]
                                            ]
                                        );
                                        const nextValue = parseFloat(
                                            c.params_avances.others_years[subkey]
                                                .valeur_annee[
                                                lastFilledYearTertiaryHeatingAction
                                            ]
                                        );

                                        if (nextValue < firstValue) {
                                            invalidValueForadvancedParms = true;
                                            advancedActionError = a.name;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        params["actions"] = actions;
        paramsRef["actions"] = this.state.actions;
        params["advanced"] = advanced;
        params["yearAdvancedParams"] = yearAdvancedParams;
        paramsRef["advanced"] = advanced;

        // Check if all coeff for selected actions has been filled, if not: stop the process
        let somme;
        let sommeKmParcourus = 0;
        let sommePourcentageIndus = 0;
        let sommePourcentageAgri = 0;
        let invalidTotal = false;
        let invalidTotalKmParcourus = false;
        let invalidTotalIndus = false;
        let invalidTotalAgri = false;
        let actionErreur;
        let anneeErreur;
        let actionErreurKm;
        let actionErreurIndus;
        let actionErreurAgri;
        let actionErreurChauffage;
        let anneeErreurChauffage;
        for (let y of this.state.years) {
            for (let a of this.state.actions) {
                if (a.enabled) {
                    if (y === a.annee_ref) {
                        continue;
                    }
                    somme = 0;
                    for (let coeff of this.state.coeffs) {
                        if (coeff.action === a.numero) {
                            let fieldYear = coeff.nom + "#" + y;
                            if (this.refs[fieldYear]) {
                                let value = trim(
                                    ReactDOM.findDOMNode(this.refs[fieldYear]).value
                                );
                                if (value === "") {
                                    invalidValue = true;
                                    actionErreur = a.name;
                                    anneeErreur = y;
                                    break;
                                } else if (["16", "17"].includes(a.numero)) {
                                    somme += parseFloat(value);
                                } else if (a.numero === "18") {
                                    sommeKmParcourus += parseFloat(value);
                                    actionErreurKm = a.name;
                                } else if (a.numero === "14") {
                                    sommePourcentageIndus += parseFloat(value);
                                    actionErreurIndus = a.name;
                                } else if (a.numero === "15") {
                                    sommePourcentageAgri += parseFloat(value);
                                    actionErreurAgri = a.name;
                                }
                            }
                        }
                    }
                    // Check if the sum of all user inputs for actions 16 and 17 for a year equals to 100% , if not: stop the process
                    // why round to 10 decimal places?
                    // computers use a format (binary floating-point) that cannot accurately represent a number like 0.1, 0.2, or 0.3.
                    // When code is compiled or interpreted, a number like "0.1" is rounded to the nearest number in that format, resulting in (in most cases) a small rounding error
                    // Exemple :
                    // the expected result for : 27.4 + 2.4 + 14.8 + 30 + 9.6 + 15.8 is : 100
                    // the result obtained in this case is : 100.00000000000001
                    // which leads to a comparison error
                    if (
                        somme !== 0 &&
                        (somme.toFixed(10) < 100 || somme.toFixed(10) > 100)
                    ) {
                        invalidTotal = true;
                        actionErreurChauffage = a.name;
                        anneeErreurChauffage = y;
                        break;
                    }
                    // check if the sum of the user inputs equals to 100% for action 18: Reduction of distance traveled on d/t trips
                    if (sommeKmParcourus > 100) {
                        invalidTotalKmParcourus = true;
                        break;
                    }
                    // check if the sum of the user inputs is greater than 60% for action 14
                    if (sommePourcentageIndus > 60) {
                        invalidTotalIndus = true;
                        break;
                    }
                    // check if the sum of the user inputs is greater than 60% for action 15
                    if (sommePourcentageAgri > 60) {
                        invalidTotalAgri = true;
                        break;
                    }
                }
            }
        }

        if (invalidValue) {
            this.setState({
                error:
                    "Merci de renseigner une valeur ou à défaut 0 dans les cases vide (action : " +
                    actionErreur +
                    ", année : " +
                    anneeErreur +
                    ")",
            });
            return;
        }
        if (invalidTotal) {
            this.setState({
                error:
                    "La somme des valeurs des différents équipements doit être égale à 100% (action : " +
                    actionErreurChauffage +
                    ", année : " +
                    anneeErreurChauffage +
                    ")",
            });
            return;
        }
        if (invalidTotalKmParcourus) {
            this.setState({
                error:
                    "La somme des valeurs renseignées pour les différentes années ne doit pas dépasser 100% (action : " +
                    actionErreurKm +
                    ")",
            });
            return;
        }
        if (invalidTotalIndus) {
            this.setState({
                error:
                    "La somme des valeurs renseignées pour les différentes années ne doit pas dépasser 60% (action : " +
                    actionErreurIndus +
                    ")",
            });
            return;
        }
        if (invalidTotalAgri) {
            this.setState({
                error:
                    "La somme des valeurs renseignées pour les différentes années ne doit pas dépasser 60% (action : " +
                    actionErreurAgri +
                    ")",
            });
            return;
        }

        if (invalidValueForadvancedParms) {
            this.setState({
                error:
                    "La valeur de l'année N ne doit pas être inférieure à la valeur de l'année N-1 (dans les paramètres avancés de l'action " +
                    advancedActionError +
                    ")",
            });
            return;
        }

        if (noValueForCustomYears) {
            this.setState({
                error:
                    "Quand une personnalisation de certains paramètres avancés annualisés est faite, des valeurs de paramètres avancés doivent être impérativement renseignés pour les années où une action est mise en place pour l'action " +
                    advancedActionError,
            });
            return;
        }

        // HACK For action 20 (Alternative motorization for public transport), for a given year the sums bus/car elec/gnv cannot exceed 100%
        let invalidValueFor20 = false;
        somme = 0;
        for (let a of this.state.actions) {
            for (let y of this.state.years) {
                if (a.enabled && a.numero === "20") {
                    for (let coeff of this.state.coeffs) {
                        if (coeff.action === a.numero) {
                            let fieldYear = coeff.nom + "#" + y;
                            if (this.refs[fieldYear]) {
                                let value = parseInt(
                                    trim(
                                        ReactDOM.findDOMNode(this.refs[fieldYear]).value
                                    )
                                );
                                if (
                                    coeff.nom === "20_bus_elec" ||
                                    coeff.nom === "20_bus_gnv" ||
                                    coeff.nom === "20_bus_diesel"
                                ) {
                                    somme += value;
                                }
                            }
                        }
                    }
                    // Check if the sum of all user inputs for actions 16 and 17 for a year equals to 100% , if not: stop the process
                    if (somme > 100) {
                        invalidValueFor20 = true;
                        break;
                    }
                }
            }
        }
        if (invalidValueFor20) {
            this.setState({
                error: "La somme des valeurs pour les bus par année et par type ne peut pas dépasser 100%",
            });
            return;
        }

        // HACK
        // For action 8, check if coeff for year n+1 is > coeff year n
        // heat from solar thermal = heat delivered * percentage of solar thermal
        // heat from solar thermal n+1 > heat from solar thermal n
        invalidValue = false;
        for (let a of this.state.actions) {
            if (a.enabled && a.numero === "8") {
                let prevValue = 0;
                for (let y of this.state.years) {
                    let chaleurLivree = 0;
                    let partSolaire = 0;
                    for (let coeff of this.state.coeffs) {
                        if (coeff.action === a.numero) {
                            let fieldYear = coeff.nom + "#" + y;
                            if (this.refs[fieldYear]) {
                                let value = trim(
                                    ReactDOM.findDOMNode(this.refs[fieldYear]).value
                                );
                                if (coeff.nom === "8_chaleur_livree")
                                    chaleurLivree = parseFloat(value);
                                if (coeff.nom === "8_part_solaire")
                                    partSolaire = parseFloat(value);
                            }
                        }
                    }
                    let chaleur = chaleurLivree * partSolaire;
                    if (chaleur > 0 && chaleur < prevValue) {
                        invalidValue = true;
                        break;
                    } else {
                        prevValue = chaleur;
                    }
                }
                break;
            }
        }
        if (invalidValue) {
            this.setState({
                error: "Erreur dans la saisie des paramètres de l'action \"modification du mix énergétique d’un réseau de chaleur\". La chaleur issue du solaire thermique (chaleur livrée * % de solaire thermique) saisie à un instant t ne peut pas être inférieure à la valeur de l'année précédente",
            });
            return;
        }
        // END HACK
        let urlIdUtilisateur =
            "&id_utilisateur=" +
            this.props.parentApi.controller.gestionSuiviConsultations?.idUtilisateur;

        let dataSource = buildRegionUrl(
            url +
                "?zone=" +
                zone.zone +
                "&zone_id=" +
                this.props.parentApi.data.currentZone +
                urlIdUtilisateur,
            this.props.parentApi.data.region
        );
        // reset loader
        this.setState({ dataLoaded: false });
        let trajCiblesCochees = [];
        // fetch by POST
        // We call the api once, and in return we have the 4 possible combinaisons (direct/indirect with ponctuel/perenne)
        if (this.state.showTrajectoireCibleEnergieClimat) {
            for (let categorie in this.trajectoires) {
                if (this.trajectoires[categorie].annees_modifiees.length) {
                    trajCiblesCochees.push(categorie);
                }
            }
        }
        params["trajectoires_cibles"] = trajCiblesCochees;
        if (xls) {
            var xhr = new XMLHttpRequest();
            xhr.open("POST", dataSource, true);
            //xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

            xhr.responseType = "blob";
            xhr.withCredentials = true;
            xhr.onload = function (e) {
                if (this.status === 200) {
                    var blob = this.response;
                    saveAs(blob, "export-pcaet-ademe.xlsx");
                    planActionObject.setState({
                        dataLoaded: true,
                        runningPlan: false,
                    });
                }
            };
            xhr.send(JSON.stringify(params));
        } else if (exportParams) {
            var xhrParams = new XMLHttpRequest();
            xhrParams.open(
                "POST",
                buildRegionUrl(dataSource, this.props.parentApi.data.region),
                true
            );
            xhrParams.responseType = "blob";
            xhrParams.withCredentials = true;
            xhrParams.onload = function (e) {
                if (this.status === 200) {
                    var blob = this.response;
                    saveAs(blob, "export_parametres.csv");
                    planActionObject.setState({
                        dataLoaded: true,
                        runningPlan: false,
                    });
                }
            };

            let actionsCoeffs = this.getCheckedActionsCoeffs();
            xhrParams.send(JSON.stringify(actionsCoeffs));
        } else {
            if (update) {
                this.loadActionsDataRef(
                    this.props.parentApi.data.zone,
                    this.props.parentApi.data.currentZone,
                    paramsRef
                );
            }
            Api.callApi(
                buildRegionUrl(dataSource, this.props.parentApi.data.region),
                JSON.stringify(params),
                "POST"
            )
                .then((data) => {
                    // display or not the air pollutants target trajectories chart
                    if (
                        data.nox &&
                        data.sox &&
                        data.pm10 &&
                        data.pm25 &&
                        data.covnm &&
                        data.nh3
                    ) {
                        this.setState({
                            displayAirQualityChart: true,
                        });
                    } else {
                        this.setState({
                            displayAirQualityChart: false,
                        });
                    }

                    if (data.failed_actions) {
                        let disabledActions = data.failed_actions.map((a) => {
                            return a.action_key;
                        });
                        let listeDesActions = [...this.state.listeDesActions];
                        listeDesActions = listeDesActions.filter(
                            (a) => !disabledActions.includes(a)
                        );
                        let actions = [...this.state.actions];
                        for (const i in actions) {
                            if (disabledActions.includes(actions[i].numero)) {
                                actions[i].enabled = false;
                            }
                        }
                        const popupContent = (
                            <div>
                                <p>
                                    Les actions suivantes ont été automatiquement
                                    désactivées à cause d'une erreur survenue au cours
                                    de l'exécution. Si l'erreur se reproduit, merci de
                                    transmettre les informations et les détails de la
                                    situation à un administrateur.
                                </p>
                                <ul>
                                    {data.failed_actions.map((a) => (
                                        <li key={a.action_key}>{a.name}</li>
                                    ))}
                                </ul>
                            </div>
                        );
                        const warningDisabledActions = (
                            <DetailsPopup
                                title="Actions désactivées au cours de l'exécution"
                                content={popupContent}
                                show={true}
                                emptyMsg="Nothing to show"
                                callbackAfterClosing={() => {
                                    this.setState({ warningDisabledActions: "" });
                                }}
                            />
                        );

                        // Display results
                        this.setState({
                            data: data,
                            dataLoaded: true,
                            error: "",
                            runningPlan: false,
                            actions: actions,
                            listeDesActions: listeDesActions,
                            disabledActions: disabledActions,
                            warningDisabledActions: warningDisabledActions,
                        });
                    } else {
                        // Display results
                        this.setState({
                            data: data,
                            dataLoaded: true,
                            error: "",
                            runningPlan: false,
                        });
                    }
                    const chartNode = ReactDOM.findDOMNode(this.refs.results);
                    chartNode.scrollIntoView({ behavior: "smooth" });
                })
                .catch((error) => {
                    this.setState({
                        dataLoaded: true,
                        error: error.message,
                        runningPlan: false,
                    });
                });
        }
    }

    /**
     * Callback to update a table
     * @param  {json} tableRow : current line
     */
    chargerParametresStrategies() {
        this.setState({ data: undefined });

        let formData = new FormData();
        formData.append("fichier", this.state.fichierDonnees);
        formData.append("nomFichier", this.state.fichierDonnees.name);

        // Call API with the file and the metadata entered by the user
        Api.callApi(
            buildRegionUrl(
                config.api_charger_params_strategie,
                this.props.parentApi.data.region
            ),
            formData,
            "POST",
            "default"
        )
            .then((response) => {
                this.setState({ status: response.message });
                let listeActions = [];
                // only check the actions checked in the file
                for (let action of response) {
                    if (action.cochee && listeActions.indexOf(action.action) === -1) {
                        listeActions.push(action.action);
                    }
                }

                let valeurs_annees_conso = {
                    annees_valeurs: {},
                    annees_modifiees: {},
                };
                let valeurs_annees_ges = {
                    annees_valeurs: {},
                    annees_modifiees: {},
                };
                let valeurs_annees_prod = {
                    annees_valeurs: {},
                    annees_modifiees: {},
                };
                // values per year for air pollutants analysis
                let valeurs_annees_nox = {
                    annees_valeurs: {},
                    annees_modifiees: {},
                };
                let valeurs_annees_sox = {
                    annees_valeurs: {},
                    annees_modifiees: {},
                };
                let valeurs_annees_pm10 = {
                    annees_valeurs: {},
                    annees_modifiees: {},
                };
                let valeurs_annees_pm25 = {
                    annees_valeurs: {},
                    annees_modifiees: {},
                };
                let valeurs_annees_covnm = {
                    annees_valeurs: {},
                    annees_modifiees: {},
                };
                let valeurs_annees_nh3 = {
                    annees_valeurs: {},
                    annees_modifiees: {},
                };

                let trajFinale = {};
                for (let annee of this.state.years) {
                    valeurs_annees_conso.annees_valeurs[annee] =
                        this.state.referencesTrajectoiresCibles.energie_economisee?.ref;
                    valeurs_annees_ges.annees_valeurs[annee] =
                        this.state.referencesTrajectoiresCibles.emission_ges?.ref;
                    valeurs_annees_prod.annees_valeurs[annee] =
                        this.state.referencesTrajectoiresCibles.energie_produite?.ref;
                    // for air pollutants analysis
                    valeurs_annees_nox.annees_valeurs[annee] =
                        this.state.referencesTrajectoiresCibles.nox?.ref;
                    valeurs_annees_sox.annees_valeurs[annee] =
                        this.state.referencesTrajectoiresCibles.sox?.ref;
                    valeurs_annees_pm10.annees_valeurs[annee] =
                        this.state.referencesTrajectoiresCibles.pm10?.ref;
                    valeurs_annees_pm25.annees_valeurs[annee] =
                        this.state.referencesTrajectoiresCibles.pm25?.ref;
                    valeurs_annees_covnm.annees_valeurs[annee] =
                        this.state.referencesTrajectoiresCibles.covnm?.ref;
                    valeurs_annees_nh3.annees_valeurs[annee] =
                        this.state.referencesTrajectoiresCibles.nh3?.ref;
                }

                let zone = this.props.parentApi.data.zone;
                let zoneType = zone.zone + "#" + zone.maille;

                trajFinale.energie_economisee = valeurs_annees_conso;
                trajFinale.emission_ges = valeurs_annees_ges;
                trajFinale.energie_produite = valeurs_annees_prod;

                // for air pollutants analysis
                trajFinale.nox = valeurs_annees_nox;
                trajFinale.sox = valeurs_annees_sox;
                trajFinale.pm10 = valeurs_annees_pm10;
                trajFinale.pm25 = valeurs_annees_pm25;
                trajFinale.covnm = valeurs_annees_covnm;
                trajFinale.nh3 = valeurs_annees_nh3;

                // we copy the coefficients and only update the values
                // so as not to lose the names of the coefficients or their unit
                let newCoeffs = [...this.state.coeffs];
                for (let i in response) {
                    const action = response[i];
                    const nomAction = action["nom"];
                    let obj = newCoeffs.find((o) => o.nom === nomAction);
                    if (obj && obj !== undefined) {
                        obj["valeur_annee"] = action["valeur_annee"];
                        obj["cochee"] = action["cochee"];
                    }
                }

                let annee_ref = this.state.anneeRefDataConso;
                let actions = [...this.state.actions];
                for (const i in actions) {
                    if (listeActions.includes(actions[i].numero)) {
                        actions[i].enabled = true;
                        // Update the reference year if action 16: conversion of heating equipment in the residential sector has been checked
                        if (["16", "17"].includes(actions[i].numero)) {
                            annee_ref = actions[i].annee_ref;
                        }
                    }
                }
                this.setState({
                    coeffs: newCoeffs,
                    chargementFichier: true,
                    listeDesActions: listeActions,
                    anneeRefDataConso: annee_ref,
                    actions: [...actions],
                });
                this.trajectoires = trajFinale;

                this.loadParams(
                    listeActions,
                    newCoeffs,
                    this.state.coeffsAdvanced,
                    trajFinale,
                    zoneType,
                    this.props.parentApi.data.currentZone,
                    "",
                    this.state.is_national
                );
            })
            .catch((e) => {
                this.setState({ erreur: e.message });
            });
    }

    /**
     * Data file to import
     * @param {event} event the event triggered by input[file]
     */
    importFile = (event) => {
        this.setState({
            fichierDonnees: event.target.files[0],
            status: undefined,
            erreur: undefined,
        });
    };

    /**
     * Reset all coeffs to default values.
     *
     * @param {event} e the event triggered
     * @returns if the user doesn't confirm, exits
     */
    resetCoeffs(e) {
        // Erase all coeffs
        // Update state with coeff changed
        let r = window.confirm(
            "Êtes-vous vraiment sûr.e de vouloir réinitialiser les valeurs de toutes actions ?"
        );
        if (r !== true) {
            return;
        } else {
            let coeffs = this.state.coeffs;
            for (let c of coeffs) {
                c.valeur_annee = {};
                for (let y of this.state.years) {
                    if (c.choices) {
                        // when we have choices, we use first value as default
                        c.valeur_annee[y] = c.choices[0];
                    } else {
                        // otherwise, default value is 0
                        c.valeur_annee[y] = 0;
                    }
                }
            }

            // No need to reset the form, model is suffisant.
            this.setState({
                coeffs: coeffs,
                coeffsAdvanced: JSON.parse(
                    JSON.stringify(this.state.coeffsAdvancedOriginal)
                ),
                data: undefined,
            });
        }
    }

    /**
     * Reset target trajectories.
     *
     * @param {event} e the event triggered
     * @returns if the user doesn't confirm, exits
     */
    resetTargetTrajectories(e) {
        let r = window.confirm(
            "Êtes-vous vraiment sûr.e de vouloir réinitialiser les valeurs de toutes les trajectoires cibles ?"
        );
        if (r !== true) {
            return;
        } else {
            this.resetTrajectoiresCibles = true;

            this.setState({
                resetTrajectoiresCibles: true,
                data: undefined,
                loadedTrajectory: undefined,
            });
        }
    }

    ajouterRetirerTrajectoireCible(e) {
        let showTrajectoireCibleEnergieClimat =
            !this.state.showTrajectoireCibleEnergieClimat;
        this.setState({
            showTrajectoireCibleEnergieClimat: showTrajectoireCibleEnergieClimat,
            data: undefined,
        });
    }

    ajouterRetirerTrajectoireCibleQualiteAir(e) {
        let showTrajectoireCibleQualiteAir = !this.state.showTrajectoireCibleQualiteAir;
        this.setState({
            showTrajectoireCibleQualiteAir: showTrajectoireCibleQualiteAir,
            data: undefined,
        });
    }

    /**
     * Save as png the canvas containing the image
     * @param {string} ref the ref to use
     */
    saveCanvasAsPng(ref) {
        var a = document.createElement("a");
        a.href = this.refs[ref].canvas.toDataURL();
        a.download = "plan_action_" + ref + ".png";
        a.click();
    }

    /**
     * Load the saved actions
     */
    loadSavedActions() {
        let actionsScenario = this.loadedScenarioData.actionsScenario;
        let params = this.loadedScenarioData.params;
        let advanced = this.loadedScenarioData.advanced;
        let trajectoires = this.loadedScenarioData.trajectoires;
        let titre = this.loadedScenarioData.titre;
        let description = this.loadedScenarioData.description;
        let coeffs = this.state.coeffs;
        params.forEach((p) => {
            for (let c of coeffs) {
                if (p.nom === c.nom) {
                    c.valeur_annee = p.valeur;
                    break;
                }
            }
        });

        // Load advanced params
        let coeffsAdvancedByAction = [];
        for (let c of advanced) {
            coeffsAdvancedByAction.push({
                action: c.action,
                params_avances: c.params_avances,
            }); // TODO add also original values ?
        }

        // Also, enable / disable actions according to the scenario loaded
        let actions = this.state.actions;
        // Reset actions
        actions.forEach((a) => {
            a.enabled = false;
        });

        actionsScenario.forEach((a) => {
            for (let ca of actions) {
                if (a === ca.numero) {
                    ca.enabled = true;
                }
            }
        });

        // Load trajectoires
        let showTrajectoireCibleEnergieClimat = false;

        this.trajectoires = trajectoires;
        if (
            Object.keys(this.trajectoires).length !== 0 &&
            this.trajectoires.constructor === Object
        ) {
            showTrajectoireCibleEnergieClimat = true;
        }
        this.resetTrajectoiresCibles = true;

        const trajectoiresCibles = {};
        for (const trajectoryName in trajectoires) {
            const { annees_valeurs, annees_modifiees } = trajectoires[trajectoryName];
            trajectoiresCibles[trajectoryName] = Object.fromEntries(
                Object.entries(annees_valeurs).filter(
                    ([year, _], index) => annees_modifiees[year] || index === 0
                )
            );
        }

        // Update state with coeff and actions changed
        this.setState({
            coeffs: coeffs,
            coeffsAdvanced: coeffsAdvancedByAction,
            actions: actions,
            showTrajectoireCibleEnergieClimat: showTrajectoireCibleEnergieClimat,
            titre: titre,
            description: description,
            pcaetAdeme: this.loadedScenarioData.pcaetAdeme,
            dataScenario: this.loadedScenarioData.url,
            loadedTrajectory: trajectoiresCibles,
        });
    }

    /**
     * Load param when the user select a specific scenario, and change display to planActions
     *
     * @param {array} actionsScenario the scenario of actions
     * @param {array} params the params used
     * @param {array} advanced the advanced params used
     * @param {array} trajectoires the trajectories used
     * @param {string} zone_type the type of zone
     * @param {string} zone_id the zone ID
     * @param {string} description the description of current plan
     * @param {string} titre the title of current plan
     * @param {string} url the url to use to load data
     */
    loadParams(
        actionsScenario,
        params,
        advanced,
        trajectoires,
        zone_type,
        zone_id,
        description,
        titre,
        url,
        is_national
    ) {
        this.planActions.loadedScenarioData = {};
        this.planActions.loadedScenarioData.actionsScenario = actionsScenario;
        this.planActions.loadedScenarioData.params = params;
        this.planActions.loadedScenarioData.advanced = advanced;
        this.planActions.loadedScenarioData.trajectoires = trajectoires;
        this.planActions.loadedScenarioData.description = description;
        this.planActions.loadedScenarioData.titre = titre;
        // Like in the "new strategy" case, the toggle button is initialized to true
        this.planActions.loadedScenarioData.pcaetAdeme = true;
        // retrieve the url to retrieve once again, later , the data of the trajectory
        this.planActions.loadedScenarioData.url = url;
        // Reload page with plan_actions mode
        let zone = {};
        zone.zone = zone_type.split("#")[0];
        zone.maille = zone_type.split("#")[1];

        let route_param = is_national
            ? "national/" +
              this.planActions.props.parentApi.data.region +
              "/strategie_territoriale"
            : "strategie_territoriale";
        this.planActions.props.parentApi.callbacks.updateZoneType(zone, zone_id);
        this.planActions.props.parentApi.callbacks.updateAnalysis(route_param);

        /*this.setState({
            actions: actionsScenario,
            coeffs: params,
            coeffsAdvanced: advanced,
        });*/
    }

    /**
     * Display a customized total tooltip text.
     * @param {array} infos the data to display
     * @returns the string to display in the tooltip
     */
    tooltipTotal(infos, impacts_configuration) {
        const decimal = impacts_configuration
            .filter((x) => x.type === "impact-prod-conso")
            .map((x) => x.decimal)[0];

        let tot = "";
        if (infos[1]) {
            tot =
                "Proportion de la production d'EnR par rapport à la consommation d'énergie : " +
                (
                    (parseFloat(infos[0].raw.y, 10) * 100) /
                    parseFloat(infos[1].raw.y, 10)
                ).toFixed(decimal) +
                " %";
        }
        return tot;
    }

    /**
     * Toggles the PCAET integration, either from ADEME or TerriSTORY.
     * Reinitializes also target trajectories.
     */
    activatePCAETintegration() {
        if (this.state.pcaetAdeme) {
            this.setState({
                resetTrajectoiresCibles: true,
                pcaetAdeme: false,
            });
            this.resetTrajectoiresCibles = true;
        } else {
            this.setState({
                resetTrajectoiresCibles: true,
                pcaetAdeme: true,
                retourEtatDefaut: false,
            });
            this.resetTrajectoiresCibles = true;
        }
    }

    avertirQuandRetourALaCarte() {
        let r = window.confirm(
            "Si vous retournez à la carte, les modifications apportées à la stratégie seront perdues. Souhaitez-vous continuer ?"
        );
        if (r === true) {
            this.props.history.push("/");
        }
    }

    render() {
        let titre_graph_ges = this.props.parentApi.data.settings["titre_graph_ges"];
        let titre_graph_ges_complet = titre_graph_ges
            ? titre_graph_ges.charAt(0).toUpperCase() + titre_graph_ges.substring(1)
            : "";
        let impacts_configuration = this.props.parentApi.data.settings["impacts"];
        const saveTrajectoiresCibles = (trajectoires) => {
            // Save target trajectory parameters (by type: conso, prod, emission)
            if (trajectoires !== false) {
                this.trajectoires[Object.keys(trajectoires)[0]] =
                    trajectoires[Object.keys(trajectoires)[0]];
                this.setState({ savedTrajectoiresCibles: true });
            } else {
                this.setState({ savedTrajectoiresCibles: trajectoires });
            }
        };

        let zoneName = this.props.parentApi.controller.zonesManager?.getZoneName(
            this.props.parentApi.data.currentZone,
            this.props.parentApi.data.zone
        );

        // change the title of the tooltip to current year
        let formatTitleToolip = function (data) {
            return new Date(data[0].label).getFullYear();
        };

        let addTotalToTooltip = function (items, unit, type) {
            // compute total
            let total = 0;
            items.forEach((item) => {
                if (item.dataset.isRefTraj) return;
                total += parseFloat(item.raw.y, 10);
            });
            // get decimal config
            const decimal = impacts_configuration
                .filter((x) => x.type === type)
                .map((x) => x.decimal)[0];
            return "Total : " + parseFloat(total).toFixed(decimal) + " " + unit;
        };

        const trajectoiresReference = JSON.parse(
            JSON.stringify(this.state.referencesTrajectoiresCiblesReference)
        );
        let initialTrajectories = JSON.parse(
            JSON.stringify(this.state.referencesTrajectoiresCibles)
        );

        for (let [trajectoryName, trajectoryValues] of Object.entries(
            initialTrajectories
        )) {
            const referenceValue = trajectoryValues["ref"];
            delete trajectoryValues["ref"];

            if (!this.state.pcaetAdeme) {
                // Hide PCAET: remove all objectives
                trajectoryValues = {};
                initialTrajectories[trajectoryName] = trajectoryValues;
                // Also remove reference trajectory which name contains "PCAET"
                let i = trajectoiresReference[trajectoryName].findIndex((traj) =>
                    traj.titre.includes("PCAET")
                );
                trajectoiresReference[trajectoryName].splice(i, 1);
            }

            // Set the first value of the trajectories using the reference value
            let startYear = this.state.anneeConsoGes;
            if (trajectoryName === "energie_produite") {
                startYear = this.state.anneeProd;
            }

            trajectoryValues[startYear] = referenceValue;
        }

        // configurtions of target trajectory
        let labelBoutonTrajectoireCibleEnergieClimat =
            "Ajouter des trajectoires énergie-climat";
        let labelBoutonTrajectoireCibleQualiteAir =
            "Ajouter les trajectoires qualité de l'air";

        // Initialize data
        let trajectoriesHistories = {};
        let trajectoriesHistoryTypes = [
            "emission_ges",
            "energie_economisee",
            "energie_produite",
            "covnm",
            "nh3",
            "nox",
            "pm10",
            "pm25",
            "sox",
        ];
        trajectoriesHistoryTypes.forEach((parameter) => {
            if (this.props.parentApi.data.settings.enable_history_actions_plan) {
                trajectoriesHistories[parameter] = { annees: [], valeurs: [] };
            } else {
                trajectoriesHistories[parameter] = false;
            }
        });

        // parse input data for history
        if (
            this.props.parentApi.data.settings.enable_history_actions_plan &&
            this.state.referencesHistoryTrajectories
        ) {
            // Initialization to reference value
            trajectoriesHistories = this.state.referencesHistoryTrajectories;
        }

        // Overwrite these values if we have already modified or saved them in an action plan
        let uiTrajectoireCible = [];

        if (this.state.loadedTrajectory) {
            initialTrajectories = this.state.loadedTrajectory;
        }

        let shouldResetInitialTajectory = false;
        if (
            this.resetTrajectoiresCibles ||
            (this.state.retourEtatDefaut && this.state.pcaetAdeme)
        ) {
            this.resetTrajectoiresCibles = false;
            if (this.state.retourEtatDefaut) {
                this.setState.retourEtatDefaut = false;
            }
            // Force PlanActionTrajectoiresCibles to reset its data without updating the initial trajectory
            shouldResetInitialTajectory = true;
        }

        if (
            (this.state.showTrajectoireCibleEnergieClimat ||
                this.state.showTrajectoireCibleQualiteAir) &&
            this.state.affichagePcaetAdeme
        ) {
            uiTrajectoireCible.push(
                <div id="pcaet-ademe" key="pcaet-ademe">
                    <label style={{ marginRight: 5 + "px" }}>Objectifs PCAET</label>
                    <label className="switch">
                        <input
                            type="checkbox"
                            defaultChecked={this.state.pcaetAdeme}
                            onClick={() => this.activatePCAETintegration()}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            );
        }

        if (this.state.showTrajectoireCibleEnergieClimat) {
            labelBoutonTrajectoireCibleEnergieClimat =
                "Masquer les trajectoires énergie-climat";
            uiTrajectoireCible.push(
                <div key="energie-climat">
                    {initialTrajectories["energie_economisee"] && (
                        <PlanActionsTrajectoiresCibles
                            parentApi={this.props.parentApi}
                            id="energie_economisee"
                            index={0}
                            title="Energie consommée"
                            unite="(GWh)"
                            historyData={trajectoriesHistories["energie_economisee"]}
                            data={initialTrajectories["energie_economisee"]}
                            dataReference={trajectoiresReference["energie_economisee"]}
                            saveTrajectoiresCibles={saveTrajectoiresCibles}
                            trajectoirePcaetAdeme={this.state.pcaetAdeme}
                            shouldResetData={shouldResetInitialTajectory}
                        />
                    )}
                    {initialTrajectories["energie_produite"] && (
                        <PlanActionsTrajectoiresCibles
                            parentApi={this.props.parentApi}
                            id="energie_produite"
                            index={0}
                            title="EnR produite"
                            unite="(GWh)"
                            historyData={trajectoriesHistories["energie_produite"]}
                            data={initialTrajectories["energie_produite"]}
                            dataReference={trajectoiresReference["energie_produite"]}
                            saveTrajectoiresCibles={saveTrajectoiresCibles}
                            options="doublemax"
                            trajectoirePcaetAdeme={this.state.pcaetAdeme}
                            shouldResetData={shouldResetInitialTajectory}
                        />
                    )}
                    {initialTrajectories["emission_ges"] && (
                        <PlanActionsTrajectoiresCibles
                            parentApi={this.props.parentApi}
                            id="emission_ges"
                            index={0}
                            title={titre_graph_ges_complet}
                            unite="(kteqCO2)"
                            historyData={trajectoriesHistories["emission_ges"]}
                            data={initialTrajectories["emission_ges"]}
                            dataReference={trajectoiresReference["emission_ges"]}
                            saveTrajectoiresCibles={saveTrajectoiresCibles}
                            trajectoirePcaetAdeme={this.state.pcaetAdeme}
                            shouldResetData={shouldResetInitialTajectory}
                        />
                    )}
                </div>
            );
        }

        if (this.state.showTrajectoireCibleQualiteAir) {
            labelBoutonTrajectoireCibleQualiteAir =
                "Masquer les trajectoires qualité de l'air";
            uiTrajectoireCible.push(
                <div key="qualite-air">
                    {initialTrajectories["nox"] && (
                        <PlanActionsTrajectoiresCibles
                            parentApi={this.props.parentApi}
                            id="nox"
                            index={0}
                            title="NOx"
                            unite="(en tonnes)"
                            historyData={trajectoriesHistories["nox"]}
                            data={initialTrajectories["nox"]}
                            dataReference={trajectoiresReference["nox"]}
                            saveTrajectoiresCibles={saveTrajectoiresCibles}
                            trajectoirePcaetAdeme={this.state.pcaetAdeme}
                            shouldResetData={shouldResetInitialTajectory}
                        />
                    )}
                    {initialTrajectories["pm10"] && (
                        <PlanActionsTrajectoiresCibles
                            parentApi={this.props.parentApi}
                            id="pm10"
                            index={0}
                            title="PM10"
                            unite="(en tonnes)"
                            historyData={trajectoriesHistories["pm10"]}
                            data={initialTrajectories["pm10"]}
                            dataReference={trajectoiresReference["pm10"]}
                            saveTrajectoiresCibles={saveTrajectoiresCibles}
                            trajectoirePcaetAdeme={this.state.pcaetAdeme}
                            shouldResetData={shouldResetInitialTajectory}
                        />
                    )}
                    {initialTrajectories["pm25"] && (
                        <PlanActionsTrajectoiresCibles
                            parentApi={this.props.parentApi}
                            id="pm25"
                            index={0}
                            title="PM2.5"
                            unite="(en tonnes)"
                            historyData={trajectoriesHistories["pm25"]}
                            data={initialTrajectories["pm25"]}
                            dataReference={trajectoiresReference["pm25"]}
                            saveTrajectoiresCibles={saveTrajectoiresCibles}
                            trajectoirePcaetAdeme={this.state.pcaetAdeme}
                            shouldResetData={shouldResetInitialTajectory}
                        />
                    )}
                    {initialTrajectories["covnm"] && (
                        <PlanActionsTrajectoiresCibles
                            parentApi={this.props.parentApi}
                            id="covnm"
                            index={0}
                            title="COVNM"
                            unite="(en tonnes)"
                            historyData={trajectoriesHistories["covnm"]}
                            data={initialTrajectories["covnm"]}
                            dataReference={trajectoiresReference["covnm"]}
                            saveTrajectoiresCibles={saveTrajectoiresCibles}
                            trajectoirePcaetAdeme={this.state.pcaetAdeme}
                            shouldResetData={shouldResetInitialTajectory}
                        />
                    )}
                    {initialTrajectories["sox"] && (
                        <PlanActionsTrajectoiresCibles
                            parentApi={this.props.parentApi}
                            id="sox"
                            index={0}
                            title="SO2"
                            unite="(en tonnes)"
                            historyData={trajectoriesHistories["sox"]}
                            data={initialTrajectories["sox"]}
                            dataReference={trajectoiresReference["sox"]}
                            saveTrajectoiresCibles={saveTrajectoiresCibles}
                            trajectoirePcaetAdeme={this.state.pcaetAdeme}
                            shouldResetData={shouldResetInitialTajectory}
                        />
                    )}
                    {initialTrajectories["nh3"] && (
                        <PlanActionsTrajectoiresCibles
                            parentApi={this.props.parentApi}
                            id="nh3"
                            index={0}
                            title="NH3"
                            unite="(en tonnes)"
                            historyData={trajectoriesHistories["nh3"]}
                            data={initialTrajectories["nh3"]}
                            dataReference={trajectoiresReference["nh3"]}
                            saveTrajectoiresCibles={saveTrajectoiresCibles}
                            trajectoirePcaetAdeme={this.state.pcaetAdeme}
                            shouldResetData={shouldResetInitialTajectory}
                        />
                    )}
                </div>
            );
        }

        uiTrajectoireCible = <div id="all-trajectoire">{uiTrajectoireCible}</div>;

        let source_suivi_traj = JSON.parse(
            this.props.parentApi.data.settings["source_suivi_traj"]
        );
        let source = source_suivi_traj["source_impacts"];
        let position_annotation = source_suivi_traj["position_annotation"];
        const defaultChartOptions = {
            maintainAspectRatio: false,
            responsive: false,
            plugins: {
                title: {
                    display: true,
                    padding: 10,
                },
                legend: {
                    reverse: true,
                    position: "top",
                    labels: {
                        boxWidth: 10,
                    },
                },
            },
            width: 400,
            height: 400,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: false,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            },
        };

        const defaultChartLineOptions = {
            responsive: false,
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                },
                legend: {
                    position: "bottom",
                },
                tooltip: {
                    reverse: true,
                    mode: "x",
                    intersect: false,
                    callbacks: {
                        title: formatTitleToolip,
                        label: function (tooltipItems, data) {
                            return tooltipItems.yLabel;
                        },
                    },
                },
                annotation: {
                    annotations: [
                        {
                            type: "line",
                            mode: "vertical",
                            scaleID: "x-axis-0",
                            value: new Date("2010-01-01"),
                            borderColor: "red",
                            label: {
                                xAdjust: position_annotation,
                                fontSize: 10,
                                fontColor: "#444",
                                fontStyle: "normal",
                                backgroundColor: "rgba(0,0,0,0.7)",
                                content: source,
                                enabled: true,
                            },
                        },
                        {
                            type: "line",
                            mode: "vertical",
                            scaleID: "x-axis-0",
                            value: new Date("2010-01-01"),
                            borderColor: "red",
                            label: {
                                xAdjust: 50,
                                fontSize: 10,
                                fontColor: "#444",
                                fontStyle: "normal",
                                backgroundColor: "rgba(0,0,0,0.7)",
                                content: "Projection",
                                enabled: true,
                            },
                        },
                    ],
                },
            },
            spanGaps: false,
            scales: {
                "x-axis-0": {
                    type: "time",
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Années",
                    },
                    time: {
                        unit: "year",
                    },
                    ticks: {
                        autoSkip: false,
                        minRotation: 45,
                        source: "data",
                    },
                },
                "y-axis-main": {
                    type: "linear",
                    stacked: true,
                    display: true,
                    position: "left",
                    scaleLabel: {
                        display: true,
                        labelString: "Valeurs",
                    },
                    ticks: {
                        beginAtZero: true,
                    },
                },
                "y-axis-trajectoire": {
                    type: "linear",
                    display: false,
                    position: "right",
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        beginAtZero: true,
                    },
                },
            },
            hover: {
                mode: "single",
            },
            width: 900,
            height: 450,
        };

        const ticksCallbackConsoProd = (value) => {
            return (
                value +
                (this.state.estimatedYears["prod_enr_ratio"] &&
                this.state.estimatedYears["prod_enr_ratio"].includes(+value)
                    ? " (e)"
                    : "")
            );
        };

        const formatUnitInPopup = function (data, unit, type) {
            const decimal = impacts_configuration
                .filter((x) => x.type === type)
                .map((x) => x.decimal)[0];
            return (
                data.dataset.label +
                " : " +
                parseFloat(data.raw.y).toFixed(decimal) +
                " " +
                unit
            );
        };

        const lineConsoProdDataOptions = {
            responsive: false,
            maintainAspectRatio: false,

            plugins: {
                title: {
                    display: true,
                    text: "Production EnR et consommation d'énergie (GWh)",
                },
                legend: {
                    position: "bottom",
                },
                tooltip: {
                    mode: "x",
                    intersect: false,
                    callbacks: {
                        title: (infos) =>
                            this.tooltipTotal(infos, impacts_configuration),
                        label: (data) =>
                            formatUnitInPopup(data, "GWh", "impact-prod-conso"),
                    },
                },
                annotation: {
                    annotations: [
                        {
                            type: "line",
                            mode: "vertical",
                            scaleID: "x",
                            borderColor: "black",
                            label: {
                                xAdjust: position_annotation,
                                fontSize: 10,
                                fontColor: "#444",
                                fontStyle: "normal",
                                backgroundColor: "rgba(0,0,0,0.7)",
                                content: source,
                                enabled: true,
                            },
                        },
                        {
                            type: "line",
                            mode: "vertical",
                            scaleID: "x",
                            borderColor: "black",
                            label: {
                                xAdjust: 50,
                                fontSize: 10,
                                fontColor: "#444",
                                fontStyle: "normal",
                                backgroundColor: "rgba(0,0,0,0.7)",
                                content: "Projection",
                                enabled: true,
                            },
                        },
                    ],
                },
            },
            scales: {
                y: {
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Valeurs exprimées en GWh",
                    },
                    ticks: {
                        beginAtZero: true,
                    },
                },
                x: {
                    type: "time",
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Années",
                    },
                    ticks: {
                        autoSkip: false,
                        source: "data",
                        callback: ticksCallbackConsoProd,
                    },
                },
            },
            width: 900,
            height: 450,
        };
        // Copy settings
        const chartOptionsNbEmpl = JSON.parse(JSON.stringify(defaultChartOptions));
        chartOptionsNbEmpl.plugins.title.text = "Nombre d'emplois générés";

        // Copy settings
        const chartOptionsVAG = JSON.parse(JSON.stringify(defaultChartOptions));
        chartOptionsVAG.plugins.title.text =
            "Valeur ajoutée générée (en millions d'euros)";

        // Copy settings
        const chartOptionsMixEnergetique = JSON.parse(
            JSON.stringify(defaultChartOptions)
        );
        chartOptionsMixEnergetique.plugins.title.text = "";
        chartOptionsMixEnergetique.width = 800;
        chartOptionsMixEnergetique.plugins.tooltip = {
            callbacks: {
                label: function (tooltipItem, data) {
                    return tooltipItem.yLabel; // We only need to display value
                },
            },
        };

        // Copy settings
        const chartOptionsFactureEnergetique = JSON.parse(
            JSON.stringify(defaultChartOptions)
        );
        chartOptionsFactureEnergetique.plugins.title.text =
            "Facture énergétique (en milliers d'euros)";

        // Copy settings
        const chartOptionsRetombeesFiscales = JSON.parse(
            JSON.stringify(defaultChartOptions)
        );
        chartOptionsRetombeesFiscales.plugins.title.text =
            "Retombées fiscales (en milliers d'euros)";
        chartOptionsRetombeesFiscales.scales.y.stacked = true;
        chartOptionsRetombeesFiscales.plugins.tooltip = {
            callbacks: {
                label: function (tooltipItem) {
                    const decimal = impacts_configuration
                        .filter((x) => x.type === "impact-tax")
                        .map((x) => x.decimal)[0];
                    let soloValue =
                        tooltipItem.dataset.label +
                        " : " +
                        tooltipItem.raw.toFixed(decimal);
                    let agregateValue = 0;

                    for (let ds in tooltipItem.parsed._stacks.y) {
                        agregateValue += tooltipItem.parsed._stacks.y[ds];
                    }
                    return soloValue + "   Total : " + agregateValue.toFixed(decimal);
                },
            },
        };

        // Copy settings
        const chartOptionsInvestissement = JSON.parse(
            JSON.stringify(defaultChartOptions)
        );
        chartOptionsInvestissement.plugins.title.text =
            "Investissement (en millions d'euros)";

        const handleActionChange = (e) => {
            // weird case not triggered by a click
            // Remove the case where the event is triggered by itself
            // when there was no click. And the coordinates are
            // at 0 / 0. we then do nothing
            if (e.nativeEvent.clientX === 0 && e.nativeEvent.clientY === 0) {
                return false;
            }

            // Update state with actions enabled
            let actions = [...this.state.actions];
            let listeActions = [];
            let annee_ref = this.state.anneeRefDataConso;
            for (let a of actions) {
                if (parseInt(e.target.name, 10) === a.id) {
                    a.enabled = e.target.checked;
                    if (a.enabled) {
                        listeActions.push(a.numero);
                    }
                    // Update the reference year if action 16: conversion of heating equipment in the residential sector has been checked
                    if (["16", "17"].includes(a.numero)) {
                        annee_ref = a.annee_ref;
                    }
                }
            }

            this.setState({
                anneeRefDataConso: annee_ref,
                actions: actions,
                listeDesActions: listeActions,
                disabledActions: [],
                warningDisabledActions: "",
                data: undefined,
            });
        };

        const handleCoeffChange = (e, isAutresParams = false) => {
            // Update state with actions coeff changed
            let coeffs = this.state.coeffs;
            if (isAutresParams) {
                // Update state with years coeff advanced params
                let currentId = e.target.id;
                let value = e.target.value;

                let coeffsAdvanced = [...this.state.coeffsAdvanced];
                let idParser = currentId.split("-");
                let numAction = idParser[0];
                let type = idParser[1];
                let subkey = idParser[2].split("#")[0].replaceAll("_", " ");
                let year = idParser[2].split("#")[1];

                for (const c of coeffsAdvanced) {
                    // type always equals to "others_years"
                    if (type in c.params_avances && c.action === numAction) {
                        Object.keys(c.params_avances.others_years).forEach((key) => {
                            if (key === subkey) {
                                let param = c.params_avances.others_years[key];
                                param.valeur_annee[year] = value; // update year value with the updated value
                            }
                        });
                    }
                }
                this.setState({
                    coeffsAdvanced: coeffsAdvanced,
                    data: undefined,
                });
            } else {
                for (let c of coeffs) {
                    let id = e.target.id.split("#")[0];
                    let year = e.target.id.split("#")[1];
                    if (id === c.nom) {
                        let value = e.target.value;
                        //c.valeur_annee[year] = parseFloat(value);
                        c.valeur_annee[year] = value;
                    }
                }
                this.setState({
                    coeffs: coeffs,
                    data: undefined,
                });
            }
        };

        const handleCoeffAdvancedChange = (currentId, value) => {
            // Update state with coeff advanced changed
            let coeffsAdvanced = this.state.coeffsAdvanced;
            let idParser = currentId.split("-");
            let numAction = idParser[0];
            let type = idParser[1];
            let id = undefined;
            let subkey = undefined;

            if (type === "economique") {
                id = idParser[2];
            }
            if (type === "autres") {
                // Look for subkey
                // when subdivisions, the keys are the name of the field
                // they thus have spaces that need to be kept
                subkey = idParser[2];
                id = idParser[3];
            }

            for (let c of coeffsAdvanced) {
                if (c.action === numAction) {
                    let pa = undefined;
                    if (type === "economique") {
                        pa = c.params_avances[type];
                        for (let p of pa) {
                            if (p.id === parseInt(id, 10)) {
                                p.part = trim(value);
                            }
                        }
                    } else {
                        // Others
                        pa = c.params_avances[type][subkey];

                        // search valeur index in pa.colums
                        let idxValeur = pa.columns.indexOf("valeur");
                        pa.data[id][idxValeur] = trim(value);
                    }
                }
            }
            this.setState({
                coeffsAdvanced: coeffsAdvanced,
                data: undefined,
            });
        };

        // Charts filters
        const handleCheckDirect = (e) => {
            this.setState({ directChecked: e.target.checked });
        };

        const handleCheckIndirect = (e) => {
            this.setState({ indirectChecked: e.target.checked });
        };

        // Build charts
        let filters = "";
        filters = (
            <div className="filters options actions centered-widget">
                <ul>
                    <li>
                        <label className="checkbox-container">
                            Emplois et valeur ajoutée directs
                            <input
                                type="checkbox"
                                key="checkDirect"
                                name="checkDirect"
                                id="checkDirect"
                                defaultChecked={this.state.directChecked}
                                onChange={handleCheckDirect}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </li>
                    <li>
                        <label className="checkbox-container">
                            Emplois et valeur ajoutée indirect
                            <input
                                type="checkbox"
                                key="checkIndirect"
                                name="checkIndirect"
                                id="checkIndirect"
                                defaultChecked={this.state.indirectChecked}
                                onChange={handleCheckIndirect}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </li>
                </ul>
            </div>
        );

        let chartNbEmplData = {};
        let chartVAGData = {};
        let chartDataMixEnergetiqueSurfacePanneaux = {};
        let chartDataMixEnergetiqueSurfaceSol = {};
        let chartDataMixEnergetiqueLogementsChauffes = {};
        let chartNbEmpl = "";
        let chartVAG = "";
        let chartMixEnergetique = "";
        let chartEmissionGesSecteur = "";
        let chartEmissionGesType = "";

        let chartAirPollutants = {
            secteur: {},
        };

        let chartEnergieEcoSecteur = "";
        let chartEnergieEcoType = "";
        let chartEnergieProduite = "";
        let chartFactureEnergetique = "";
        let chartRetombeesFiscales = "";
        let chartInvestissement = "";
        let lineConsoVsEnR = "";
        if (this.state.data) {
            let labels = [];

            let colorEmploiTerritoire = configData.planActionsColorEmploiTerritoire;
            let colorEmploiRegion = configData.planActionsColorEmploiRegion;
            let colorVAGTerritoire = configData.planActionsColorVAGTerritoire;
            let colorVAGRegion = configData.planActionsColorVAGRegion;

            let tabColorEmploiTerritoire = [];
            let tabColorEmploiRegion = [];
            let tabColorVAGTerritoire = [];
            let tabColorVAGRegion = [];
            let zone = this.props.parentApi.data.zone.zone;

            let dataNbEmplTerritoire = [];
            let dataNbEmplRegion = [];
            let dataVAGTerritoire = [];
            let dataVAGRegion = [];

            let dataMixSurfacePanneaux = [];
            let dataMixSurfaceSol = [];
            let dataMixLogementsChauffes = [];

            let isDataMix = false;

            let dataFactureEnergetique = [];
            let dataRetombeesFiscales = {
                commune: [],
                departement: [],
                epci: [],
                region: [],
            };

            let dataInvestissement = [];

            // For each year
            for (let year of this.state.years) {
                let data = this.state.data["impact_emplois"][year];
                if (!data) {
                    continue;
                }
                labels.push(year);

                // Emploi / val ajoutée
                let val = 0;
                if (this.state.directChecked && data.direct && data.direct[zone])
                    val +=
                        parseFloat(data.direct[zone].ponctuel.nb_emploi_total) +
                        parseFloat(data.direct[zone].perenne.nb_emploi_total);
                if (this.state.indirectChecked && data.indirect && data.indirect[zone])
                    val +=
                        parseFloat(data.indirect[zone].ponctuel.nb_emploi_total) +
                        parseFloat(data.indirect[zone].perenne.nb_emploi_total);
                dataNbEmplTerritoire.push(val.toFixed(2));

                val = 0;
                if (this.state.directChecked && data.direct && data.direct.region)
                    val +=
                        parseFloat(data.direct.region.ponctuel.nb_emploi_total) +
                        parseFloat(data.direct.region.perenne.nb_emploi_total);
                if (this.state.indirectChecked && data.indirect && data.indirect.region)
                    val +=
                        parseFloat(data.indirect.region.ponctuel.nb_emploi_total) +
                        parseFloat(data.indirect.region.perenne.nb_emploi_total);
                dataNbEmplRegion.push(val.toFixed(2));

                val = 0;
                if (this.state.directChecked && data.direct && data.direct[zone])
                    val +=
                        parseFloat(data.direct[zone].ponctuel.va_totale) +
                        parseFloat(data.direct[zone].perenne.va_totale);
                if (this.state.indirectChecked && data.indirect && data.indirect[zone])
                    val +=
                        parseFloat(data.indirect[zone].ponctuel.va_totale) +
                        parseFloat(data.indirect[zone].perenne.va_totale);
                dataVAGTerritoire.push(val.toFixed(2));

                val = 0;
                if (this.state.directChecked && data.direct && data.direct.region)
                    val +=
                        parseFloat(data.direct.region.ponctuel.va_totale) +
                        parseFloat(data.direct.region.perenne.va_totale);
                if (this.state.indirectChecked && data.indirect && data.indirect.region)
                    val +=
                        parseFloat(data.indirect.region.ponctuel.va_totale) +
                        parseFloat(data.indirect.region.perenne.va_totale);
                dataVAGRegion.push(val.toFixed(2));

                // Investment (special case)
                dataInvestissement.push(parseFloat(data.investissement) / 1000.0);

                // Energy mix (special case)
                if (data.mix) {
                    isDataMix = true;
                    val = 0;
                    val += parseFloat(data.mix.surface_panneaux);
                    dataMixSurfacePanneaux.push(val.toFixed(3));

                    val = 0;
                    val += parseFloat(data.mix.surface_sol);
                    dataMixSurfaceSol.push(val.toFixed(3));

                    val = 0;
                    val += parseFloat(data.mix.logements_chauffes);
                    dataMixLogementsChauffes.push(val.toFixed(3));
                }

                tabColorEmploiTerritoire.push(colorEmploiTerritoire);
                tabColorEmploiRegion.push(colorEmploiRegion);
                tabColorVAGTerritoire.push(colorVAGTerritoire);
                tabColorVAGRegion.push(colorVAGRegion);
            }

            // we need to push 2 objects per year (territoire + region) / year
            let dataSetsNbEmpl = [];
            if (zoneName && dataNbEmplTerritoire.length > 0) {
                dataSetsNbEmpl.push({
                    label: zoneName,
                    backgroundColor: tabColorEmploiTerritoire,
                    data: dataNbEmplTerritoire,
                });
            }
            if (dataNbEmplRegion.length > 0) {
                dataSetsNbEmpl.push({
                    label: "Région",
                    backgroundColor: tabColorEmploiRegion,
                    data: dataNbEmplRegion,
                });
            }

            let dataSetsVAG = [];
            if (zoneName && dataVAGTerritoire.length > 0) {
                dataSetsVAG.push({
                    label: zoneName,
                    backgroundColor: tabColorVAGTerritoire,
                    data: dataVAGTerritoire,
                });
            }
            if (dataVAGRegion.length > 0) {
                dataSetsVAG.push({
                    label: "Région",
                    backgroundColor: tabColorVAGRegion,
                    data: dataVAGRegion,
                });
            }
            if (
                this.props.parentApi.data.zone.zone === "region" &&
                !this.props.parentApi.data.settings["is_national"]
            )
                zoneName = "Région";

            // Energy bill
            let labelsFactureEnergetique = [];
            for (let year in this.state.data["facture_energetique"]) {
                // Conversion of values to negative values for a better understanding of the table in the case of a drop
                dataFactureEnergetique.push(
                    parseFloat(-this.state.data["facture_energetique"][year])
                );
                labelsFactureEnergetique.push(year);
            }
            let dataSetsFactureEnergetique = [];
            if (dataFactureEnergetique.length > 0) {
                dataSetsFactureEnergetique.push({
                    label: zoneName,
                    backgroundColor: configData.planActionsFactureEnergetique,
                    data: dataFactureEnergetique,
                });
            }
            let chartDataFactureEnergetique = {
                labels: labelsFactureEnergetique,
                datasets: dataSetsFactureEnergetique,
            };

            // Energy Mix
            let dataSetsMixEnergetiqueSurfacePanneaux = [];
            let dataSetsMixEnergetiqueSurfaceSol = [];
            let dataSetsMixEnergetiqueLogementsChauffes = [];
            if (isDataMix) {
                dataSetsMixEnergetiqueSurfacePanneaux.push({
                    label: "Surface nécessaire en panneaux solaires pour obtenir la part souhaitée dans le mix énergétique (m2)",
                    backgroundColor: configData.planActionsColorMix,
                    data: dataMixSurfacePanneaux,
                });
                dataSetsMixEnergetiqueSurfaceSol.push({
                    label: "Surface au sol nécessaire pour cette surface de panneaux (ha)",
                    backgroundColor: configData.planActionsColorMix,
                    data: dataMixSurfaceSol,
                });
                dataSetsMixEnergetiqueLogementsChauffes.push({
                    label: "Nombre d’équivalent logements chauffés (serait)",
                    backgroundColor: configData.planActionsColorMix,
                    data: dataMixLogementsChauffes,
                });
            }

            // Factorization for retrieving data in JSON
            const getDataType = (type, categorie) => {
                let dataSets = [];
                let min = 99999999;
                let max = 0;
                let cumulAnnee = new Map();
                let dataTypes = this.state.data[type][categorie];
                if (dataTypes === "Confidentiel") {
                    return "Confidentiel";
                }
                for (let ds of dataTypes) {
                    // Check if we have a color or the code for confidentiality in ds.couleur
                    let bgColor = undefined;
                    let borderColor = undefined;
                    if (ds.confidentiel === "oui") {
                        // confidential
                        bgColor = pattern.draw("diagonal-right-left", "#FF0000");
                        borderColor = chroma("#FF0000").darken().alpha(1).css();
                    } else {
                        bgColor = chroma(ds.couleur).alpha(1).css();
                        borderColor = chroma(ds.couleur).darken().alpha(1).css();
                    }
                    let dataset = {
                        backgroundColor: bgColor,
                        borderColor: borderColor,
                        label: ds === "Confidentiel" ? ds : ds.nom,
                        yAxisID: "y-axis-main",
                        order: 1,
                        fill: { target: "origin", above: bgColor },
                    };
                    let datasetData = [];
                    for (let d of ds.data) {
                        datasetData.push({
                            x: new Date(d.annee + "-01-01"),
                            y: d.valeur,
                        });
                        let cumul = d.valeur;
                        if (cumulAnnee.has(d.annee)) {
                            cumul = cumulAnnee.get(d.annee) + d.valeur;
                        }
                        cumulAnnee.set(d.annee, cumul);
                    }
                    dataset.data = datasetData;
                    dataSets.push(dataset);
                }

                // Get min/max
                cumulAnnee.forEach(function (value, key, map) {
                    if (value > max) {
                        max = value;
                    }
                    if (value < min) {
                        min = value;
                    }
                });

                // Add supra-territorial objectives if they exist in the impact result
                if (this.state.referencesTrajectoiresCiblesReference) {
                    if (this.state.referencesTrajectoiresCiblesReference[type]) {
                        let trajcReference = getDataReferenceTraj(
                            this.state.referencesTrajectoiresCiblesReference[type],
                            false,
                            min,
                            max
                        );
                        for (let item of trajcReference.datasets) {
                            dataSets.unshift(item);
                        }
                        min = trajcReference.min;
                        max = trajcReference.max;
                    }
                }

                // Add target trajectory if user enabled it
                if (
                    this.state.showTrajectoireCibleEnergieClimat ||
                    this.state.showTrajectoireCibleQualiteAir ||
                    (this.state.showTrajectoireCibleEnergieClimat &&
                        this.state.showTrajectoireCibleQualiteAir)
                ) {
                    if (this.trajectoires[type]) {
                        let datasetTrajectoireCible = {
                            backgroundColor: "rgb(255, 255, 255, 0)", // add transparency to avoid the option fill: false
                            borderColor: "#444444",
                            label: "Trajectoire cible",
                            yAxisID: "y-axis-trajectoire",
                            pointBorderColor: "#444444",
                            borderDash: [5, 5],
                            borderWidth: 2,
                            pointRadius: 1,
                            order: 1,
                        };

                        let datasetDataTrajectoireCible = [];
                        // Convert JSON => array if needed (if data comes from a loaded scenario)
                        let trajectoiresCiblesData = {};
                        trajectoiresCiblesData[type] = {};
                        trajectoiresCiblesData[type].annees = [];
                        trajectoiresCiblesData[type].valeurs = [];

                        if (this.trajectoires[type].annees_valeurs) {
                            for (let annee in this.trajectoires[type].annees_valeurs) {
                                trajectoiresCiblesData[type].annees.push(annee);
                                trajectoiresCiblesData[type].valeurs.push(
                                    this.trajectoires[type].annees_valeurs[annee]
                                );
                            }
                        } else {
                            trajectoiresCiblesData[type].annees =
                                this.trajectoires[type].annees;
                            trajectoiresCiblesData[type].valeurs =
                                this.trajectoires[type].valeurs;
                        }

                        for (let a in trajectoiresCiblesData[type].annees) {
                            let annee = trajectoiresCiblesData[type].annees[a];
                            var valeur = parseFloat(
                                trajectoiresCiblesData[type].valeurs[a]
                            );
                            datasetDataTrajectoireCible.push({
                                x: new Date(annee + "-01-01"),
                                y: valeur,
                            });
                            if (valeur > max) {
                                max = valeur;
                            }
                            if (valeur < min) {
                                min = valeur;
                            }
                        }
                        datasetTrajectoireCible.data = datasetDataTrajectoireCible;
                        datasetTrajectoireCible.isRefTraj = true;
                        dataSets.unshift(datasetTrajectoireCible);
                    }
                }
                // set min to 0 if necessary
                if (min > 0) {
                    min = 0;
                }

                return { datasets: dataSets, min: min, max: max };
            };
            /**
             * This function calculates the values to display in the impact results for each year (2019 => 2050)
             * @param {object} trajectoiresCiblesReference : dataset that contains all the objectives stored in the DB: data and metadata
             * @param {boolean} chartline : boolean to indicate the type of the chart
             * @returns a datasets that contains all the supra-territorial objectives stored in the DB for each type of graph passed as parameters
             *  { "datasets": datasets ,"min":min ,"max":max }
             */
            const getDataReferenceTraj = (
                trajectoiresCiblesReference,
                chartline,
                min,
                max
            ) => {
                let dataSetsTrajectoireCibleParValeur = [];
                for (let i in trajectoiresCiblesReference) {
                    let a = trajectoiresCiblesReference[i];
                    let dataSetRef = [];
                    let anneesObjsDejaIntegres = [];
                    let valeurRef = parseFloat(a.valeur_reference);

                    let yearsToDisplay = a.valeurs_annees;
                    if (!a.valeurs_annees.some((y) => y.annee === a.annee_reference)) {
                        yearsToDisplay = [
                            { annee: a.annee_reference, valeur: 0.0 },
                            ...a.valeurs_annees,
                        ];
                    }
                    for (let c in yearsToDisplay) {
                        let annee_t = yearsToDisplay[c].annee;
                        // total value of consumption / production for each year
                        let valeur = yearsToDisplay[c].valeur / 100; // values are expressed as a percentage in DB
                        // calculate the reduction from the total value of each year
                        // multiply the absolute value of the reduction entered by the user by the total value of each year ( 2019 => 2050)
                        let valeurGraph = valeur * valeurRef + valeurRef;
                        if (
                            anneesObjsDejaIntegres.indexOf(
                                annee_t + valeurGraph + a.titre
                            ) === -1
                        ) {
                            dataSetRef.push({
                                x: new Date(annee_t + "-01-01"),
                                y: valeurGraph,
                            });
                        }
                        anneesObjsDejaIntegres.push(annee_t + valeurGraph + a.titre);
                        if (min !== undefined && max !== undefined) {
                            if (valeurGraph > max) {
                                max = valeurGraph;
                            }
                            if (valeurGraph < min) {
                                min = valeurGraph;
                            }
                        }
                    }
                    let ds = {
                        backgroundColor: "rgb(255, 255, 255, 0)", // add transparency to avoid the option fill: false
                        borderColor: a.couleur,
                        label: a.titre + " (" + a.annee_reference + ")",
                        pointBorderColor: a.couleur,
                        borderDash: [5, 5],
                        borderWidth: 2,
                        pointRadius: 1,
                        order: 2,
                    };

                    if (!chartline) {
                        ds["yAxisID"] = "y-axis-trajectoire";
                        ds["order"] = 1;
                    }
                    ds.data = dataSetRef;
                    ds.isRefTraj = true;
                    let affectation = JSON.parse(a.affectation);
                    let affectationExiste =
                        affectation[this.props.parentApi.data.zone.zone] ||
                        affectation[this.props.parentApi.data.zone.zone + "s"] === "";
                    let correspondanceAffectationAvecTerritoireCourant =
                        affectation[this.props.parentApi.data.zone.zone + "s"] === "" ||
                        affectation[this.props.parentApi.data.zone.zone] ===
                            this.props.parentApi.data.currentZone;
                    if (
                        affectationExiste &&
                        correspondanceAffectationAvecTerritoireCourant
                    ) {
                        dataSetsTrajectoireCibleParValeur.push(ds);
                    }
                }
                return {
                    datasets: dataSetsTrajectoireCibleParValeur,
                    min: min,
                    max: max,
                };
            };

            const dataForConsoVsProd = (data, liste_annees, a) => {
                /**
                This function returns the total of values declined into types.
                Thus, they are formatted in order to be used in the graph 
                showing the curves of final renewable energy production and final energy consumption
                within impact results.
                * @param  {object} data : Table of data by type, then by year / value
                * @param  {object} liste_annees: List of years for which we have data for consumption and production (conso/prod)
                */
                if (typeof data !== "undefined") {
                    let dataSet = [];
                    for (let annee in liste_annees) {
                        // For each year
                        let v = 0,
                            anyImpact = false;
                        for (let i in data.energie) {
                            // browse each modality (arbitrarily by type of energy because common to production and consumption)
                            for (let k in data.energie[i].data) {
                                // browse each line of data {annee : valeur}
                                if (
                                    data.energie[i].data[k].annee ===
                                    liste_annees[annee]
                                ) {
                                    // If the year matches then
                                    v += data.energie[i].data[k].valeur; //  add all the types to calculate the total
                                    anyImpact = true;
                                }
                            }
                        }
                        // if we have had no impact, no need to add dot to zero (misleading)
                        if (!anyImpact) {
                            continue;
                        }
                        dataSet.push({
                            x: new Date(parseInt(liste_annees[annee], 10) + "-01-01"),
                            y: parseFloat(v).toFixed(2),
                        });
                    }
                    return { dataset: dataSet, labels: liste_annees }; // transmit the data in the correct format
                }
            };

            const trajectoiresProdVsConso = (type_impact_energie) => {
                /**
                Returns the target trajectory values in the correct format 
                in order to be added to the Prod EnR and Final Conso graph 
                displayed in the impact results.
                * @param  {string} type_impact_energie : type of impact : "energie_produite" or "energie_economisee"
                */
                let datasetTrajectoireCible = {
                    // Target trajectory Parameters
                    backgroundColor: "rgb(255, 255, 255, 0)", // add transparency to avoid the option fill: false
                    borderDash: [5, 5],
                    borderWidth: 2,
                    pointRadius: 1,
                    order: 2,
                };
                if (this.state.showTrajectoireCibleEnergieClimat) {
                    // If target trajectories have been enabled
                    if (this.trajectoires[type_impact_energie]) {
                        // If the graph corresponding to the type_impact_energie parameter is activated,
                        let trajectoire = [];
                        if (this.trajectoires[type_impact_energie].annees_valeurs) {
                            // If the target trajectories are only touched before launching the calculation
                            for (let annee in this.trajectoires[type_impact_energie]
                                .annees_valeurs) {
                                trajectoire.push({
                                    x: new Date(parseInt(annee, 10) + "-01-01"),
                                    y: this.trajectoires[type_impact_energie]
                                        .annees_valeurs[annee],
                                }); // put the data in the format {x: annee, y: valeur}
                            }
                        } else {
                            // Otherwise, the structure changes and the treatment is then slightly different.
                            for (let i in this.trajectoires[type_impact_energie]
                                .valeurs) {
                                trajectoire.push({
                                    x: new Date(
                                        parseInt(
                                            this.trajectoires[type_impact_energie]
                                                .annees[i],
                                            10
                                        ) + "-01-01"
                                    ),
                                    y: this.trajectoires[type_impact_energie].valeurs[
                                        i
                                    ],
                                }); // Same as above ({x: annee, y: valeur})
                            }
                        }
                        datasetTrajectoireCible["data"] = trajectoire;
                    }
                    return datasetTrajectoireCible; // This dataset can be added to any graph
                }
            };

            const lineConsoProdData = (data) => {
                /**
                 * This function returns the final data to add to the Line component properties of React-chart-js.
                 * The component actually contains all the data of all the curves
                 * @param  {object} data : Data table (this.state.data)
                 */
                if (typeof data !== "undefined") {
                    let liste_annees = [];
                    for (let j in data.energie_produite.energie[0].data) {
                        liste_annees.push(
                            data.energie_produite.energie[0].data[j]["annee"]
                        ); // generate the list of data to enter as a parameter of dataForConsoVsProd
                    }

                    // Data for energy consumption curve
                    let dataSetConsommation =
                        this.state.data["energie_economisee"]["secteur"] !==
                        "Confidentiel"
                            ? dataForConsoVsProd(data.energie_economisee, liste_annees)
                            : {};

                    // Data for the renewable energy production curve
                    let dataSetProduction = dataForConsoVsProd(
                        data.energie_produite,
                        liste_annees
                    );
                    let lineConsoProdDataFinal = {
                        datasets: [
                            {
                                label: "Production EnR",
                                borderColor: "#5081bc",
                                backgroundColor: "rgba(0, 0, 0, 0)",
                                data: dataSetProduction.dataset,
                                fill: {
                                    target: "origin",
                                    above: "rgb(255, 255, 255, 0)",
                                },
                            },
                            {
                                label: "Consommation d'énergie finale",
                                borderColor: "#c0504d",
                                backgroundColor: "rgba(0, 0, 0, 0)",
                                data: dataSetConsommation.dataset,
                                fill: {
                                    target: "origin",
                                    above: "rgb(255, 255, 255, 0)",
                                },
                            },
                        ],
                    }; // add the data of the two curves in a variable in order to generate a graph.
                    if (this.state.showTrajectoireCibleEnergieClimat) {
                        // add the target trajectories
                        if (this.trajectoires["energie_produite"]) {
                            // If the produced energy target trajectory has been touched then,

                            let datasetTrajectoireCibleProd =
                                trajectoiresProdVsConso("energie_produite"); // retrieve data of the energies produced
                            datasetTrajectoireCibleProd["label"] =
                                "Trajectoire cible production EnR"; // Entry into the legend
                            datasetTrajectoireCibleProd["borderColor"] = "#5081bc"; // line color
                            datasetTrajectoireCibleProd["pointBorderColor"] = "#5081bc"; // Color of the dotted lines
                            lineConsoProdDataFinal.datasets.push(
                                datasetTrajectoireCibleProd
                            ); // Adding the curves to the final graph
                        }
                        if (this.trajectoires["energie_economisee"]) {
                            //Cf. the comments located just above
                            let datasetTrajectoireCibleConso =
                                trajectoiresProdVsConso("energie_economisee");
                            datasetTrajectoireCibleConso["label"] =
                                "Trajectoire cible consommation énergétique";
                            datasetTrajectoireCibleConso["borderColor"] = "#c0504d";
                            datasetTrajectoireCibleConso["pointBorderColor"] =
                                "#c0504d";
                            lineConsoProdDataFinal.datasets.push(
                                datasetTrajectoireCibleConso
                            );
                        }
                    }

                    // Add supra-territorial objectives if they exist in the impact result
                    if (this.state.referencesTrajectoiresCiblesReference) {
                        let objectifs = [];
                        if (
                            this.state.referencesTrajectoiresCiblesReference[
                                "energie_produite"
                            ]
                        ) {
                            objectifs.push(
                                getDataReferenceTraj(
                                    this.state.referencesTrajectoiresCiblesReference[
                                        "energie_produite"
                                    ],
                                    "energie_produite",
                                    true
                                ).datasets
                            );
                        }
                        if (
                            this.state.referencesTrajectoiresCiblesReference[
                                "energie_economisee"
                            ]
                        ) {
                            if (
                                this.state.data["energie_economisee"]["secteur"] !==
                                "Confidentiel"
                            ) {
                                objectifs.push(
                                    getDataReferenceTraj(
                                        this.state
                                            .referencesTrajectoiresCiblesReference[
                                            "energie_economisee"
                                        ],
                                        "energie_economisee",
                                        true
                                    ).datasets
                                );
                            }
                        }

                        /**
                         * objectives is a table that contains a set of sub-tables by type of energy: energy produced or saved
                         * each sub-table can contain one or more objectives
                         * objectifs = [[objectif1,objectifs2],[objectifs3,objectifs4]]
                         */
                        for (let item of objectifs) {
                            for (let x of item) {
                                lineConsoProdDataFinal.datasets.push(x);
                            }
                        }
                    }
                    return lineConsoProdDataFinal;
                }
            };

            let chartOptionsEnergieEconoSecteur = undefined;
            let dataForEnergieEcoSecteur = undefined;
            let dataSetsEnergieEcoSecteur = undefined;
            let chartOptionsEnergieEconoType = undefined;
            let dataForEnergieEcoType = undefined;
            let dataSetsEnergieEcoType = undefined;
            if (
                Object.keys(this.state.data["energie_economisee"]).length !== 0 &&
                this.state.data["energie_economisee"]["secteur"] !== "Confidentiel"
            ) {
                let maxDateEnergieEcono = new Date(
                    this.state.data["energie_economisee"]["max_annee"] + "-01-01"
                );
                // Energy saved by sector
                chartOptionsEnergieEconoSecteur = JSON.parse(
                    JSON.stringify(defaultChartLineOptions)
                );
                chartOptionsEnergieEconoSecteur.plugins.title.text =
                    "Impact sur la consommation d'énergie par secteur (GWh)";
                chartOptionsEnergieEconoSecteur.scales[
                    "y-axis-main"
                ].scaleLabel.labelString = "Consommation d'énergie (GWh)";
                chartOptionsEnergieEconoSecteur.plugins.tooltip.callbacks.title =
                    formatTitleToolip;
                chartOptionsEnergieEconoSecteur.plugins.tooltip.callbacks.beforeBody = (
                    items
                ) => addTotalToTooltip(items, "GWh", "impact-ener-sector");
                // retrieve the limit date between past data and estimates (future)
                chartOptionsEnergieEconoSecteur.plugins.annotation.annotations[0].value =
                    maxDateEnergieEcono;
                chartOptionsEnergieEconoSecteur.plugins.annotation.annotations[1].value =
                    maxDateEnergieEcono;

                chartOptionsEnergieEconoSecteur.plugins.tooltip.callbacks.label =
                    function (data) {
                        return formatUnitInPopup(data, "GWh", "impact-ener-sector");
                    };
                dataForEnergieEcoSecteur = getDataType("energie_economisee", "secteur");
                dataSetsEnergieEcoSecteur = dataForEnergieEcoSecteur.datasets;
                // force the min and max to the same value for the 2 axes (data + target trajectory)
                chartOptionsEnergieEconoSecteur.scales["y-axis-main"].min =
                    dataForEnergieEcoSecteur.min;
                chartOptionsEnergieEconoSecteur.scales["y-axis-main"].max =
                    (Math.round(dataForEnergieEcoSecteur.max) * 10) / 10;
                chartOptionsEnergieEconoSecteur.scales["y-axis-trajectoire"].min =
                    dataForEnergieEcoSecteur.min;
                chartOptionsEnergieEconoSecteur.scales["y-axis-trajectoire"].max =
                    (Math.round(dataForEnergieEcoSecteur.max) * 10) / 10;
                chartOptionsEnergieEconoSecteur.scales["x-axis-0"].ticks.callback = (
                    value
                ) =>
                    value +
                    (this.state.estimatedYears["conso_energetique"] &&
                    this.state.estimatedYears["conso_energetique"].includes(+value)
                        ? " (e)"
                        : "");

                // Energy saved by type of energy
                chartOptionsEnergieEconoType = JSON.parse(
                    JSON.stringify(defaultChartLineOptions)
                );
                chartOptionsEnergieEconoType.plugins.title.text =
                    "Impact sur la consommation d'énergie par énergie (GWh)";
                chartOptionsEnergieEconoType.scales[
                    "y-axis-main"
                ].scaleLabel.labelString = "Consommation d'énergie (GWh)";
                chartOptionsEnergieEconoType.plugins.tooltip.callbacks.title =
                    formatTitleToolip;
                chartOptionsEnergieEconoType.plugins.tooltip.callbacks.beforeBody = (
                    items
                ) => addTotalToTooltip(items, "GWh", "impact-ener-energy");
                // retrieve the limit date between past data and estimates (future)
                chartOptionsEnergieEconoType.plugins.annotation.annotations[0].value =
                    maxDateEnergieEcono;
                chartOptionsEnergieEconoType.plugins.annotation.annotations[1].value =
                    maxDateEnergieEcono;

                chartOptionsEnergieEconoType.plugins.tooltip.callbacks.label =
                    function (data) {
                        return formatUnitInPopup(data, "GWh", "impact-ener-energy");
                    };
                dataForEnergieEcoType = getDataType("energie_economisee", "energie");
                dataSetsEnergieEcoType = dataForEnergieEcoType.datasets;
                // force the min and max to the same value for the 2 axes (data + target trajectory)
                chartOptionsEnergieEconoType.scales["y-axis-main"].min =
                    dataForEnergieEcoType.min;
                chartOptionsEnergieEconoType.scales["y-axis-main"].max =
                    dataForEnergieEcoType.max;
                chartOptionsEnergieEconoType.scales["y-axis-trajectoire"].min =
                    dataForEnergieEcoType.min;
                chartOptionsEnergieEconoType.scales["y-axis-trajectoire"].max =
                    dataForEnergieEcoType.max;
                chartOptionsEnergieEconoType.scales["x-axis-0"].ticks.callback = (
                    value
                ) =>
                    value +
                    (this.state.estimatedYears["conso_energetique"] &&
                    this.state.estimatedYears["conso_energetique"].includes(+value)
                        ? " (e)"
                        : "");
            }

            // Energy produced
            let maxDateEnergieProduite = new Date(
                this.state.data["energie_produite"]["max_annee"] + "-01-01"
            );
            const chartOptionsEnergieProduite = JSON.parse(
                JSON.stringify(defaultChartLineOptions)
            );
            chartOptionsEnergieProduite.plugins.title.text =
                "Énergie EnR produite (GWh)";
            chartOptionsEnergieProduite.scales["y-axis-main"].scaleLabel.labelString =
                "Énergie EnR produite (GWh)";
            chartOptionsEnergieProduite.plugins.tooltip.callbacks.title =
                formatTitleToolip;
            chartOptionsEnergieProduite.plugins.tooltip.callbacks.beforeBody = (
                items
            ) => addTotalToTooltip(items, "GWh", "impact-ener-prod");
            // retrieve the limit date between past data and estimates (future)
            chartOptionsEnergieProduite.plugins.annotation.annotations[0].value =
                maxDateEnergieProduite;
            chartOptionsEnergieProduite.plugins.annotation.annotations[1].value =
                maxDateEnergieProduite;
            chartOptionsEnergieProduite.plugins.tooltip.callbacks.label = function (
                data
            ) {
                return formatUnitInPopup(data, "GWh", "impact-ener-prod");
            };
            let dataForEnergieProduite = getDataType("energie_produite", "energie");

            let dataSetsEnergieProduite = dataForEnergieProduite.datasets;
            // force the min and max to the same value for the 2 axes (data + target trajectory)
            chartOptionsEnergieProduite.scales["y-axis-main"].min =
                dataForEnergieProduite.min;
            chartOptionsEnergieProduite.scales["y-axis-main"].max =
                dataForEnergieProduite.max;
            chartOptionsEnergieProduite.scales["y-axis-trajectoire"].min =
                dataForEnergieProduite.min;
            chartOptionsEnergieProduite.scales["y-axis-trajectoire"].max =
                dataForEnergieProduite.max;

            chartOptionsEnergieProduite.scales["x-axis-0"].ticks.callback = (value) =>
                value +
                (this.state.estimatedYears["prod_enr"] &&
                this.state.estimatedYears["prod_enr"].includes(+value)
                    ? " (e)"
                    : "");

            // GES emissions
            let chartOptionsEmissionGesSecteur = undefined;
            let dataSetsEmissionGesSecteur = undefined;
            let chartOptionsEmissionGesType = undefined;
            let dataSetsEmissionGesType = undefined;
            if (Object.keys(this.state.data["emission_ges"]).length !== 0) {
                let maxDateEmissionGES = new Date(
                    this.state.data["emission_ges"]["max_annee"] + "-01-01"
                );
                // GES emissions by sector
                chartOptionsEmissionGesSecteur = JSON.parse(
                    JSON.stringify(defaultChartLineOptions)
                );

                chartOptionsEmissionGesSecteur.plugins.title.text =
                    "Impact sur les " + titre_graph_ges + " par secteur (ktCO2eq)";
                chartOptionsEmissionGesSecteur.scales[
                    "y-axis-main"
                ].scaleLabel.labelString = titre_graph_ges_complet + " (ktCO2eq)";
                // nom.charAt(0).toUpperCase() + nom.substring(1)
                chartOptionsEmissionGesSecteur.plugins.tooltip.callbacks.title =
                    formatTitleToolip;
                chartOptionsEmissionGesSecteur.plugins.tooltip.callbacks.beforeBody = (
                    items
                ) => addTotalToTooltip(items, "ktCO2eq", "impact-carbon-sector");
                // retrieve the limit date between past data and estimates (future)
                chartOptionsEmissionGesSecteur.plugins.annotation.annotations[0].value =
                    maxDateEmissionGES;
                chartOptionsEmissionGesSecteur.plugins.annotation.annotations[1].value =
                    maxDateEmissionGES;
                chartOptionsEmissionGesSecteur.plugins.tooltip.callbacks.label =
                    function (data) {
                        return formatUnitInPopup(
                            data,
                            "ktCO2eq",
                            "impact-carbon-sector"
                        );
                    };
                let dataForEmissionGesSecteur = getDataType("emission_ges", "secteur");
                dataSetsEmissionGesSecteur = dataForEmissionGesSecteur.datasets;
                // force the min and max to the same value for the 2 axes (data + target trajectory)
                chartOptionsEmissionGesSecteur.scales["y-axis-main"].min =
                    dataForEmissionGesSecteur.min;
                chartOptionsEmissionGesSecteur.scales["y-axis-main"].max =
                    dataForEmissionGesSecteur.max;
                chartOptionsEmissionGesSecteur.scales["y-axis-trajectoire"].min =
                    dataForEmissionGesSecteur.min;
                chartOptionsEmissionGesSecteur.scales["y-axis-trajectoire"].max =
                    dataForEmissionGesSecteur.max;

                chartOptionsEmissionGesSecteur.scales["x-axis-0"].ticks.callback = (
                    value
                ) =>
                    value +
                    (this.state.estimatedYears["emission_ges"] &&
                    this.state.estimatedYears["emission_ges"].includes(+value)
                        ? " (e)"
                        : "");

                // GES emissions by type of energy
                chartOptionsEmissionGesType = JSON.parse(
                    JSON.stringify(defaultChartLineOptions)
                );

                chartOptionsEmissionGesType.plugins.title.text =
                    "Impact sur les " + titre_graph_ges + " par énergie (ktCO2eq)";
                chartOptionsEmissionGesType.scales[
                    "y-axis-main"
                ].scaleLabel.labelString =
                    titre_graph_ges?.charAt(0).toUpperCase() +
                    titre_graph_ges?.substring(1) +
                    " (ktCO2eq)";
                chartOptionsEmissionGesType.plugins.tooltip.callbacks.title =
                    formatTitleToolip;
                chartOptionsEmissionGesType.plugins.tooltip.callbacks.beforeBody = (
                    items
                ) => addTotalToTooltip(items, "ktCO2eq", "impact-carbon-energy");
                // retrieve the limit date between past data and estimates (future)
                chartOptionsEmissionGesType.plugins.annotation.annotations[0].value =
                    maxDateEmissionGES;
                chartOptionsEmissionGesType.plugins.annotation.annotations[1].value =
                    maxDateEmissionGES;
                chartOptionsEmissionGesType.plugins.tooltip.callbacks.label = function (
                    data
                ) {
                    return formatUnitInPopup(data, "ktCO2eq", "impact-carbon-energy");
                };
                let dataForEmissionGesType = getDataType("emission_ges", "energie");
                dataSetsEmissionGesType = dataForEmissionGesType.datasets;
                // force the min and max to the same value for the 2 axes (data + target trajectory)
                chartOptionsEmissionGesType.scales["y-axis-main"].min =
                    dataForEmissionGesType.min;
                chartOptionsEmissionGesType.scales["y-axis-main"].max =
                    dataForEmissionGesType.max;
                chartOptionsEmissionGesType.scales["y-axis-trajectoire"].min =
                    dataForEmissionGesType.min;
                chartOptionsEmissionGesType.scales["y-axis-trajectoire"].max =
                    dataForEmissionGesType.max;

                chartOptionsEmissionGesType.scales["x-axis-0"].ticks.callback = (
                    value
                ) =>
                    value +
                    (this.state.estimatedYears["emission_ges"] &&
                    this.state.estimatedYears["emission_ges"].includes(+value)
                        ? " (e)"
                        : "");
            }

            // Air pollutants target trajectories chart
            let dataSetsAirPollutants = {
                secteur: {},
            };
            let chartDataSetsAirPollutants = {
                secteur: {},
            };
            let chartOptionsAirPollutants = {
                secteur: {},
            };

            let pollutants = [
                {
                    key: "covnm",
                    unit: " COVNM (tonne))",
                    title: "Impact sur les émissions de polluants atmosphériques COVNM par #type (tonnes)",
                },
                {
                    key: "nh3",
                    unit: " NH3 (tonne))",
                    title: "Impact sur les émissions de polluants atmosphériques NH3 par #type (tonnes)",
                },
                {
                    key: "nox",
                    unit: " NOx (tonne))",
                    title: "Impact sur les émissions de polluants atmosphériques NOx par #type (tonnes)",
                },
                {
                    key: "sox",
                    unit: " SO2 (tonne))",
                    title: "Impact sur les émissions de polluants atmosphériques SO2 par #type (tonnes)",
                },
                {
                    key: "pm10",
                    unit: " PM10 (tonne))",
                    title: "Impact sur les émissions de polluants atmosphériques PM10 par #type (tonnes)",
                },
                {
                    key: "pm25",
                    unit: " PM2.5 (tonne))",
                    title: "Impact sur les émissions de polluants atmosphériques PM2.5 par #type (tonnes)",
                },
            ];
            let pollutantsGraphTypes = [{ title: "secteur", key: "secteur" }];
            if (this.state.displayAirQualityChart) {
                pollutantsGraphTypes.forEach((graphType) => {
                    pollutants.forEach((pollutant) => {
                        // COVNM emissions
                        let maxDate = new Date(
                            this.state.data[pollutant.key]["max_annee"] + "-01-01"
                        );
                        let dataPollutant = getDataType(pollutant.key, graphType.key);
                        if (dataPollutant === "Confidentiel") {
                            dataSetsAirPollutants[graphType.key][pollutant.key] =
                                "Confidentiel";
                        } else {
                            // Emissions
                            let chartOptions = JSON.parse(
                                JSON.stringify(defaultChartLineOptions)
                            );

                            chartOptions.plugins.title.text = pollutant.title.replace(
                                "#type",
                                graphType.title
                            );
                            chartOptions.scales["y-axis-main"].scaleLabel.labelString =
                                pollutant.unit;
                            // nom.charAt(0).toUpperCase() + nom.substring(1)
                            chartOptions.plugins.tooltip.callbacks.title =
                                formatTitleToolip;
                            chartOptions.plugins.tooltip.callbacks.beforeBody = (
                                items
                            ) => addTotalToTooltip(items, "tonnes", "impact-atmo");
                            // retrieve the limit date between past data and estimates (future)
                            chartOptions.plugins.annotation.annotations[0].value =
                                maxDate;
                            chartOptions.plugins.annotation.annotations[1].value =
                                maxDate;
                            chartOptions.plugins.tooltip.callbacks.label = function (
                                data
                            ) {
                                return formatUnitInPopup(data, "tonnes", "impact-atmo");
                            };
                            // force the min and max to the same value for the 2 axes (data + target trajectory)
                            chartOptions.scales["y-axis-main"].min = dataPollutant.min;
                            chartOptions.scales["y-axis-main"].max = dataPollutant.max;
                            chartOptions.scales["y-axis-trajectoire"].min =
                                dataPollutant.min;
                            chartOptions.scales["y-axis-trajectoire"].max =
                                dataPollutant.max;

                            chartOptions.scales["x-axis-0"].ticks.callback = (value) =>
                                value +
                                (this.state.estimatedYears[pollutant.key] &&
                                this.state.estimatedYears[pollutant.key].includes(
                                    +value
                                )
                                    ? " (e)"
                                    : "");

                            if (this.state.data[pollutant.key]["is_empty"]) {
                                chartOptions.plugins.annotation.annotations.push({
                                    type: "label",
                                    position: "end",
                                    xScaleID: "x-axis-0",
                                    xValue: new Date("2050-01-01"),
                                    content: [
                                        "TerriSTORY ne permet pas pour l'instant",
                                        "de calculer les impacts qualité de l'air",
                                        "des actions sélectionnées",
                                    ],
                                    backgroundColor: "rgba(200,200,200,0.5)",
                                    font: { size: 15 },
                                });
                            }

                            dataSetsAirPollutants[graphType.key][pollutant.key] =
                                dataPollutant.datasets;
                            chartOptionsAirPollutants[graphType.key][pollutant.key] =
                                chartOptions;
                        }
                    });
                });
            }

            // final EnR conso
            let chartLineConsoProdData;
            if (this.state.data["energie_produite"].length > 0) {
                chartLineConsoProdData = lineConsoProdData(this.state.data);
                let maxDateConsoProd = new Date(
                    this.state.data["energie_produite"]["max_annee"] + "-01-01"
                );
                lineConsoProdDataOptions.plugins.annotation.annotations[0].value =
                    maxDateConsoProd;
                lineConsoProdDataOptions.plugins.annotation.annotations[1].value =
                    maxDateConsoProd;
            }
            // Tax benefits
            let labelsRetombeesFiscales = [];
            for (let year in this.state.data["retombees_fiscales"]) {
                dataRetombeesFiscales.commune.push(
                    parseFloat(this.state.data["retombees_fiscales"][year].commune)
                );
                dataRetombeesFiscales.epci.push(
                    parseFloat(this.state.data["retombees_fiscales"][year].epci)
                );
                dataRetombeesFiscales.departement.push(
                    parseFloat(this.state.data["retombees_fiscales"][year].departement)
                );
                dataRetombeesFiscales.region.push(
                    parseFloat(this.state.data["retombees_fiscales"][year].region)
                );
                labelsRetombeesFiscales.push(year);
            }

            let dataSetsRetombeesFiscales = [];
            if (dataRetombeesFiscales.commune.length > 0) {
                dataSetsRetombeesFiscales.push({
                    label: "Commune",
                    backgroundColor: configData.planActionsRetombeesFiscalesCommune,
                    data: dataRetombeesFiscales.commune,
                });
            }
            if (dataRetombeesFiscales.epci.length > 0) {
                dataSetsRetombeesFiscales.push({
                    label: "EPCI",
                    backgroundColor: configData.planActionsRetombeesFiscalesEPCI,
                    data: dataRetombeesFiscales.epci,
                });
            }
            if (dataRetombeesFiscales.departement.length > 0) {
                dataSetsRetombeesFiscales.push({
                    label: "Département",
                    backgroundColor: configData.planActionsRetombeesFiscalesDepartement,
                    data: dataRetombeesFiscales.departement,
                });
            }
            if (dataRetombeesFiscales.region.length > 0) {
                dataSetsRetombeesFiscales.push({
                    label: "Région",
                    backgroundColor: configData.planActionsRetombeesFiscalesRegion,
                    data: dataRetombeesFiscales.region,
                });
            }
            let chartDataRetombeesFiscales = {
                labels: labelsRetombeesFiscales,
                datasets: dataSetsRetombeesFiscales,
            };

            // Investissement
            let dataSetsInvestissement = [];
            if (dataInvestissement.length > 0) {
                dataSetsInvestissement.push({
                    label: zoneName,
                    backgroundColor: configData.planActionsInvestissement,
                    data: dataInvestissement,
                });
            }

            chartNbEmplData = {
                labels: labels,
                datasets: dataSetsNbEmpl,
            };

            chartVAGData = {
                labels: labels,
                datasets: dataSetsVAG,
            };

            if (isDataMix) {
                chartDataMixEnergetiqueSurfacePanneaux = {
                    labels: labels,
                    datasets: dataSetsMixEnergetiqueSurfacePanneaux,
                };
                chartDataMixEnergetiqueSurfaceSol = {
                    labels: labels,
                    datasets: dataSetsMixEnergetiqueSurfaceSol,
                };
                chartDataMixEnergetiqueLogementsChauffes = {
                    labels: labels,
                    datasets: dataSetsMixEnergetiqueLogementsChauffes,
                };
            }

            let chartDataEnergieEcoSecteur = {};
            let chartDataEnergieEcoType = {};
            if (this.state.data["energie_economisee"]["secteur"] !== "Confidentiel") {
                chartDataEnergieEcoSecteur = {
                    datasets: dataSetsEnergieEcoSecteur,
                };
                chartDataEnergieEcoType = {
                    datasets: dataSetsEnergieEcoType,
                };
            }
            let chartDataEnergieProduite = {
                datasets: dataSetsEnergieProduite,
            };
            let chartDataEmissionGesSecteur = {
                datasets: dataSetsEmissionGesSecteur,
            };
            let chartDataEmissionGesType = {
                datasets: dataSetsEmissionGesType,
            };

            pollutantsGraphTypes.forEach((graphType) => {
                pollutants.forEach((pollutant) => {
                    chartDataSetsAirPollutants[graphType.key][pollutant.key] = {
                        datasets: dataSetsAirPollutants[graphType.key][pollutant.key],
                    };
                });
            });

            let chartDataInvestissement = {
                labels: labels,
                datasets: dataSetsInvestissement,
            };

            const buildChartJSX = (
                ref,
                type,
                options,
                data,
                filters,
                displayEstimatedLegend = false
            ) => {
                let source_traj = (
                    <div className="chart-legend">{source_suivi_traj["source"]}</div>
                );
                var chart = undefined;
                var bouton_source = undefined;
                if (type === "Bar") {
                    chart = (
                        <Bar
                            ref={ref}
                            width={options.width}
                            height={options.height}
                            options={options}
                            data={data}
                        />
                    );
                    bouton_source = (
                        <button
                            type="button"
                            className="btn btn-info btn-save block-row"
                            onClick={() => this.saveCanvasAsPng(ref)}
                        >
                            <span>Enregistrer le graphique (PNG)</span>
                        </button>
                    );
                }
                if (type === "Line") {
                    chart = (
                        <Line
                            ref={ref}
                            width={options.width}
                            height={options.height}
                            options={options}
                            data={data}
                        />
                    );
                    bouton_source = (
                        <div className="bouton-source">
                            <div className="source-traj">{source_traj}</div>
                            <button
                                type="button"
                                className="btn btn-info btn-save block-row"
                                onClick={() => this.saveCanvasAsPng(ref)}
                            >
                                <span>Enregistrer le graphique (PNG)</span>
                            </button>
                        </div>
                    );
                }

                let estimatedLegend = null;
                if (displayEstimatedLegend)
                    estimatedLegend = <>(e) = données estimées</>;

                return (
                    <div className="block-row">
                        {chart}
                        {filters}
                        {estimatedLegend}
                        {bouton_source}
                    </div>
                );
            };

            if (chartNbEmplData.datasets.length > 0) {
                chartNbEmpl = buildChartJSX(
                    "chartNbEmpl",
                    "Bar",
                    chartOptionsNbEmpl,
                    chartNbEmplData
                );
            } else {
                chartNbEmpl = (
                    <div className="block-row">
                        <p>
                            <strong>Impact sur le nombre d'emplois</strong>
                        </p>
                        <div className="form-group centered-row">
                            <label className="missing-data">
                                Aucune action paramétrée
                            </label>
                        </div>
                    </div>
                );
            }
            if (chartVAGData.datasets.length > 0) {
                chartVAG = buildChartJSX(
                    "chartVAG",
                    "Bar",
                    chartOptionsVAG,
                    chartVAGData,
                    filters
                );
            } else {
                chartVAG = (
                    <div className="block-row">
                        <p>
                            <strong>
                                Impact sur la répartition de la valeur ajoutée
                            </strong>
                        </p>
                        <div className="form-group centered-row">
                            <label className="missing-data">
                                Aucune action paramétrée
                            </label>
                        </div>
                    </div>
                );
            }
            if (Object.keys(this.state.data["energie_economisee"]).length !== 0) {
                if (
                    this.state.data["energie_economisee"]["secteur"] !== "Confidentiel"
                ) {
                    chartEnergieEcoSecteur = buildChartJSX(
                        "chartEnergieEcoSecteur",
                        "Line",
                        chartOptionsEnergieEconoSecteur,
                        chartDataEnergieEcoSecteur,
                        undefined,
                        this.state.estimatedYears["conso_energetique"] &&
                            this.state.estimatedYears["conso_energetique"].length
                    );
                } else {
                    chartEnergieEcoSecteur = (
                        <div>
                            <p>
                                <strong>
                                    Impact sur la consommation d'énergie par secteur
                                    (GWh)
                                </strong>
                            </p>
                            <p>Données confidentielles</p>
                        </div>
                    );
                }
                if (this.state.data["energie_economisee"]) {
                    if (
                        this.state.data["energie_economisee"]["energie"] !==
                        "Confidentiel"
                    ) {
                        chartEnergieEcoType = buildChartJSX(
                            "chartEnergieEconoType",
                            "Line",
                            chartOptionsEnergieEconoType,
                            chartDataEnergieEcoType,
                            undefined,
                            this.state.estimatedYears["conso_energetique"] &&
                                this.state.estimatedYears["conso_energetique"].length
                        );
                    } else {
                        chartEnergieEcoType = (
                            <div>
                                <p>
                                    <strong>
                                        Impact sur la consommation d'énergie par énergie
                                        (GWh)
                                    </strong>
                                </p>
                                <p>Données confidentielles</p>
                            </div>
                        );
                    }
                }
            } else {
                chartEnergieEcoSecteur = (
                    <div>
                        <p>
                            <strong>
                                Impact sur la consommation d'énergie par secteur (GWh)
                            </strong>
                        </p>
                        <div className="form-group centered-row">
                            <label className="missing-data">
                                Données incomplètes !<br />
                                Lorsque toutes les données auront été saisies, les
                                impacts seront disponibles ici.
                            </label>
                        </div>
                    </div>
                );

                chartEnergieEcoType = (
                    <div>
                        <p>
                            <strong>
                                Impact sur la consommation d'énergie par énergie (GWh)
                            </strong>
                        </p>
                        <div className="form-group centered-row">
                            <label className="missing-data">
                                Données incomplètes !<br />
                                Lorsque toutes les données auront été saisies, les
                                impacts seront disponibles ici.
                            </label>
                        </div>
                    </div>
                );
            }

            if (chartDataEnergieProduite.datasets.length > 0) {
                chartEnergieProduite = buildChartJSX(
                    "chartEnergieProduite",
                    "Line",
                    chartOptionsEnergieProduite,
                    chartDataEnergieProduite,
                    undefined,
                    this.state.estimatedYears["prod_enr"] &&
                        this.state.estimatedYears["prod_enr"].length
                );
            } else {
                chartEnergieProduite = (
                    <div>
                        <p>
                            <strong>Impact sur la production d'énergie</strong>
                        </p>
                        <div className="form-group centered-row">
                            <label className="missing-data">
                                Aucune action paramétrée
                            </label>
                        </div>
                    </div>
                );
            }

            if (chartDataFactureEnergetique.datasets.length > 0) {
                chartFactureEnergetique = buildChartJSX(
                    "chartFactureEnergetique",
                    "Bar",
                    chartOptionsFactureEnergetique,
                    chartDataFactureEnergetique
                );
            } else {
                chartFactureEnergetique = (
                    <div className="block-row">
                        <p>
                            <strong>Impact sur la facture énergétique</strong>
                        </p>
                        <div className="form-group centered-row">
                            <label className="missing-data">
                                Aucune action paramétrée
                            </label>
                        </div>
                    </div>
                );
            }
            if (Object.keys(this.state.data["emission_ges"]).length !== 0) {
                if (this.state.data["emission_ges"]["secteur"] !== "Confidentiel") {
                    chartEmissionGesSecteur = buildChartJSX(
                        "chartEmissionGesSecteur",
                        "Line",
                        chartOptionsEmissionGesSecteur,
                        chartDataEmissionGesSecteur,
                        undefined,
                        this.state.estimatedYears["emission_ges"] &&
                            this.state.estimatedYears["emission_ges"].length
                    );
                } else {
                    chartEmissionGesSecteur = (
                        <div>
                            <p>
                                <strong>
                                    Impact sur les émissions de gaz à effet de serre par
                                    secteur (GWh)
                                </strong>
                            </p>
                            <p>Données confidentielles</p>
                        </div>
                    );
                }
                if (this.state.data["emission_ges"]["energie"] !== "Confidentiel") {
                    chartEmissionGesType = buildChartJSX(
                        "chartEmissionGesType",
                        "Line",
                        chartOptionsEmissionGesType,
                        chartDataEmissionGesType,
                        undefined,
                        this.state.estimatedYears["emission_ges"] &&
                            this.state.estimatedYears["emission_ges"].length
                    );
                } else {
                    chartEmissionGesType = (
                        <div>
                            <p>
                                <strong>
                                    Impact sur les émissions de gaz à effet de serre par
                                    énergie (GWh)
                                </strong>
                            </p>
                            <p>Données confidentielles</p>
                        </div>
                    );
                }
            } else {
                chartEmissionGesSecteur = (
                    <div>
                        <p>
                            <strong>
                                Impact sur les émissions de gaz à effet de serre par
                                secteur (GWh)
                            </strong>
                        </p>
                        <div className="form-group centered-row">
                            <label className="missing-data">
                                Données incomplètes !<br />
                                Lorsque toutes les données auront été saisies, les
                                impacts seront disponibles ici.
                            </label>
                        </div>
                    </div>
                );
                chartEmissionGesType = (
                    <div>
                        <p>
                            <strong>
                                Impact sur les émissions de gaz à effet de serre par
                                énergie (GWh)
                            </strong>
                        </p>
                        <div className="form-group centered-row">
                            <label className="missing-data">
                                Données incomplètes !<br />
                                Lorsque toutes les données auront été saisies, les
                                impacts seront disponibles ici.
                            </label>
                        </div>
                    </div>
                );
            }

            if (this.state.displayAirQualityChart) {
                pollutantsGraphTypes.forEach((graphType) => {
                    pollutants.forEach((pollutant) => {
                        if (
                            chartDataSetsAirPollutants[graphType.key][pollutant.key]
                                .datasets === "Confidentiel"
                        ) {
                            chartAirPollutants[graphType.key][pollutant.key] = (
                                <div>
                                    <h5>
                                        {pollutant.title.replace(
                                            "#type",
                                            graphType.title
                                        )}
                                    </h5>
                                    <p>Données confidentielles non modélisables.</p>
                                </div>
                            );
                        } else {
                            chartAirPollutants[graphType.key][pollutant.key] =
                                buildChartJSX(
                                    "chartAirPolluants" + pollutant.key + graphType.key,
                                    "Line",
                                    chartOptionsAirPollutants[graphType.key][
                                        pollutant.key
                                    ],
                                    chartDataSetsAirPollutants[graphType.key][
                                        pollutant.key
                                    ],
                                    undefined,
                                    this.state.estimatedYears[pollutant.key] &&
                                        this.state.estimatedYears[pollutant.key].length
                                );
                        }
                    });
                });
            }

            if (chartDataRetombeesFiscales.datasets.length > 0) {
                chartRetombeesFiscales = buildChartJSX(
                    "chartRetombeesFiscales",
                    "Bar",
                    chartOptionsRetombeesFiscales,
                    chartDataRetombeesFiscales
                );
            } else {
                chartRetombeesFiscales = (
                    <div className="block-row">
                        <p>
                            <strong>Retombées fiscales</strong>
                        </p>
                        <div className="form-group centered-row">
                            <label className="missing-data">
                                Aucune action paramétrée
                            </label>
                        </div>
                    </div>
                );
            }

            if (chartDataInvestissement.datasets.length > 0) {
                chartInvestissement = buildChartJSX(
                    "chartInvestissement",
                    "Bar",
                    chartOptionsInvestissement,
                    chartDataInvestissement
                );
            } else {
                chartInvestissement = (
                    <div className="block-row">
                        <p>
                            <strong>Impact sur les investissements</strong>
                        </p>
                        <div className="form-group centered-row">
                            <label className="missing-data">
                                Aucune action paramétrée
                            </label>
                        </div>
                    </div>
                );
            }

            if (chartLineConsoProdData) {
                lineConsoVsEnR = buildChartJSX(
                    "lineConsoVsEnR",
                    "Line",
                    lineConsoProdDataOptions,
                    chartLineConsoProdData,
                    undefined,
                    this.state.estimatedYears["prod_enr_ratio"] &&
                        this.state.estimatedYears["prod_enr_ratio"].length
                );
            } else {
                lineConsoVsEnR = (
                    <div>
                        <p>
                            <strong>
                                Impact sur la comparaison production et consommation
                                d'énergie
                            </strong>
                        </p>
                        <div className="form-group centered-row">
                            <label className="missing-data">
                                Aucune action paramétrée
                            </label>
                        </div>
                    </div>
                );
            }

            if (isDataMix) {
                chartMixEnergetique = (
                    <Tabs>
                        <TabList>
                            <Tab>Surface panneaux solaires</Tab>
                            <Tab>Surface sol</Tab>
                            <Tab>Nb logements chauffés</Tab>
                        </TabList>

                        <TabPanel>
                            <div className="block-row">
                                <Bar
                                    ref="chart"
                                    width={chartOptionsMixEnergetique.width}
                                    height={chartOptionsMixEnergetique.height}
                                    options={chartOptionsMixEnergetique}
                                    data={chartDataMixEnergetiqueSurfacePanneaux}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="block-row">
                                <Bar
                                    ref="chart"
                                    width={chartOptionsMixEnergetique.width}
                                    height={chartOptionsMixEnergetique.height}
                                    options={chartOptionsMixEnergetique}
                                    data={chartDataMixEnergetiqueSurfaceSol}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="block-row">
                                <Bar
                                    ref="chart"
                                    width={chartOptionsMixEnergetique.width}
                                    height={chartOptionsMixEnergetique.height}
                                    options={chartOptionsMixEnergetique}
                                    data={chartDataMixEnergetiqueLogementsChauffes}
                                />
                            </div>
                        </TabPanel>
                    </Tabs>
                );
            }
        }

        let dataLoaded = "";
        if (!this.state.dataLoaded) {
            dataLoaded = <div className="loader centered-widget"></div>;
        }

        let errors = "";
        if (this.state.error !== "") {
            errors = <div className="alert alert-warning">{this.state.error}</div>;
        }
        let actionList = "";

        if (this.state.categories && this.state.yearsConsoGes && this.state.yearsProd) {
            let hidePastInputs =
                !this.props.parentApi.data.settings["enable_past_inputs_for_strategy"];
            const currentYear = new Date().getFullYear();

            actionList = this.state.categories.map((cat) => {
                let actions = this.state.actions.map((a) => {
                    if (a.categorie === cat.name) {
                        let years = this.state.yearsConsoGes;
                        if (cat.className === "prod_renouv") {
                            years = this.state.yearsProd;
                        }
                        const renderInputField = (
                            pNom,
                            pId,
                            y,
                            valeur,
                            isAutresParams = false
                        ) => {
                            let nom = pNom + "#" + y;
                            let id = pId + "#" + y;
                            let cellRef = "";

                            if (y === a.annee_ref && !isAutresParams) {
                                cellRef = "green";
                            } else if (y === a.annee_ref && isAutresParams) {
                                delete years[y];
                                cellRef = "advanced-params";
                            }

                            return (
                                <div key={id} className="annee-cell">
                                    <div className="pre-input-year">{y}</div>
                                    <input
                                        type="text"
                                        className={"form-control input-year " + cellRef}
                                        placeholder={valeur}
                                        defaultValue={valeur}
                                        key={id}
                                        id={nom}
                                        ref={nom}
                                        onChange={(e) =>
                                            handleCoeffChange(e, isAutresParams)
                                        }
                                    />
                                </div>
                            );
                        };
                        // List all params available for that action
                        let params = this.state.coeffs.map((c) => {
                            // Display only coeff for enabled actions
                            let classVisibility = "hide";
                            if (c.action === a.numero && a.enabled === true) {
                                classVisibility = "";
                                // add the coefficients of the default reference year
                                if (a.annee_ref) {
                                    for (let item in a.coeffs_ref) {
                                        if (c.nom === item) {
                                            // add the reference year and the % of heating equipment in the residential sector (action 16) and tertiary sector (action 17)
                                            if (!years.includes(a.annee_ref)) {
                                                years.unshift(a.annee_ref);
                                            }
                                            if (this.state.updated_params_action) {
                                                // browse the new action parameters
                                                for (const action of this.state
                                                    .updated_params_action) {
                                                    if (
                                                        (action.numero === "16" &&
                                                            a.numero === "16") ||
                                                        (action.numero === "17" &&
                                                            a.numero === "17")
                                                    ) {
                                                        c.valeur_annee[a.annee_ref] =
                                                            action.coeffs_ref[
                                                                item
                                                            ].toFixed(1);
                                                    }
                                                }
                                            }
                                            if (
                                                !this.state.updated_params_action &&
                                                c.valeur_annee
                                            ) {
                                                c.valeur_annee[a.annee_ref] =
                                                    a.coeffs_ref[item].toFixed(1);
                                            }
                                        }
                                    }
                                } else {
                                    if (years.includes(this.state.referenceYear)) {
                                        years.shift(a.annee_ref);
                                    }
                                }
                            } else {
                                // hide the object if the action is not activated
                                return "";
                            }

                            const renderSelectField = (
                                pNom,
                                pId,
                                y,
                                valeur,
                                choices
                            ) => {
                                let nom = pNom + "#" + y;
                                let id = pId + "#" + y;
                                let cellRef = "";
                                if (y === a.annee_ref) {
                                    cellRef = "green";
                                }
                                let select = (
                                    <div key={id}>
                                        <div className={"pre-input-year " + cellRef}>
                                            {y}
                                        </div>
                                        <select
                                            value={valeur}
                                            onChange={handleCoeffChange}
                                            key={id}
                                            id={nom}
                                            ref={nom}
                                            className="action-select"
                                        >
                                            {choices.map((c) => {
                                                return (
                                                    <option value={c} key={c}>
                                                        {c}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                );

                                return select;
                            };

                            let unite = "";

                            if (c.unite) {
                                unite = "(" + c.unite + ")";
                            }
                            return (
                                <div className="block-input" key={"coeff-" + c.id}>
                                    <div
                                        className={
                                            "input-group mb-3 " + classVisibility
                                        }
                                        key={"coeff-" + c.id}
                                    >
                                        <div className="input-group-prepend">
                                            <div className="input-group-text block-input-title">
                                                {c.label} {unite}
                                            </div>
                                        </div>
                                        {years.map((y) => {
                                            if (
                                                hidePastInputs &&
                                                y < currentYear &&
                                                y !== a.annee_ref
                                            ) {
                                                return "";
                                            }
                                            let valeur = 0;
                                            if (c.valeur_annee) {
                                                valeur = c.valeur_annee[y];
                                            }

                                            if (isNaN(valeur) && !c.choices) {
                                                valeur = "";
                                            }
                                            let field = undefined;
                                            if (c.choices) {
                                                field = renderSelectField(
                                                    c.nom,
                                                    c.id,
                                                    y,
                                                    valeur,
                                                    c.choices
                                                );
                                            } else {
                                                field = renderInputField(
                                                    c.nom,
                                                    c.id,
                                                    y,
                                                    valeur
                                                );
                                            }

                                            return field;
                                        })}
                                    </div>
                                </div>
                            );
                        });

                        // List all advanced params for that action
                        let advanced_params = "";
                        if (this.state.coeffsAdvanced) {
                            advanced_params = this.state.coeffsAdvanced.map((c) => {
                                let classVisibility = "hide";
                                if (c.action === a.numero && a.enabled === true) {
                                    classVisibility = "";
                                } else {
                                    // hide the object if the action is not activated
                                    return "";
                                }

                                let tableCoeffsAdvancedEconomic = [];
                                let autresItems = [];
                                let autresParams = "";

                                if (c.action === a.numero) {
                                    const renderAdvancedInputField = (
                                        pKey,
                                        pNom,
                                        pId,
                                        valeur
                                    ) => {
                                        if (!/^⁻?\d+\.?\d*$/.test(valeur)) {
                                            valeur = "";
                                        }
                                        return (
                                            <div key={pId}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={valeur}
                                                    value={valeur}
                                                    id={pKey}
                                                    ref={pKey}
                                                    onChange={(e) =>
                                                        handleCoeffAdvancedChange(
                                                            pNom,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        );
                                    };
                                    for (let key in c.params_avances) {
                                        if (key === "autres") {
                                            // TODO be careful, if we detect a year column in columns, then we must create as many boxes as necessary (until 2030)
                                            // Count the number of subkeys
                                            let nbAutresItems = Object.keys(
                                                c.params_avances[key]
                                            ).length;
                                            // Look for another sub keys

                                            for (let subkey in c.params_avances[key]) {
                                                let columns =
                                                    c.params_avances[key][subkey]
                                                        .columns;
                                                let index =
                                                    c.params_avances[key][subkey].index;
                                                let data =
                                                    c.params_avances[key][subkey].data;
                                                let description =
                                                    c.params_avances[key][subkey]
                                                        .description;
                                                let contrainte =
                                                    c.params_avances[key][subkey]
                                                        .contrainte;

                                                let headerTableAutres = [];
                                                let colIntitule = "";
                                                for (let col of columns) {
                                                    if (col === "nom") {
                                                        colIntitule = "Nom";
                                                    } else if (col === "valeur") {
                                                        colIntitule = "Valeur";
                                                    } else if (col === "unite") {
                                                        colIntitule = "Unité";
                                                    }
                                                    let classTd = "";
                                                    if (col === "id") {
                                                        classTd = "hidden";
                                                    }
                                                    let keyHdr =
                                                        "autres_" +
                                                        subkey.replaceAll(" ", "_") +
                                                        "_" +
                                                        col +
                                                        "_hdr";
                                                    headerTableAutres.push(
                                                        <th
                                                            className={
                                                                classTd + " capitalize"
                                                            }
                                                            key={keyHdr}
                                                        >
                                                            {colIntitule}
                                                        </th>
                                                    );
                                                }

                                                let contentTableAutres = [];
                                                for (let ind in index) {
                                                    //for(let d of data[ind]) {
                                                    let d = data[ind];
                                                    if (
                                                        !/^⁻?\d+\.?\d*$/.test(
                                                            d[d.length - 1]
                                                        )
                                                    ) {
                                                        d[d.length - 1] = "";
                                                    }

                                                    let idPAdvanced =
                                                        a.numero +
                                                        "-autres-" +
                                                        subkey.replaceAll(" ", "_") +
                                                        "-" +
                                                        ind;
                                                    let idPAdvancedFull =
                                                        a.numero +
                                                        "-autres-" +
                                                        subkey +
                                                        "-" +
                                                        ind;
                                                    let tabCol = [];
                                                    for (let c in columns) {
                                                        let curCol = columns[c];
                                                        let keyTd =
                                                            idPAdvanced + "_" + curCol;
                                                        if (curCol === "valeur") {
                                                            tabCol.push(
                                                                <td key={keyTd}>
                                                                    {renderAdvancedInputField(
                                                                        idPAdvanced,
                                                                        idPAdvancedFull,
                                                                        idPAdvanced,
                                                                        d[c]
                                                                    )}
                                                                </td>
                                                            );
                                                        } else {
                                                            let classTd = "";
                                                            if (
                                                                curCol.toLowerCase() ===
                                                                "id"
                                                            ) {
                                                                classTd = "hidden";
                                                            }
                                                            tabCol.push(
                                                                <td
                                                                    className={classTd}
                                                                    key={keyTd}
                                                                >
                                                                    {d[c]}
                                                                </td>
                                                            );
                                                        }
                                                    }

                                                    let lineTable = (
                                                        <tr key={idPAdvanced}>
                                                            {tabCol}
                                                        </tr>
                                                    );
                                                    contentTableAutres.push(lineTable);
                                                }
                                                let keyAccord =
                                                    "accord_autres_" + subkey;
                                                if (nbAutresItems >= 1)
                                                    autresItems.push(
                                                        <AccordionItem key={keyAccord}>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    {" "}
                                                                    {subkey}
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <i className="tip">
                                                                    {description}
                                                                </i>
                                                                <table className="params-avances">
                                                                    <thead>
                                                                        <tr>
                                                                            {
                                                                                headerTableAutres
                                                                            }
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            contentTableAutres
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <i className="tip">
                                                                    {contrainte}
                                                                </i>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    );
                                                else
                                                    autresItems.push(
                                                        <div key={keyAccord}>
                                                            <i className="tip">
                                                                {description}
                                                            </i>
                                                            <table className="params-avances">
                                                                <thead>
                                                                    <tr>
                                                                        {
                                                                            headerTableAutres
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {contentTableAutres}
                                                                </tbody>
                                                            </table>
                                                            <i className="tip">
                                                                {contrainte}
                                                            </i>
                                                        </div>
                                                    );
                                            }
                                        } else if (key === "others_years") {
                                            for (let subkey in c.params_avances[key]) {
                                                let index =
                                                    c.params_avances[key][subkey].index;
                                                let data =
                                                    c.params_avances[key][subkey].data;
                                                for (let ind in index) {
                                                    let name = data[ind];
                                                    let keyAccord =
                                                        "accord_others_years_" + subkey;
                                                    let idPAdvanced =
                                                        a.numero +
                                                        "-others_years-" +
                                                        subkey.replaceAll(" ", "_");
                                                    let idPAdvancedFull =
                                                        a.numero +
                                                        "-others_years-" +
                                                        subkey;
                                                    autresItems.push(
                                                        <AccordionItem key={keyAccord}>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    {" "}
                                                                    {subkey}
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <div
                                                                    className="block-input advanced-year-params"
                                                                    key={
                                                                        "coeff-" +
                                                                        idPAdvanced
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            "input-group mb-3 " +
                                                                            classVisibility
                                                                        }
                                                                        key={
                                                                            "coeff-" +
                                                                            idPAdvanced
                                                                        }
                                                                    >
                                                                        <div className="input-group-prepend">
                                                                            <div className="input-group-text block-input-title">
                                                                                {name}
                                                                            </div>
                                                                        </div>
                                                                        {years.map(
                                                                            (y) => {
                                                                                let valeur = 0;

                                                                                if (
                                                                                    c
                                                                                        .params_avances[
                                                                                        key
                                                                                    ][
                                                                                        subkey
                                                                                    ]
                                                                                        ?.valeur_annee?.[
                                                                                        y
                                                                                    ]
                                                                                ) {
                                                                                    valeur =
                                                                                        c
                                                                                            .params_avances[
                                                                                            key
                                                                                        ][
                                                                                            subkey
                                                                                        ]
                                                                                            .valeur_annee[
                                                                                            y
                                                                                        ];
                                                                                }
                                                                                if (
                                                                                    isNaN(
                                                                                        valeur
                                                                                    )
                                                                                ) {
                                                                                    valeur =
                                                                                        "";
                                                                                }
                                                                                let field =
                                                                                    renderInputField(
                                                                                        idPAdvanced,
                                                                                        idPAdvancedFull,
                                                                                        y,
                                                                                        valeur,
                                                                                        true
                                                                                    );

                                                                                return field;
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    );
                                                }
                                            }
                                        } else {
                                            // economical, fixed structure
                                            for (let phase of c.params_avances[key]) {
                                                let idPAdvanced =
                                                    a.numero +
                                                    "-economique-" +
                                                    phase.id;
                                                tableCoeffsAdvancedEconomic.push(
                                                    <tr key={idPAdvanced}>
                                                        <td>{phase.phase_projet}</td>
                                                        <td>{phase.maillon}</td>
                                                        <td>
                                                            <p>{phase.part_france}</p>
                                                        </td>
                                                        <td>
                                                            {renderAdvancedInputField(
                                                                idPAdvanced,
                                                                idPAdvanced,
                                                                idPAdvanced,
                                                                phase.part
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        }
                                    }
                                    if (autresItems.length > 0) {
                                        let blocAutres = undefined;
                                        if (autresItems.length >= 1) {
                                            blocAutres = autresItems;
                                        } else {
                                            blocAutres = (
                                                <Accordion
                                                    allowMultipleExpanded
                                                    allowZeroExpanded
                                                >
                                                    {autresItems}
                                                </Accordion>
                                            );
                                        }

                                        autresParams = (
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {" "}
                                                        Modifier les autres paramètres
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <i>
                                                        Paramètres remplis par défaut, à
                                                        modifier pour votre territoire
                                                        si nécessaire
                                                    </i>
                                                    {blocAutres}
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        );
                                    } else
                                        autresParams = (
                                            <i>
                                                Pas d'autres paramètres avancés pour
                                                cette action
                                            </i>
                                        );
                                }

                                let keyAccordion = "accordion-" + c.action;
                                return c.params_avances.economique.length === 0 ? (
                                    <div className={classVisibility} key={keyAccordion}>
                                        <Accordion
                                            allowMultipleExpanded
                                            allowZeroExpanded
                                        >
                                            {autresParams}
                                        </Accordion>
                                    </div>
                                ) : (
                                    <div className={classVisibility} key={keyAccordion}>
                                        <Accordion
                                            allowMultipleExpanded
                                            allowZeroExpanded
                                        >
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {" "}
                                                        Modifier les paramètres
                                                        économiques
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <i>
                                                        Part calculée par défaut à
                                                        partir de données statistiques,
                                                        à modifier pour votre territoire
                                                        si nécessaire
                                                    </i>
                                                    <table className="params-avances">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Grandes phases
                                                                    projet
                                                                </th>
                                                                <th>
                                                                    Maillon détaillé
                                                                </th>
                                                                <th>
                                                                    Part captée par la
                                                                    France (%){" "}
                                                                </th>
                                                                <th>
                                                                    {" "}
                                                                    Part captée par le
                                                                    territoire (%)
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                tableCoeffsAdvancedEconomic
                                                            }
                                                        </tbody>
                                                    </table>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            {autresParams}
                                        </Accordion>
                                    </div>
                                );
                            });
                        }

                        let description = "";

                        if (a.enabled) {
                            if (
                                ["18", "19"].includes(a.numero) &&
                                this.state.updated_params_action !== undefined
                            ) {
                                // browse the new action parameters
                                for (const action of this.state.updated_params_action) {
                                    if (
                                        (action.numero === "18" && a.numero === "18") ||
                                        (action.numero === "19" && a.numero === "19")
                                    ) {
                                        description = action.description;
                                    }
                                }
                            } else {
                                description = <i className="tip">{a.description}</i>;
                            }
                        }

                        let defaultChecked = !!a.enabled || "";
                        if (this.state.disabledActions.includes(a.numero)) {
                            defaultChecked = false;
                        }

                        return (
                            <li key={"action-" + a.id}>
                                <label className={"checkbox-container "}>
                                    {a.name}
                                    <input
                                        type="checkbox"
                                        key={a.numero}
                                        name={a.id}
                                        id={a.numero}
                                        checked={defaultChecked}
                                        onChange={handleActionChange}
                                    />
                                    <span className={"checkmark"}></span>
                                </label>
                                {params}
                                {description}
                                {advanced_params}
                            </li>
                        );
                    }
                    return undefined;
                });

                return (
                    <div key={cat.className + "_" + cat.name}>
                        <label className={"category " + cat.className}>
                            {cat.name}
                        </label>
                        {actions}
                    </div>
                );
            });
        }

        let titleChartMixEnergetique = "";
        let legendChartMixEnergetique = "";
        if (chartMixEnergetique !== "") {
            titleChartMixEnergetique = (
                <div className="title centered-row">
                    <label>
                        Installations de solaire thermique résultant de la modification
                        du mix énergétique des réseaux de chaleur
                    </label>
                </div>
            );
            legendChartMixEnergetique = (
                <div className="chart-legend">
                    <label>Estimations réalisées dans le cadre du projet SDHp2m</label>
                    <div className="chart-legend-infos">
                        <div className="chart-legend-item chart-legend-item-text italic">
                            Le projet SDHp2m est soutenu financièrement par le
                            programme-cadre Horizon 2020 pour la recherche et
                            l‘innovation, sous le contrat n°691624. Le contenu de cette
                            publication n‘engage que la responsabilité de son auteur et
                            ne représente pas nécessairement l‘opinion de l‘Union
                            européenne. Ni la Commission européenne, ni les auteurs ne
                            sont responsables de l‘usage qui pourrait être fait des
                            informations qui y figurent.
                        </div>
                        <div className="chart-legend-item">
                            <img src="img/SDHlogo.png" alt="SDH" />
                        </div>
                        <div className="chart-legend-item">
                            <img src="img/logo_ue.png" alt="Europe" />
                        </div>
                    </div>
                </div>
            );
        }

        let exportResultsButton = this.props.parentApi.data.settings[
            "export_excel_results"
        ] ? (
            <button
                type="button"
                className="btn btn-info block-row"
                onClick={() => this.exportResults()}
            >
                Export des résultats
            </button>
        ) : (
            ""
        );

        let actionsWidget = "";
        if (!this.props.fromMenu) {
            actionsWidget = (
                <div className="actions-container">
                    <div className="options actions centered-widget">
                        <label>
                            Pour vous aider à piloter votre trajectoire de transition,
                            TerriSTORY® vous permet de : <br></br>
                            <br></br>
                            <b>
                                {" "}
                                Saisir votre trajectoire cible climat-air-énergie
                                jusqu'à 2050 (en lien avec les objectifs de votre
                                territoire).
                            </b>{" "}
                            <br></br>
                            <br></br>
                            <p>
                                <b>➔</b> Pour cela, cliquez sur Ajouter des trajectoires
                                cibles finales et définissez vos objectifs annuels en
                                déplaçant les points sur le(s) graphique(s) à l’aide de
                                votre souris.
                            </p>
                            <b>
                                Analyser l’impact des actions structurantes de votre
                                plan d’actions, en termes énergétiques (consommation et
                                production), carbone (émissions de GES), qualité de
                                l'air (émissions de polluants atmosphériques) et
                                économiques (emploi, fiscalité, facture énergétique)
                                tout en mesurant leurs contributions à votre trajectoire
                                cible.
                            </b>
                            <br></br>
                            <br></br>
                            <p>
                                <b>➔</b> Pour cela, choisissez des actions, ci-dessous,
                                et complétez les valeurs prévisionnelles annuelles de
                                ces actions. Cliquez sur Lancer le calcul pour obtenir
                                une estimation des différents impacts de votre plan
                                d’actions dans le temps.
                            </p>
                            <p>
                                Enfin, les deux boutons d'Export permettent d'une part
                                d'exporter sa stratégie territoriale au format Excel
                                pour la retravailler ensuite (bouton "Export des
                                résultats"), d'autre part de l'exporter dans le format
                                ADEME de dépôt des PCAET (bouton "Export Excel ADEME").
                            </p>
                            <br></br>
                            <p>
                                <b>Point d'attention :</b> par souci de simplification
                                et de lisibilité pour l'utilisateur, le plan d'actions
                                TerriSTORY n'intègre pas d'éléments de prospective. Par
                                conséquent, une action dans TerriSTORY enregistrera le
                                même impact qu'elle soit mise en place en 2020 ou en
                                2050 (même si des évolutions en termes de performance et
                                de coût sont en réalité probables entre ces 2 dates).
                            </p>
                            <br></br>
                        </label>
                        <div className="centered-row">
                            {this.state.displayEnergyTrajectoryButton && (
                                <button
                                    type="button"
                                    className="btn btn-info block-row"
                                    onClick={() =>
                                        this.ajouterRetirerTrajectoireCible()
                                    }
                                >
                                    <span>
                                        {labelBoutonTrajectoireCibleEnergieClimat}
                                    </span>
                                </button>
                            )}
                            {this.state.displayAirQualityTrajectoryButton && (
                                <button
                                    type="button"
                                    className="btn btn-info block-row"
                                    onClick={() =>
                                        this.ajouterRetirerTrajectoireCibleQualiteAir()
                                    }
                                >
                                    <span>{labelBoutonTrajectoireCibleQualiteAir}</span>
                                </button>
                            )}
                            {uiTrajectoireCible}
                        </div>
                        <label>
                            Choisissez des actions et complétez les valeurs
                            prévisionnelles annuelles de vos actions :
                        </label>
                        <ul>{actionList}</ul>
                    </div>

                    <div className="centered-row">
                        {errors}
                        <button
                            type="button"
                            className="btn btn-info block-row"
                            onClick={() => this.launchPlanActions()}
                        >
                            Lancer le calcul
                        </button>
                        {!this.props.parentApi?.data?.settings?.["is_national"] && (
                            <button
                                type="button"
                                className="btn btn-info block-row"
                                onClick={() => this.exportPlanActions()}
                            >
                                Export Excel ADEME
                            </button>
                        )}
                        {exportResultsButton}
                        {/* <button type="button" className="btn btn-info block-row" onClick={() => this.exportParamsStrategie()}>Export des paramètres de la stratégspan></button> */}
                        <button
                            type="button"
                            className="btn btn-warning block-row"
                            onClick={() => this.resetCoeffs()}
                        >
                            Réinitialiser les valeurs
                        </button>
                        <button
                            type="button"
                            className="btn btn-warning block-row"
                            onClick={() => this.resetTargetTrajectories()}
                        >
                            Réinitialiser les trajectoires cibles
                        </button>
                        {/* <input type="file" onChange={(e) => {this.importFile(e)}} />
                                <button className="btn btn-info" onClick={() => {this.chargerParametresStrategies()}}>{"Importer les données"}</button> */}
                        {dataLoaded}
                    </div>
                </div>
            );
        }

        let results = "";
        if (this.state.data) {
            results = (
                <div ref="results" className="centered-row centered-widget results">
                    <Accordion allowMultipleExpanded allowZeroExpanded>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {" "}
                                    Impacts énergétiques
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div ref="results" className="centered-row">
                                    {chartEnergieEcoSecteur}
                                    <span className="separator-line"></span>
                                    {chartEnergieEcoType}
                                    <span className="separator-line"></span>
                                    {chartEnergieProduite}
                                    <span className="separator-line"></span>
                                    {lineConsoVsEnR}
                                    <span className="separator-line"></span>
                                    {titleChartMixEnergetique}
                                    {chartMixEnergetique}
                                    {legendChartMixEnergetique}
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {" "}
                                    Impacts carbone
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div ref="results" className="centered-row">
                                    {chartEmissionGesSecteur}
                                    <span className="separator-line"></span>
                                    {chartEmissionGesType}
                                    <span className="separator-line"></span>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        {this.state.displayAirQualityChart &&
                            this.props.parentApi.data.settings
                                .enable_air_pollutants_impacts && (
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {" "}
                                            Impacts qualité de l'air
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div ref="results" className="centered-row">
                                            {chartAirPollutants["secteur"]["nox"]}
                                            <span className="separator-line"></span>
                                            {chartAirPollutants["secteur"]["pm10"]}
                                            <span className="separator-line"></span>
                                            {chartAirPollutants["secteur"]["pm25"]}
                                            <span className="separator-line"></span>
                                            {chartAirPollutants["secteur"]["covnm"]}
                                            <span className="separator-line"></span>
                                            {chartAirPollutants["secteur"]["sox"]}
                                            <span className="separator-line"></span>
                                            {chartAirPollutants["secteur"]["nh3"]}
                                            <span className="separator-line"></span>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            )}
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {" "}
                                    Impacts économiques
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div ref="results" className="centered-row">
                                    {chartNbEmpl}
                                    {chartVAG}
                                    <span className="separator-line"></span>
                                    {chartInvestissement}
                                    {chartRetombeesFiscales}
                                    <span className="separator-line"></span>
                                    {chartFactureEnergetique}
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            );
        }

        let scenarii = "";
        let displayListScenarii = false; // to display or not the scenarii list
        if (this.props.fromMenu) {
            displayListScenarii = true;
            results = ""; // Erase charts
        }

        let description = this.state.description;
        let titre = this.state.titre;

        scenarii = (
            <PlanActionsScenarii
                parentApi={this.props.parentApi}
                listeDesActions={this.state.listeDesActions}
                fichierDonnees={this.state.fichierDonnees}
                chargementFichier={this.state.chargementFichier}
                chargerParametresStrategies={this.chargerParametresStrategies}
                actions={this.state.actions}
                coeffs={this.state.coeffs}
                coeffsAdvanced={this.state.coeffsAdvanced}
                trajectoiresCibles={this.trajectoires}
                trajectoiresCiblesReference={this.state.referencesTrajectoiresCibles}
                loadParams={this.loadParams}
                description={description}
                titre={titre}
                displayListScenarii={displayListScenarii}
                planActions={this}
                texteIntro={this.props.fromMenu}
            />
        );

        // Methodo
        let methodoPdf = "";
        let pdfUrl = createPdfMethodoLink(
            config.methodo_url,
            this.props.parentApi.data.region,
            configData.methodoPlanActionsPdf
        );
        methodoPdf = (
            <a href={pdfUrl} target="_blank" rel="noreferrer">
                <div className="help"></div>
            </a>
        );

        let classTheme = this.props.parentApi.data.theme;

        let nomTerritoire = "";
        if (!this.props.fromMenu) {
            if (
                this.props.parentApi.data.zone.zone === "region" &&
                !this.props.parentApi.data.settings["is_national"]
            ) {
                nomTerritoire = this.props.parentApi.data.regionLabel;
            } else if (this.props.parentApi.data.settings["is_national"]) {
                nomTerritoire =
                    this.props.parentApi.data.region.charAt(0).toUpperCase() +
                    this.props.parentApi.data.region.slice(1);
            } else {
                nomTerritoire =
                    this.props.parentApi.controller.zonesManager.getZoneName(
                        this.props.parentApi.data.currentZone,
                        this.props.parentApi.data.zone
                    );
            }
        }
        let returnButton = (
            <button
                type="button"
                className="close close-big"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => this.avertirQuandRetourALaCarte()}
            >
                <span aria-hidden="true">&times;</span>
            </button>
        );
        if (this.props.parentApi.data.settings["is_national"]) {
            returnButton = (
                <div className="btn btn-light return-button">
                    <Link
                        to={
                            "/national/" + this.props.parentApi.data.region + "/portail"
                        }
                    >
                        <i className="bi bi-arrow-left"></i>
                    </Link>
                </div>
            );
        }
        return (
            <div className="plan-actions widgets full-screen-widget">
                {returnButton}
                {methodoPdf}
                <div className={"page-content " + classTheme}>
                    <div className="title centered-row">
                        <label className="action-title">
                            Construction de la stratégie territoriale
                        </label>
                        <label className="action-title">
                            Plans d’actions et impacts
                        </label>
                        <label className="territorialsynthesis-territoire">
                            {" "}
                            Territoire : {nomTerritoire}
                        </label>
                    </div>
                    {actionsWidget}
                    {scenarii}
                    {results}
                </div>
                <div className="footer-charts"></div>
                {this.state.warningDisabledActions}
            </div>
        );
    }
}

export default PlanActions;
