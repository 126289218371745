const qwertyChars = [
    ...["", "", "", "", "", "", "", "", "", "", "", "", "", "\n", "", ""], // 0-15
    ...["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""], // 16-31
    ...[" ", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""], // 32-47
    ...["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "", ";", "", "=", "", ""], // 48-63
    ...["", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o"], // 64-79
    ...["p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "", "", "", "", ""], // 80-95
    ...["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "+", "", "_", "", "/"], // 96-111
];
const qwertyShiftChars = [
    ...["", "", "", "", "", "", "", "", "", "", "", "", "", "\n", "", ""], // 0-15
    ...["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""], // 16-31
    ...[" ", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""], // 32-47
    ...[")", "!", "@", "#", "$", "%", "^", "&", "*", "(", "", ":", "", "+", "", ""], // 48-63
    ...["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"], // 64-79
    ...["P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "", "", "", "", ""], // 80-95
    ...["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "+", "", "_", "", "/"], // 96-111
];
const azertyChars = [
    ...["", "", "", "", "", "", "", "", "", "", "", "", "", "\n", "", ""], // 0-15
    ...["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""], // 16-31
    ...[" ", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""], // 32-47
    ...["à", "&", "é", '"', "'", "(", "-", "è", "_", "ç", "", ";", "", "=", "", ""], // 48-63
    ...["", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o"], // 64-79
    ...["p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "", "", "", "", ""], // 80-95
    ...["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "+", "", "_", "", "/"], // 96-111
];
const azertyShiftChars = [
    ...["", "", "", "", "", "", "", "", "", "", "", "", "", "\n", "", ""], // 0-15
    ...["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""], // 16-31
    ...[" ", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""], // 32-47
    ...["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "", ".", "", "+", "", ""], // 48-63
    ...["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"], // 64-79
    ...["P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "", "", "", "", ""], // 80-95
    ...["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "+", "", "_", "", "/"], // 96-111
];

export default function getCharFromKeyCode(keyCode, shiftPressed, language) {
    if (language.startsWith("fr")) {
        if (shiftPressed) {
            return azertyShiftChars[keyCode] ?? "";
        }
        return azertyChars[keyCode] ?? "";
    }
    if (shiftPressed) {
        return qwertyShiftChars[keyCode] ?? "";
    }
    return qwertyChars[keyCode] ?? "";
}
