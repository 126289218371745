/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";

import configData from "../../settings_data";
import { ReactGrid } from "@silevis/reactgrid";

export default function Trajectories({
    strategy,
    trajectoriesMeta,
    refTrajectories,
    trajectories,
    setTrajectories,
}) {
    const [isOpen, setIsOpen] = useState({});

    const setUserTrajectory = useCallback(
        (trajId, { annees_modifiees, annees_valeurs }) =>
            setTrajectories({
                ...trajectories,
                [trajId]: {
                    annees_valeurs: linearInterpolation(
                        annees_valeurs,
                        annees_modifiees
                    ),
                    annees_modifiees,
                },
            }),
        [trajectories, setTrajectories]
    );

    return (
        <div>
            <p>Comment remplir ma trajectoire ?</p>
            <p>
                Vous pouvez dessiner des trajectoires en saississant des valeurs dans le
                tableau
            </p>
            {/*<ul>
                <li>en déplaçant le point sur le graphique</li>
                <li>
                    ou en saississant des valeurs ou des évolutions (%) dans le tableau
                </li>
            </ul>*/}
            {!trajectoriesMeta?.length ? (
                <p>Chargement en cours...</p>
            ) : (
                trajectoriesMeta.map((meta) => (
                    <details
                        key={meta.id}
                        className="tsdetails"
                        onToggle={(e) =>
                            setIsOpen((prev) => ({ ...prev, [meta.id]: e.target.open }))
                        }
                    >
                        <summary>
                            {meta.name} {meta.unit && `(${meta.unit})`}
                        </summary>
                        {strategy?.referenceYear && isOpen[meta.id] ? (
                            <Trajectory
                                referenceYear={strategy.referenceYear}
                                meta={meta}
                                refTrajectories={refTrajectories?.[meta.id]}
                                userTrajectory={trajectories?.[meta.id]}
                                setUserTrajectory={setUserTrajectory}
                            />
                        ) : null}
                    </details>
                ))
            )}
        </div>
    );
}

function Trajectory({
    referenceYear,
    meta,
    refTrajectories,
    userTrajectory,
    setUserTrajectory,
}) {
    const years = useMemo(() => {
        const years = [];
        for (let y = referenceYear; y <= configData.planActionDerniereAnnee; y++) {
            years.push(y);
        }
        return years;
    }, [referenceYear]);

    useEffect(() => {
        if (
            (userTrajectory?.annees_valeurs &&
                Object.keys(userTrajectory.annees_valeurs).length) ||
            !refTrajectories?.historical_data?.length
        )
            return;
        const referenceValue = refTrajectories.historical_data
            .map(
                ({ data }) => data.find(({ annee }) => annee === years[0])?.valeur ?? 0
            )
            .reduce((a, b) => a + b, 0);
        const values = { [years[0]]: referenceValue };
        const modified = Object.fromEntries(
            years.map((year, index) => ["" + year, index === 0])
        );
        if (refTrajectories?.pcaet_trajectory?.length) {
            // If there is a PCAET, use it as the default trajectory
            const yearsList = refTrajectories.pcaet_trajectory[0].data.map(
                ({ annee }) => annee
            );
            let lastValue = referenceValue;
            for (const year of yearsList) {
                if (year <= years[0]) continue;
                // Sum all categories corresponding to current year to get total value
                const pcaetValue = refTrajectories.pcaet_trajectory
                    .map(
                        ({ data }) =>
                            data.find(({ annee }) => annee === year)?.valeur ?? 0
                    )
                    .reduce((a, b) => a + b, 0);
                // Production is always ascending, other indicators are always descending
                const minOrMax = meta.id === "enr_production" ? Math.max : Math.min;
                lastValue = values[year] = minOrMax(lastValue, pcaetValue);
                modified[year] = true;
            }
        }
        setUserTrajectory(meta.id, {
            annees_valeurs: values,
            annees_modifiees: modified,
        });
    }, [userTrajectory, refTrajectories, years, setUserTrajectory, meta.id]);

    // TABLE CONFIGURATION
    const tableColumns = [
        { columnId: "header", width: 300 },
        ...years.map((year) => ({ columnId: `${year}`, width: 60 })),
    ];
    const tableRows = [
        {
            rowId: "years",
            cells: [
                { type: "header", text: "" },
                ...years.map((year) => ({ type: "header", text: `${year}` })),
            ],
        },
    ];
    const numberFormat = Intl.NumberFormat(undefined, { maximumSignificantDigits: 4 });
    if (userTrajectory?.annees_valeurs) {
        tableRows.push({
            rowId: "values",
            cells: [
                { type: "header", text: `${meta.name} (${meta.unit})` },
                ...years.map((year, index) => ({
                    type: "text",
                    text: numberFormat.format(
                        parseFloat(userTrajectory.annees_valeurs[year])
                    ),
                    className: userTrajectory.annees_modifiees[year]
                        ? ""
                        : "cell-placeholder",
                    nonEditable: index === 0,
                })),
            ],
        });
    }
    const handleTableChange = (changes) => {
        let values = { ...userTrajectory.annees_valeurs };
        let modified = { ...userTrajectory.annees_modifiees };
        changes.forEach(({ rowId, columnId, newCell }) => {
            if (newCell.text == null || newCell.text === "") {
                modified[columnId] = false;
            } else if (rowId === "values") {
                let value = parseFloat(
                    newCell.text.replace(/\s/, "").replace(",", ".")
                );
                if (isNaN(value) || value < 0) return 0;
                values[columnId] = value;
                modified[columnId] = true;
            } // TODO: else if (rowId === "variations")
        });

        setUserTrajectory(meta.id, {
            annees_modifiees: modified,
            annees_valeurs: values,
        });
    };

    // CHART CONFIGURATION
    const chartData = { datasets: [] };
    const annotations = [];
    if (refTrajectories?.historical_data?.length) {
        const yearsList = refTrajectories.historical_data[0].data.map(
            ({ annee }) => annee
        );
        const dataSet = yearsList.map((year) => ({
            x: new Date(year + "-01-01"),
            // Sum all categories corresponding to current year to get total value
            y: refTrajectories.historical_data
                .map(
                    ({ data }) => data.find(({ annee }) => annee === year)?.valeur ?? 0
                )
                .reduce((a, b) => a + b, 0),
        }));

        chartData.datasets.push({
            borderColor: "#000",
            backgroundColor: "#000",
            pointStyle: "circle",
            label: "Historique",
            pointRadius: 3,
            borderWidth: 2,
            order: 1,
            data: dataSet,
            labels: yearsList,
        });

        annotations.push({
            type: "line",
            mode: "vertical",
            scaleID: "x",
            value: new Date(referenceYear + "-01-01"),
            borderColor: "red",
            label: {
                position: "end",
                xAdjust: -50,
                backgroundColor: "rgba(0,0,0,0.7)",
                content: "Historique",
                enabled: true,
            },
        });
        annotations.push({
            type: "line",
            mode: "vertical",
            scaleID: "x",
            value: new Date(referenceYear + "-01-01"),
            borderColor: "red",
            label: {
                position: "end",
                xAdjust: 50,
                backgroundColor: "rgba(0,0,0,0.7)",
                content: "Projection",
                enabled: true,
            },
        });
    }

    if (
        userTrajectory?.annees_valeurs &&
        Object.keys(userTrajectory.annees_valeurs).length
    ) {
        const { annees_valeurs, annees_modifiees } = userTrajectory;

        const dataSet = Object.entries(annees_valeurs).map(([year, value]) => ({
            x: new Date(year + "-01-01"),
            y: value,
        }));
        chartData.datasets.push({
            borderColor: "#666",
            backgroundColor: "#666",
            pointStyle: "circle",
            label: "Trajectoire cible",
            pointRadius: Object.values(annees_modifiees).map((m) => (m ? 5 : 3)),
            borderWidth: 2,
            order: 0,
            data: dataSet,
            labels: Object.keys(annees_valeurs),
        });
    }

    if (refTrajectories?.pcaet_trajectory?.length) {
        const yearsList = refTrajectories.pcaet_trajectory[0].data.map(
            ({ annee }) => annee
        );
        const dataSet = yearsList.map((year) => ({
            x: new Date(year + "-01-01"),
            // Sum all categories corresponding to current year to get total value
            y: refTrajectories.pcaet_trajectory
                .map(
                    ({ data }) => data.find(({ annee }) => annee === year)?.valeur ?? 0
                )
                .reduce((a, b) => a + b, 0),
        }));

        chartData.datasets.push({
            borderColor: "#0dcaf0",
            backgroundColor: "#0dcaf0",
            pointStyle: "circle",
            label: "Trajectoire PCAET",
            borderDash: [5, 5],
            pointRadius: 3,
            borderWidth: 2,
            order: -1,
            data: dataSet,
            labels: yearsList,
        });
    }

    if (refTrajectories?.supra_goals) {
        for (const supraGoal of refTrajectories.supra_goals) {
            const dataSet = [
                {
                    x: new Date(supraGoal.annee_reference + "-01-01"),
                    y: supraGoal.valeur_reference,
                },
            ];
            dataSet.push(
                ...supraGoal.valeurs_annees.map(({ annee, valeur }) => ({
                    x: new Date(parseInt(annee, 10) + "-01-01"),
                    y: parseFloat(supraGoal.valeur_reference * (1 + valeur / 100)),
                }))
            );
            chartData.datasets.push({
                borderColor: supraGoal.couleur,
                backgroundColor: supraGoal.couleur,
                pointStyle: "circle",
                label: supraGoal.titre,
                borderDash: [5, 5],
                pointRadius: 1,
                borderWidth: 2,
                order: -2,
                data: dataSet,
                labels: supraGoal.valeurs_annees.map(({ annee }) => annee),
            });
        }
    }

    const chartOptions = {
        scales: {
            x: {
                type: "time",
                title: {
                    text: "Années",
                    font: { size: 16 },
                },
                time: { unit: "year" },
                ticks: {
                    minRotation: 65,
                    source: "data",
                },
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    font: { size: 16 },
                    usePointStyle: true,
                },
                reverse: true,
            },
            tooltip: {
                reverse: true,
                callbacks: {
                    title: function (data) {
                        return new Date(data[0].label).getFullYear();
                    },
                    label: function (data) {
                        return (
                            data.raw.y.toLocaleString(undefined, {
                                maximumSignificantDigits: 4,
                            }) +
                            " " +
                            meta.unit
                        );
                    },
                },
            },
            annotation: { annotations },
        },
        width: 1200,
        height: 600,
    };

    // RENDER
    if (
        userTrajectory?.annees_valeurs &&
        Object.keys(userTrajectory.annees_valeurs).length === 0
    ) {
        return <p>Aucune donnée disponible pour cette trajectoire.</p>;
    } else if (
        !userTrajectory?.annees_valeurs ||
        !Object.keys(userTrajectory.annees_valeurs).length
    ) {
        return <p>Chargement en cours...</p>;
    }
    return (
        <div className="trajectory">
            <div className="scrollable-grid-container">
                <ReactGrid
                    rows={tableRows}
                    columns={tableColumns}
                    onCellsChanged={handleTableChange}
                    enableFillHandle
                    enableRangeSelection
                />
            </div>
            <Line
                data={chartData}
                options={chartOptions}
                width={chartOptions.width}
                height={chartOptions.height}
            />
        </div>
    );
}

/**
 * Interpolate given values depending on a "known" boolean dictionnary.
 *
 * @param {{[key: number]: number}} values Dictionnary of preexisting values depending
 * on the year
 * @param {{[key: number]: boolean}} isKnown Dictionnary containing true for years where
 * values are known and false for years that need to be interpolated
 * @param {boolean} inPlace By default, the function modify the values dict. Set to
 * false to return a copy
 */
function linearInterpolation(values, isKnown, inPlace = true) {
    if (!inPlace) values = { ...values };
    const allYears = Object.keys(isKnown).map((year) => parseInt(year, 10));
    allYears.sort((a, b) => a - b); // Shouldn't be necessary in our use case
    const knownYears = allYears.filter((year) => isKnown[year]);
    if (knownYears.length === 0) {
        throw Error(
            `isKnown should have at least one true value. Got ${JSON.stringify(
                isKnown
            )}`
        );
    }
    for (const year of allYears) {
        if (isKnown[year]) continue;

        // Find where in the known data, the values to interpolate would be inserted
        const prevKnownYear = [...knownYears].reverse().find((y) => y < year);
        const nextKnownYear = knownYears.find((y) => y >= year);

        // Special cases outside the known region
        if (prevKnownYear === undefined) {
            values[year] = values[nextKnownYear];
            continue;
        }
        if (nextKnownYear === undefined) {
            values[year] = values[prevKnownYear];
            continue;
        }

        // Calculate the slope of the interval in which the current year is
        const prevKnownVal = values[prevKnownYear];
        const nextKnownVal = values[nextKnownYear];
        const slope = (nextKnownVal - prevKnownVal) / (nextKnownYear - prevKnownYear);

        // Calculate the actual value at the current year
        values[year] = slope * (year - prevKnownYear) + prevKnownVal;
    }
    return values;
}
