/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

import NotFound from "../NotFound.js";
import config from "../settings.js";

import RegionalAbout from "../Components/StaticPages/RegionalAbout.js";
import Contact from "../Components/StaticPages/Contact.js";
import LegalInformation from "../Components/StaticPages/LegalInformation.js";
import Newsletter from "../Components/StaticPages/Newsletter.js";
import OpenSource from "../Components/StaticPages/OpenSource.js";
import RegionalFooter from "../Components/StaticPages/RegionalFooter.js";
import Support from "../Components/StaticPages/Support.js";

import MainMap from "../Components/Map/MainMap.js";
import Filters from "../Components/Filters.js";
import Infos from "../Components/Map/Infos.js";
import Legend from "../Components/Map/Legend.js";
import ChartsContainer from "../Components/Map/ChartsContainer.js";

import PlanActions from "../Components/PlanActions.js";
import Strategies from "../Components/TerritorialStrategies/Strategies";
import StrategyEdition from "../Components/TerritorialStrategies/StrategyEdition.js";
import TerritorialSynthesis from "../Components/TerritorialSynthesis.js";
import SuiviEnergetique from "../Components/SuiviEnergetique.js";
import DashboardEdition from "../Components/DashboardEdition.js";
import DashboardList from "../Components/DashboardList.js";
import DashboardShow from "../Components/DashboardShow.js";
import Profile from "../Components/Profile.js";
import Subscribe from "../Components/Subscribe.js";
import SplashScreen from "../Components/SplashScreen.js";
import Header from "../Components/Header.js";
import Messages from "../Components/Messages.js";
import Reset from "../Components/Map/Reset.js";
import SuiviEmissionGes from "../Components/SuiviEmissionGes.js";
import ErrorPage from "../Components/ErrorPage.js";
import Sankey from "../Components/Sankey.js";
import SuiviPolluants from "../Components/SuiviPolluants.js";
import DidacticFile from "../Components/DidacticFile.js";
import Simulator from "../Components/Simulator/Simulator.js";

/**
 * Router describing the routes for the regional pages.
 */
function RegionalRouter({
    fromMenu,
    settings,
    parentApi,
    territoireSelectionne,
    connected,
    analysisManagerFailed,
    splashScreen,
    analysisMeta,
    dataLoaded,
    currentDidacticFile,
    analysisManager,
    tableauBordDonnees,
    tableauBordCourant,
    analysis,
    olCallback,
}) {
    // On test également si la région est précisée dans la première partie de l'url (arec-nouvelleaquitaine. ou auvergnerhonealpes.)
    if (analysisManagerFailed) {
        return (
            <ErrorPage message={"la connexion avec l'API semble ne plus fonctionner"} />
        );
    }

    let appli = "";
    const regionEnabled = settings.enabled;
    if (settings) {
        appli = (
            <div
                className="main"
                data-theme={parentApi.data.theme}
                data-color-mode="light" // MDEditor switch to light/dark theme depending on browser preference. We override this theme to keep consistency throughout the application.
            >
                {splashScreen && !config.HIDE_SPLASH_SCREEN && (
                    <SplashScreen parentApi={parentApi} />
                )}
                <Messages parentApi={parentApi} />
                <Route
                    render={(props) => (
                        <Header
                            {...props}
                            parentApi={parentApi}
                            dataLoaded={dataLoaded}
                            ui_show_login={settings.ui_show_login}
                        />
                    )}
                />
                <Switch>
                    {regionEnabled && (
                        <>
                            <Route
                                exact
                                path="/profile"
                                render={(props) => (
                                    <Profile
                                        {...props}
                                        connected={connected}
                                        parentApi={parentApi}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/inscription"
                                render={(props) => (
                                    <Subscribe {...props} parentApi={parentApi} />
                                )}
                            />
                            <Route
                                exact
                                path="/contact"
                                render={(props) => (
                                    <Contact {...props} parentApi={parentApi} />
                                )}
                            />
                            {(settings.enable_newsletter_national ||
                                settings.enable_newsletter_regional) && (
                                <Route
                                    path="/newsletter"
                                    render={(props) => (
                                        <Newsletter {...props} parentApi={parentApi} />
                                    )}
                                />
                            )}
                            {
                                // For some regions the "/support" page is not available and replaced by a PDF file
                                settings.lien_faq !== "faq.pdf" && (
                                    <Route
                                        exact
                                        path="/support"
                                        render={(props) => (
                                            <Support {...props} parentApi={parentApi} />
                                        )}
                                    />
                                )
                            }
                            <Route
                                exact
                                path="/a_propos"
                                render={(props) => (
                                    <RegionalAbout {...props} parentApi={parentApi} />
                                )}
                            />
                            {settings.ui_show_plan_actions &&
                                config.enable_old_strategy_module && (
                                    <Route
                                        exact
                                        path="/strategie_territoriale"
                                        render={(props) => (
                                            <PlanActions
                                                {...props}
                                                parentApi={parentApi}
                                                fromMenu={fromMenu}
                                            />
                                        )}
                                    />
                                )}
                            {settings.ui_show_plan_actions && (
                                <Route
                                    exact
                                    path="/strategies_territoriales"
                                    render={(props) => (
                                        <Strategies
                                            {...props}
                                            parentApi={parentApi}
                                            fromMenu={fromMenu}
                                        />
                                    )}
                                />
                            )}
                            {settings.ui_show_plan_actions && (
                                <Route
                                    path="/edition_strategie"
                                    render={(props) => (
                                        <StrategyEdition
                                            {...props}
                                            parentApi={parentApi}
                                            fromMenu={fromMenu}
                                        />
                                    )}
                                />
                            )}
                            <Route
                                exact
                                path="/mentions_legales_cgu"
                                render={(props) => (
                                    <LegalInformation
                                        {...props}
                                        parentApi={parentApi}
                                        fromMenu={fromMenu}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/open_source"
                                render={(props) => (
                                    <OpenSource
                                        {...props}
                                        parentApi={parentApi}
                                        fromMenu={fromMenu}
                                    />
                                )}
                            />
                            {settings.ui_show_plan_actions && (
                                <Route
                                    exact
                                    path="/fiche_didactique"
                                    render={(props) => (
                                        <DidacticFile
                                            {...props}
                                            parentApi={parentApi}
                                            fromMenu={fromMenu}
                                            currentDashboard={tableauBordCourant}
                                            currentDidacticFile={currentDidacticFile}
                                        />
                                    )}
                                />
                            )}
                            {settings.ui_show_plan_actions && (
                                <Route
                                    exact
                                    path="/simulateur"
                                    render={(props) => (
                                        <Simulator
                                            {...props}
                                            parentApi={parentApi}
                                            fromMenu={fromMenu}
                                            currentDashboard={tableauBordCourant}
                                        />
                                    )}
                                />
                            )}
                            <Route
                                exact
                                path="/creation_tableaux_bord"
                                render={(props) => (
                                    <DashboardEdition
                                        {...props}
                                        parentApi={parentApi}
                                        connected={connected}
                                        fromMenu={fromMenu}
                                        analysis={analysis}
                                        analysisManager={analysisManager}
                                        tableauBordDonnees={tableauBordDonnees}
                                        territoireSelectionne={territoireSelectionne}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/gestion_tableaux_bord"
                                render={(props) => (
                                    <DashboardList
                                        {...props}
                                        parentApi={parentApi}
                                        connected={connected}
                                        fromMenu={fromMenu}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/restitution_tableaux_bord"
                                render={(props) => (
                                    <DashboardShow
                                        {...props}
                                        parentApi={parentApi}
                                        tableauBordCourant={tableauBordCourant}
                                    />
                                )}
                            />
                            {settings.ui_show_analyse && (
                                <Route
                                    exact
                                    path="/synthese_territoriale"
                                    render={(props) => (
                                        <TerritorialSynthesis
                                            {...props}
                                            parentApi={parentApi}
                                            fromMenu={fromMenu}
                                        />
                                    )}
                                />
                            )}
                            {settings.ui_show_analyse && (
                                <Route
                                    exact
                                    path="/suivi_energetique"
                                    render={(props) => (
                                        <SuiviEnergetique
                                            {...props}
                                            parentApi={parentApi}
                                            fromMenu={fromMenu}
                                        />
                                    )}
                                />
                            )}
                            {settings.ui_show_analyse && (
                                <Route
                                    exact
                                    path="/suivi_emission_ges"
                                    render={(props) => (
                                        <SuiviEmissionGes
                                            {...props}
                                            parentApi={parentApi}
                                            fromMenu={fromMenu}
                                        />
                                    )}
                                />
                            )}
                            {settings.ui_show_analyse && settings.ui_show_sankey && (
                                <Route
                                    exact
                                    path="/diagramme_sankey"
                                    render={(props) => (
                                        <Sankey
                                            {...props}
                                            parentApi={parentApi}
                                            fromMenu={fromMenu}
                                        />
                                    )}
                                />
                            )}
                            {settings.ui_show_analyse && (
                                <Route
                                    exact
                                    path={[
                                        "/suivi_polluants_covnm",
                                        "/suivi_polluants_nh3",
                                        "/suivi_polluants_nox",
                                        "/suivi_polluants_pm10",
                                        "/suivi_polluants_pm25",
                                        "/suivi_polluants_so2",
                                    ]}
                                    render={(props) => (
                                        <SuiviPolluants
                                            {...props}
                                            parentApi={parentApi}
                                            fromMenu={fromMenu}
                                        />
                                    )}
                                />
                            )}
                            <Route exact path="/">
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => (
                                        <MainMap
                                            {...props}
                                            parentApi={parentApi}
                                            initialMapCenter={settings.map_init_center}
                                            initialMapZoom={settings.map_init_zoom}
                                            onRef={(ref) => olCallback(ref)}
                                        />
                                    )}
                                />

                                <Route
                                    exact
                                    path="/"
                                    render={(props) => (
                                        <Filters
                                            {...props}
                                            parentApi={parentApi}
                                            territoireSelectionne={
                                                territoireSelectionne
                                            }
                                        />
                                    )}
                                />

                                <Route
                                    exact
                                    path="/"
                                    render={(props) => (
                                        <Reset {...props} parentApi={parentApi} />
                                    )}
                                />
                                <div className="widgets map-widgets">
                                    <Route
                                        exact
                                        path="/"
                                        render={(props) => (
                                            <Infos {...props} parentApi={parentApi} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/"
                                        render={(props) => (
                                            <Legend
                                                {...props}
                                                parentApi={parentApi}
                                                analysisMeta={analysisMeta}
                                            />
                                        )}
                                    />
                                </div>
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => (
                                        <ChartsContainer
                                            {...props}
                                            parentApi={parentApi}
                                            analysis={analysis}
                                            analysisManager={analysisManager}
                                        />
                                    )}
                                />
                            </Route>{" "}
                        </>
                    )}
                    <Route path="*">
                        <NotFound />{" "}
                    </Route>{" "}
                </Switch>
                <Route
                    render={(props) => (
                        <RegionalFooter {...props} parentApi={parentApi} />
                    )}
                />
            </div>
        );
        return appli;
    }

    return <></>;
}

export default RegionalRouter;
