/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import html2canvas from "html2canvas";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "../style/dashboard_print.css";

import Api from "../Controllers/Api";
import config from "../settings.js";
import configData from "../settings_data.js";
import {
    saveAsPng,
    createPdfMethodoLink,
    buildRegionUrl,
    consulteTableauDeBord,
    slugify,
} from "../utils.js";

import FabriqueRepresentation from "./FabriqueRepresentation.js";
import TextBlock from "./Graphs/TextBlock.js";
import { AnalysisUnitSelection } from "./Map/Infos.js";
import { ZoneSelect } from "./SelectionObjet.js";
import { ExportIndicatorButton } from "./ExportButton";
import SEO from "./SEO";

// import 'react-table/react-table.css'

/**
 * Ce composant permet d'afficher un tableau de bord spécifique.
 */
class DashboardShow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableauBordDonnees: undefined,
            analyses: props.parentApi.controller.analysisManager.analysis, // Liste des analyses
            id_tableau_bord: props.tableauBordCourant,
            donneesDuTableauDeBordARestituer: false,
            displayPdfVersion: false, // if we display the header and footer for the PDF export
            isLoadingPDF: false,
            displayTerritoriesChoice: false,
            territoryInChoice: undefined,
            otherTerritories: [],
            selectedUnits: {},
            isGettingData: false,
            dataReferencement: undefined,
        };
    }

    componentDidMount() {
        if (this.props.tableauBordCourant) {
            this.trouverTableauBordARestituer();
            this.props.parentApi.callbacks.updateProvenance("tableau_de_bord_restitue");
        }
        const givenCTerritories = this.getComparedTerritoriesFromUrl();

        if (givenCTerritories) {
            const otherTerritories = [];
            for (const key in givenCTerritories) {
                if (Object.hasOwnProperty.call(givenCTerritories, key)) {
                    const territory = givenCTerritories[key];
                    const currentTerritory = {
                        zoneId: territory.zoneid,
                        zone: {
                            zone: territory.zone,
                            maille: territory.maille,
                        },
                    };
                    if (
                        !otherTerritories.includes(currentTerritory) &&
                        (this.props.parentApi.data.currentZone !== territory.zoneid ||
                            this.props.parentApi.data.zone.zone !== territory.zone ||
                            this.props.parentApi.data.zone.maille !== territory.maille)
                    ) {
                        otherTerritories.push(currentTerritory);
                    }
                }
            }
            this.setState({
                otherTerritories,
            });
        }
    }

    /**
     *   Cette fonction est appelé lorsque l'état  du composant principal change
     *   elle permet d'appeler la fonction saveAsPDF() et de réinitialiser displayPdfVersion
     *   @param prevState: propriétés du composant principal avant le changement
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.state.displayPdfVersion && !prevState.displayPdfVersion) {
            this.saveAsPdf();
        }
        if (
            this.props.parentApi.data.tableauBordCourant &&
            this.props.parentApi.controller.gestionSuiviConsultations &&
            this.props.parentApi.controller.dashboardManager &&
            !this.state.tableauBordCharge
        ) {
            if (
                this.props.parentApi.controller.dashboardManager.listMyDashboards !==
                    undefined &&
                this.props.parentApi.controller.dashboardManager
                    .listPublicDashboards !== undefined
            ) {
                let typeTerritoire = "?zone=" + this.props.parentApi.data.zone.zone;
                let maille = "&maille=" + this.props.parentApi.data.zone.maille;
                let codeInseeTerritoire =
                    "&zone_id=" + this.props.parentApi.data.currentZone;
                let tableauBordCourant =
                    "&id_tableau=" + this.props.parentApi.data.tableauBordCourant;
                let nomTerritoire =
                    "&nom_territoire=" +
                    encodeURI(
                        this.props.parentApi.controller.zonesManager.getZoneName(
                            this.props.parentApi.data.currentZone,
                            this.props.parentApi.data.zone
                        )
                    );
                if (
                    !this.props.parentApi.controller.zonesManager.getZoneName(
                        this.props.parentApi.data.currentZone,
                        this.props.parentApi.data.zone
                    )
                ) {
                    nomTerritoire =
                        "&nom_territoire=" +
                        encodeURI(this.props.parentApi.data.nomTerritoire);
                }
                if (this.props.parentApi.data.zone.zone === "region") {
                    codeInseeTerritoire =
                        "&zone_id=" + this.props.parentApi.data.regionCode;
                    nomTerritoire =
                        "&nom_territoire=" + this.props.parentApi.data.settings.label;
                }

                let url =
                    typeTerritoire +
                    maille +
                    codeInseeTerritoire +
                    tableauBordCourant +
                    nomTerritoire +
                    this.getComparedTerritoriesToUrl();
                this.props.parentApi.callbacks.mettreAJourParametresUrls(url);
                this.props.parentApi.callbacks.updateAnalysis("");
                this.trouverTableauBordARestituer();
                this.setState({
                    tableauBordCharge: true,
                });
            }
        }
    }

    /**
     * This function returns the url to an analysis or a group of POI
     */
    builtPoiOrAnalysisUrl(
        currentZoneType,
        currentZoneId,
        currentMaille,
        analysisOrPoiId,
        theme,
        isPoi = false
    ) {
        let typeTerritoire = "?zone=" + currentZoneType;
        let maille = "&maille=" + currentMaille;
        let uiTheme = "&theme=" + theme;
        let codeInseeTerritoire = "&zone_id=" + currentZoneId;
        let nomTerritoire =
            "&nom_territoire=" +
            encodeURI(
                this.props.parentApi.controller.zonesManager.getZoneName(
                    currentZoneId,
                    currentZoneType
                ) ?? this.props.parentApi.data.nomTerritoire
            );
        let poiOrAnalysis = "";
        if (isPoi) {
            poiOrAnalysis =
                "&installation=" +
                this.props.parentApi.controller.equipementsManager
                    .getEquipementsLayers()
                    .filter((layer) => layer.theme === analysisOrPoiId)
                    .map((layer) => layer.nom)
                    .join("-");
        } else {
            poiOrAnalysis = "&analysis=" + analysisOrPoiId;
        }
        let url =
            "/" +
            typeTerritoire +
            maille +
            codeInseeTerritoire +
            nomTerritoire +
            uiTheme +
            poiOrAnalysis +
            this.getComparedTerritoriesToUrl();

        return url;
    }

    /**
    Cette fonction retourne les données du tableau de bord sélectionné par l'utilisateur.rice
    parmi ses tableaux de bord si elle est connectée et qu'elle en a créé et les tableaux de
    bord disponibles pour le territoire sélectionné
    */
    trouverTableauBordARestituer() {
        if (this.state.isGettingData) {
            return;
        }
        this.setState({ isGettingData: true });
        let typeTerritoire = this.props.parentApi.data.zone.zone;
        let codeInseeTerritoire = this.props.parentApi.data.currentZone;
        let url =
            buildRegionUrl(config.tableau_bord_url, this.props.parentApi.data.region) +
            "/" +
            this.props.tableauBordCourant +
            "?zone=" +
            typeTerritoire +
            "&zone_id=" +
            codeInseeTerritoire;
        Api.callApi(url, null, "GET")
            .then((response) => {
                this.setState({
                    donneesDuTableauDeBordARestituer: response,
                    isGettingData: false,
                });
                let typeTerritoire = this.props.parentApi.data.zone.zone;
                let codeInseeTerritoire = this.props.parentApi.data.currentZone;
                let tableauBordCourant = this.props.tableauBordCourant;
                let region = this.props.parentApi.data.region;
                if (
                    this.props.parentApi.controller.gestionSuiviConsultations &&
                    this.props.parentApi.controller.gestionSuiviConsultations
                        .idUtilisateur
                ) {
                    let idUtilisateur =
                        this.props.parentApi.controller.gestionSuiviConsultations
                            .idUtilisateur;
                    let url = config.api_consultation_tableau_bord_url;
                    consulteTableauDeBord(
                        url,
                        region,
                        idUtilisateur,
                        tableauBordCourant,
                        codeInseeTerritoire,
                        typeTerritoire
                    );
                }
            })
            .catch((e) => {
                this.status = e;
                this.setState({ isGettingData: false });
            });
    }

    /**
     * Check we changed unit and, if new unit is valid (inside available units)
     * for specific graph, changes unit sent to FabriqueRepresentation.
     *
     * @param {integer} newUnit new value for selected unit
     */
    updateAnalysisSelectedUnit(graphId, newUnit, relevantUnits) {
        if (relevantUnits.filter((u) => u.unit_id === newUnit).length === 0) {
            return;
        }
        if (
            this.state.selectedUnits?.[graphId] === undefined ||
            newUnit !== this.state.selectedUnits[graphId]
        ) {
            this.setState({
                selectedUnits: {
                    ...this.state.selectedUnits,
                    [graphId]: newUnit,
                },
            });
        }
    }

    /**
    Cette fonction retourne la liste de toutes les lignes au sein desquelles se trouvent tous les indicateurs qui
    contiennent les différents graphiques (dans le cas où il y en a plusieurs). Il s'agit d'une liste d'éléments
    html et composants React que la méthode render restitue. Ils sont organisés de telle manière qu'on peut
    ensuite les mettre facilement en forme selon la structure que le ou la créateur.rice du tableau de bord a
    définie.

    On parcourt dans cette fonction les données d'un tableau de bord organisées dans un objet de la manière suivante
    {   id : identifiant du tableau de bord à restituer,
        titre : titre du tableau de bord à restituer,
        description : sa description, donnée au moment de sa création,
        data : date de sa création ou de sa dernière mise à jour.
        thematiques: [
            {
                id : identifiant de la thématique
                titre : titre
                identifiant_tableau : identifiant du tableau de bord
                graphiques : [
                        {
                            identifiant_analyse : identifiant de l'analyse
                            representation: représentation
                            categories {
                                première catégories {
                                    nom : nom de la catégorie (ex : secteurs, energies, usages, type_prod_enr etc.)
                                    titre : titre de la catégorie (ex : Par secteurs, Par filières de production etc.)
                                    visible : true si on doit l'afficher false sinon
                                }
                                ...
                            }
                        },
                        ...
                ]
            }
            ...
        ]
    }
    */
    getGraphsByGroupOneTerritory(currentZoneType, currentZoneId, currentMaille) {
        const { analysisManager } = this.props.parentApi.controller;

        // Liste de toutes les lignes du tableau de bord
        let listeDesLignes = this.state.donneesDuTableauDeBordARestituer.thematiques;
        if (!listeDesLignes) {
            return "";
        }
        let i = 0;
        let graphiquesParThematique = {}; // Cette liste contiendra tous les éléments html qui composent les lignes du tableau de bord
        for (let ligne of listeDesLignes) {
            let groupeGraphiques = []; // Cette liste correspond à une ligne au sein de laquelle on ajoutera tous les indicateurs qui la composent
            for (let graph of ligne.graphiques) {
                if (!graph.id_analysis) {
                    continue;
                }
                let annee = analysisManager.getAnalysisLastYear(
                    parseInt(graph.id_analysis, 10)
                );

                // we check, if we have an indicator that can be estimated,
                // if the year is estimated, we add (e) to the year
                let bottomLegend = undefined;
                if (
                    ![
                        "analysis-launcher",
                        "logo",
                        "didactic-file-launcher",
                        "courbes-historiques",
                        "link-launcher",
                        "text-block",
                    ].includes(graph.representation)
                ) {
                    const estimated_years = analysisManager.getAnalysisEstimatedYears(
                        parseInt(graph.id_analysis, 10)
                    );
                    if (estimated_years && estimated_years.includes(annee)) {
                        annee = annee + " (e)";
                        if (
                            graph.representation !== "line" &&
                            graph.representation !== "courbes_croisees"
                        ) {
                            bottomLegend = (
                                <p
                                    className="estimated-data-legend"
                                    style={{ color: "gray" }}
                                >
                                    (e) = données estimées
                                </p>
                            );
                        }
                    }
                }
                let theme = analysisManager.getAnalysisNameTheme(
                    parseInt(graph.id_analysis, 10)
                );
                let classeRepresentation = "";
                let heightJauge = "";
                let ref = React.createRef();
                if (graph.representation === "pie") {
                    classeRepresentation = " diagramme";
                } else if (
                    [
                        "analysis-launcher",
                        "logo",
                        "didactic-file-launcher",
                        "link-launcher",
                    ].includes(graph.representation)
                ) {
                    classeRepresentation = " analysis-launcher";
                } else if (graph.representation === "jauge-circulaire") {
                    heightJauge = "200px";
                }
                let analyse = this.state.analyses.find(
                    (analyse) => analyse.id === parseInt(graph.id_analysis, 10)
                );
                let nomComplet = "";
                let methodoPdf = "";
                if (
                    analyse &&
                    !["logo", "didactic-file-launcher", "link-launcher"].includes(
                        graph.representation
                    )
                ) {
                    nomComplet = "";
                    if (graph.representation !== "analysis-launcher") {
                        nomComplet = analyse.nom;
                        if (
                            annee &&
                            graph.representation !== "bar" &&
                            graph.representation !== "line" &&
                            graph.representation !== "bar-years" &&
                            graph.representation !== "line-years" &&
                            graph.representation !== "courbes_croisees"
                        ) {
                            nomComplet = analyse.nom + " (" + annee + ")";
                        }
                    }

                    let pdfName = analysisManager.getAnalysisMethodoPdf(
                        parseInt(analyse.id, 10)
                    );
                    if (pdfName) {
                        let pdfUrl = createPdfMethodoLink(
                            config.methodo_url,
                            this.props.parentApi.data.region,
                            pdfName
                        );
                        methodoPdf = (
                            <a href={pdfUrl} target="_blank" rel="noreferrer">
                                <div className="pdf"></div>
                            </a>
                        );
                    }
                } else if (graph.representation === "poi_map") {
                    nomComplet = "Carte des " + graph.id_analysis;
                } else if (graph.representation?.startsWith("pcaet-trajectory")) {
                    nomComplet =
                        "Suivi de la trajectoire PCAET - " + graph.trajectoryName;
                } else if (
                    ![
                        "logo",
                        "didactic-file-launcher",
                        "link-launcher",
                        "text-block",
                    ].includes(graph.representation)
                ) {
                    nomComplet = "Indicateur désactivé";
                }

                let meta = analysisManager.getUnitsForAnalysis(graph.id_analysis);
                // we define a unique ID to save current selection for the graph
                const unitSelectionId =
                    currentZoneType +
                    "-" +
                    currentZoneId +
                    "-" +
                    currentMaille +
                    "-" +
                    graph.id_analysis +
                    "-" +
                    graph.numero_analyse;
                let unitId = this.state.selectedUnits?.[unitSelectionId];

                // Units
                const unitsParams = analysisManager.getUnitParamsForIndicator(
                    this.state.analysisSelectedUnit,
                    meta,
                    this.props.parentApi.data.zone.maille
                );
                const { relevantUnits, defaultUnitForThisZoneType } = unitsParams;

                const unitSelection = Object.keys(relevantUnits).length > 0 && (
                    <div className="unit-selection">
                        <label>
                            <b>Unité</b> :{" "}
                            <AnalysisUnitSelection
                                units={relevantUnits}
                                hiddenUnits={meta?.unitsHiddenDetails}
                                singleUnit={unitId}
                                zoneName={this.props.parentApi.data.zone.maille}
                                unitSelected={
                                    this.state.selectedUnits?.[unitSelectionId] ??
                                    defaultUnitForThisZoneType
                                }
                                onChangeCallback={(e) =>
                                    this.updateAnalysisSelectedUnit(
                                        unitSelectionId,
                                        e,
                                        relevantUnits
                                    )
                                }
                            />
                        </label>
                    </div>
                );

                // final graphs
                let graphiques = []; // Cette liste correspond à un indicateur qui contient tous les graphiques dont il est composé
                const indicatorId = parseInt(graph.id_analysis, 10);
                let uniqueZoneEnabled = analysisManager.getOnlyForZones(indicatorId);
                let disabledZones = analysisManager.getDisabledZones(indicatorId);
                let disabledMacroLevels =
                    analysisManager.getDisabledMacroLevels(indicatorId);
                const isDisabledInDashboard =
                    analysisManager.isDisabledInDashboard(indicatorId);
                let isLaunchable = true;
                let isPrintable = graph.representation !== "text-block";
                let isActive = analysisManager.isActive(indicatorId);

                if (!isActive) {
                    isLaunchable = false;
                }
                if (
                    graph.representation &&
                    graph.representation === "map" &&
                    uniqueZoneEnabled &&
                    !uniqueZoneEnabled.split(",").includes(currentMaille)
                ) {
                    isLaunchable = false;
                    groupeGraphiques.push(
                        <div
                            key={
                                graph.id_analysis +
                                "_" +
                                graph.representation +
                                "_" +
                                graph.numero_analyse
                            }
                            className="graphs graphs-non-affiches"
                        >
                            <h4 className="nom-indicateur">{nomComplet}</h4>
                            <div className={"confid-chart"}>
                                {"Cet indicateur n'est disponible qu'à la maille " +
                                    uniqueZoneEnabled}
                            </div>
                        </div>
                    );
                } else if (
                    graph.representation &&
                    graph.representation === "map" &&
                    disabledZones &&
                    disabledZones.split(",").includes(currentMaille)
                ) {
                    isLaunchable = false;
                    groupeGraphiques.push(
                        <div
                            key={
                                graph.id_analysis +
                                "_" +
                                graph.representation +
                                "_" +
                                graph.numero_analyse
                            }
                            className="graphs graphs-non-affiches"
                        >
                            <h4 className="nom-indicateur">{nomComplet}</h4>
                            <div className="confid-chart">
                                {"Cet indicateur n'est pas activé à la maille " +
                                    currentMaille}
                            </div>
                        </div>
                    );
                } else if (
                    disabledMacroLevels &&
                    disabledMacroLevels.split(",").includes(currentZoneType)
                ) {
                    isLaunchable = false;
                    groupeGraphiques.push(
                        <div
                            key={
                                graph.id_analysis +
                                "_" +
                                graph.representation +
                                "_" +
                                graph.numero_analyse
                            }
                            className="graphs graphs-non-affiches"
                        >
                            <h4 className="nom-indicateur">{nomComplet}</h4>
                            <div className="confid-chart">
                                {"Cet indicateur n'est pas disponible à l'échelle " +
                                    currentZoneType}
                            </div>
                        </div>
                    );
                } else if (isDisabledInDashboard) {
                    groupeGraphiques.push(
                        <div
                            key={
                                graph.id_analysis +
                                "_" +
                                graph.representation +
                                "_" +
                                graph.numero_analyse
                            }
                            className="graphs graphs-non-affiches"
                        >
                            <h4 className="nom-indicateur">{nomComplet}</h4>
                            <div className="confid-chart">
                                Cet indicateur a été désactivé des tableaux de bord.
                            </div>
                        </div>
                    );
                } else if (graph.id_analysis && !graph.representation) {
                    groupeGraphiques.push(
                        <div
                            key={
                                graph.id_analysis +
                                "_" +
                                graph.representation +
                                "_" +
                                graph.numero_analyse
                            }
                            className="graphs graphs-non-affiches"
                        >
                            <h4 className="nom-indicateur">{nomComplet}</h4>
                            <div className="confid-chart">
                                Aucune représentation n'a été sélectionnée lors de
                                l'enregistrement du tableau de bord
                            </div>
                        </div>
                    );
                } else if (graph.representation) {
                    const uniqueKey =
                        currentZoneType +
                        "-" +
                        currentZoneId +
                        "-" +
                        currentMaille +
                        "-" +
                        graph.numero_analyse;
                    if (
                        graph.categories &&
                        Object.keys(graph.categories).length !== 0
                    ) {
                        let atLeastOneVisible = false;
                        for (let categorie in graph.categories) {
                            if (
                                graph.categories[categorie].visible ||
                                Object.keys(graph.categories).length === 1
                            ) {
                                atLeastOneVisible = true;
                                let graphiquesRestitues = (
                                    <div
                                        key={
                                            graph.id_analysis +
                                            "_" +
                                            graph.representation +
                                            "_" +
                                            graph.numero_analyse +
                                            "_" +
                                            categorie
                                        }
                                        className="graphs"
                                    >
                                        <FabriqueRepresentation
                                            // FabriqueRepresentation instancie la représentation précisée dans la propriété representation
                                            // Par exemple, elle peut instancer DiagrammeCirculaire, CourbesEmpilees ou encore CourbesComparees
                                            parentApi={this.props.parentApi}
                                            zoneType={currentZoneType}
                                            zoneId={currentZoneId}
                                            zoneMaille={currentMaille}
                                            id_analysis={graph.id_analysis}
                                            representation={graph.representation}
                                            thematique={ligne.titre}
                                            id={uniqueKey}
                                            provenance="tableau_de_bord_restitue"
                                            type={{
                                                categorie: categorie,
                                                titre: graph.categories[categorie]
                                                    .titre,
                                            }}
                                            key={
                                                graph.id_analysis +
                                                "_" +
                                                graph.representation
                                            }
                                            width={450}
                                            height={400}
                                            tailleSVG={graph.tailleSVG}
                                            tailleData={graph.tailleData}
                                            image={graph.imageSVG}
                                            couleur={graph.couleur}
                                            nom={graph.nom}
                                            unit={unitId}
                                            filters={graph.filters}
                                            options={graph.options}
                                        />
                                    </div>
                                );
                                graphiques.push(graphiquesRestitues);
                            }
                        }
                        if (!atLeastOneVisible) {
                            graphiques.push(
                                <div
                                    key={
                                        graph.id_analysis +
                                        "_" +
                                        graph.representation +
                                        "_" +
                                        graph.numero_analyse +
                                        "_no_valid_cat"
                                    }
                                    className="graphs"
                                >
                                    Le graphique n'a aucune catégorie valide d'activée.
                                    Merci de signaler le problème aux administrateurs.
                                </div>
                            );
                        }
                        let exportButton = "";
                        if (
                            isActive &&
                            graph.representation !== "analysis-launcher" &&
                            analyse.donnees_exportables &&
                            analyse.donnees_exportables.indexOf(currentMaille) !== -1
                        ) {
                            let exportYears = undefined;
                            if (
                                [
                                    "pie",
                                    "radar",
                                    "jauge",
                                    "jauge-circulaire",
                                    "marqueur-svg",
                                    "map",
                                ].includes(graph.representation)
                            ) {
                                exportYears = "current";
                            }
                            exportButton = (
                                <ExportIndicatorButton
                                    parentApi={this.props.parentApi}
                                    analysisId={graph.id_analysis}
                                    exportYears={exportYears}
                                    zoneType={currentZoneType}
                                    zoneId={currentZoneId}
                                    zoneMaille={currentMaille}
                                />
                            );
                        }
                        groupeGraphiques.push(
                            <div
                                ref={ref}
                                className={
                                    "formulaire-thematique formulaire-thematique-restitue " +
                                    classeRepresentation +
                                    " graph-" +
                                    graph.representation +
                                    " group-" +
                                    groupeGraphiques.length
                                }
                                key={"indicateur_" + graph.numero_analyse}
                                style={{
                                    maxWidth: this.props.parentApi.callbacks.tailleDiv,
                                }}
                            >
                                <h4 className="nom-indicateur">{nomComplet}</h4>
                                {graph.representation !== "analysis-launcher" && (
                                    <div className="export-methodo-buttons">
                                        <div className="selection-graph bouton-methodo do-not-print">
                                            {isActive && (
                                                <button
                                                    type="button"
                                                    className="btn btn-info btn-save block-row bouton-flex liste-modalites-diagramme"
                                                    onClick={() =>
                                                        saveAsPng(ref, nomComplet)
                                                    }
                                                >
                                                    <span>PNG</span>
                                                </button>
                                            )}
                                            {exportButton}
                                            {isLaunchable &&
                                                graph.representation !==
                                                    "histogramme" &&
                                                graph.representation !==
                                                    "histogramme-normalise" &&
                                                graph.representation !==
                                                    "courbes-historiques" && (
                                                    <button
                                                        className="btn block-row-search bouton-flex-search"
                                                        onClick={() =>
                                                            window.open(
                                                                this.builtPoiOrAnalysisUrl(
                                                                    currentZoneType,
                                                                    currentZoneId,
                                                                    currentMaille,
                                                                    graph.id_analysis,
                                                                    theme
                                                                ),
                                                                "_blank"
                                                            )
                                                        }
                                                    >
                                                        <span
                                                            className="bi bi-search"
                                                            title="Visualiser l'indicateur"
                                                        ></span>
                                                    </button>
                                                )}
                                            {methodoPdf}
                                            {unitSelection}
                                        </div>
                                    </div>
                                )}
                                {bottomLegend}
                                <div className="analyse">{graphiques}</div>
                            </div>
                        );
                    } else {
                        let graphiqueRestitue = (
                            <div
                                key={
                                    graph.id_analysis +
                                    "_" +
                                    graph.representation +
                                    "_" +
                                    graph.numero_analyse
                                }
                                className="graphs"
                                id={"graph_" + i}
                            >
                                <FabriqueRepresentation
                                    // FabriqueRepresentation instancie la représentation précisée dans la propriété representation
                                    // Ici, elle permet d'instancier les graphiques sans déclinaison par catégorie (exemple : jauge territoire)
                                    parentApi={this.props.parentApi}
                                    zoneType={currentZoneType}
                                    zoneId={currentZoneId}
                                    zoneMaille={currentMaille}
                                    id_analysis={graph.id_analysis}
                                    representation={graph.representation}
                                    thematique={ligne.titre}
                                    id={
                                        currentZoneType +
                                        "-" +
                                        currentZoneId +
                                        "-" +
                                        currentMaille +
                                        "-" +
                                        graph.numero_analyse
                                    }
                                    type={graph.representation}
                                    key={graph.id_analysis + "_" + graph.representation}
                                    tailleSVG={graph.tailleSVG}
                                    tailleData={graph.tailleData}
                                    image={graph.imageSVG}
                                    provenance="tableau_de_bord_restitue"
                                    couleur={graph.couleur}
                                    nom={graph.nom}
                                    id_tableau_bord={this.state.id_tableau_bord}
                                    width={450}
                                    height={400}
                                    unit={unitId}
                                    filters={graph.filters}
                                    options={graph.options}
                                    content={graph.content}
                                />
                            </div>
                        );
                        graphiques.push(graphiqueRestitue);
                        groupeGraphiques.push(
                            <div
                                ref={ref}
                                className={
                                    "formulaire-thematique formulaire-thematique-restitue " +
                                    classeRepresentation +
                                    " graph-" +
                                    graph.representation +
                                    " group-" +
                                    groupeGraphiques.length
                                }
                                key={"indicateur_" + graph.numero_analyse}
                                style={{
                                    maxWidth: this.props.parentApi.callbacks.tailleDiv,
                                    height: heightJauge,
                                }}
                            >
                                <h4 className="nom-indicateur">{nomComplet}</h4>
                                {![
                                    "analysis-launcher",
                                    "logo",
                                    "didactic-file-launcher",
                                    "link-launcher",
                                ].includes(graph.representation) && (
                                    <div className="export-methodo-buttons">
                                        <div className="selection-graph bouton-methodo">
                                            {isPrintable && (
                                                <button
                                                    type="button"
                                                    className="btn btn-info btn-save block-row bouton-flex liste-modalites-diagramme"
                                                    onClick={() =>
                                                        saveAsPng(ref, nomComplet)
                                                    }
                                                >
                                                    <span>PNG</span>
                                                </button>
                                            )}
                                            {isLaunchable &&
                                                graph.representation !==
                                                    "histogramme" &&
                                                graph.representation !==
                                                    "histogramme-normalise" &&
                                                graph.representation !==
                                                    "courbes-historiques" && (
                                                    <button
                                                        className="btn block-row-search bouton-flex-search"
                                                        onClick={() =>
                                                            window.open(
                                                                this.builtPoiOrAnalysisUrl(
                                                                    currentZoneType,
                                                                    currentZoneId,
                                                                    currentMaille,
                                                                    graph.id_analysis,
                                                                    theme
                                                                ),
                                                                "_blank"
                                                            )
                                                        }
                                                    >
                                                        <span
                                                            className="bi bi-search"
                                                            title="Visualiser l'indicateur"
                                                        ></span>
                                                    </button>
                                                )}
                                            {graph.representation === "poi_map" && (
                                                <a
                                                    className="bi bi-search block-row-search"
                                                    href={this.builtPoiOrAnalysisUrl(
                                                        currentZoneType,
                                                        currentZoneId,
                                                        currentMaille,
                                                        graph.id_analysis,
                                                        graph.id_analysis,
                                                        true
                                                    )}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    title="Visualiser les équipements"
                                                    style={{ color: "black" }}
                                                >
                                                    {" "}
                                                </a>
                                            )}
                                            {methodoPdf}
                                            {unitSelection}
                                        </div>
                                    </div>
                                )}
                                <div className="analyse">{graphiques}</div>
                                {bottomLegend}
                            </div>
                        );
                    }
                }
                i += 1;
            }

            let tousGraphiquesPourLigne = (
                <div className="structure-thematique">{groupeGraphiques}</div>
            );
            graphiquesParThematique[ligne.id] = tousGraphiquesPourLigne;
        }
        return graphiquesParThematique;
    }

    /**
     * Display the full graphs dashboard by listing thematical groups first,
     * and then, inside each group, all territorial graphs.
     *
     * @param {array} finalGraphs all graphs to display grouped by territory
     * @param {array} groups all groups of graphs to display
     * @returns JSX tree
     */
    displayDashboard(finalGraphs, groups) {
        return (
            <div
                key={"res-finale"}
                className={"corps-tableau-bord territories-are-" + finalGraphs.length}
            >
                {groups.map((group, id) => {
                    let titreThematique = group.titre;

                    let descriptionThematique = group.description;
                    if (titreThematique === "Titre de la thématique") {
                        titreThematique = "";
                    }
                    if (descriptionThematique === "Description de la thématique") {
                        descriptionThematique = "";
                    }
                    let groupTitle = (
                        <div className="dashboard-group-title">
                            <h3 style={{ fontWeight: "bold" }}>{titreThematique}</h3>
                            <TextBlock content={descriptionThematique} />
                        </div>
                    );

                    return (
                        <div
                            className={`dashboard-group dashboard-group-${id}`}
                            key={group.titre + id}
                        >
                            {groupTitle}

                            <div className="graphs-inside-group">
                                {finalGraphs.map((graphs, tId) => {
                                    // we add a key to make sure React does not render the same component twice
                                    // or mix up the graphics when adding/removing territories
                                    let subGroupKey = "";
                                    // if we are handling main territory choice (i.e., territory inside index.js / parentApi.data)
                                    if (tId === 0) {
                                        subGroupKey = "main-territory";
                                    }
                                    // otherwise, we are handling a territory choosen by the user, thus in the
                                    // state.otherTerritories array
                                    else {
                                        subGroupKey =
                                            this.state.otherTerritories[tId - 1].zone
                                                .zone +
                                            "-" +
                                            this.state.otherTerritories[tId - 1].zone
                                                .maille +
                                            "-" +
                                            this.state.otherTerritories[tId - 1].zoneId;
                                    }

                                    // we display current territory's graphs
                                    return (
                                        <div
                                            className="regional-graphs"
                                            key={subGroupKey}
                                        >
                                            {graphs[group.id]}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    /**
     * This function allows to change the display value when the user
     * clicks on the "export PDF" button.
     */
    handlePdfDownload() {
        this.setState({
            isLoadingPDF: true,
            displayPdfVersion: true,
        });
    }

    /**
     * Saving dashboard as PDF file.
     */
    saveAsPdf(callback) {
        let input = document.querySelector(".pdf-pages") || document.body;
        let date = new Date();

        html2canvas(input, {
            allowTaint: true,
            useCORS: true,

            scale: 3,
            letterRendering: true,
            removeContainer: true,
            onclone: (document) => {
                Array.from(
                    document.querySelectorAll(
                        ".formulaire-thematique h4, .formulaire-thematique .analysis-launcher-button"
                    )
                ).forEach((e) => {
                    let existingStyle = e.getAttribute("style") || "";
                    e.setAttribute(
                        "style",
                        existingStyle +
                            "; display:block; word-break: keep-all; text-align: center;"
                    );
                });
                Array.from(document.querySelectorAll(".do-not-print")).forEach((e) => {
                    e.setAttribute("hidden", true);
                });
            },
        })
            .then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF("p", "mm", [
                    input.scrollHeight,
                    input.scrollWidth,
                ]);
                const width = pdf.internal.pageSize.getWidth();
                let ratio = input.offsetHeight / input.offsetWidth;
                pdf.addImage(
                    imgData,
                    "png",
                    10,
                    10,
                    width - 20,
                    ratio * (width - 20),
                    "",
                    "FAST"
                );
                pdf.save(
                    "tableau-de-bord-" +
                        slugify(
                            this.state.donneesDuTableauDeBordARestituer?.titre ??
                                "inconnu"
                        ) +
                        "-" +
                        date.getDate() +
                        "-" +
                        (date.getMonth() + 1) +
                        "-" +
                        date.getFullYear() +
                        ".pdf"
                );
            })
            .then(() => {
                this.setState({
                    isLoadingPDF: false,
                    displayPdfVersion: false,
                });
            });
    }

    /**
    Formate les données au format attendu par le composant TableauBord (nécessaire à
    l'initialisation au chargement de this.props.parentApi.data.tableauBordDonnees)
    * @param {object} données: données propre à une thématique enregistrée en base de données
    La structure de ce paramètre est :
    {
        id : identifiant
        titre : titre
        identifiant_tableau : identifiant du tableau de bord
        graphiques : [
            {
                identifiant_analyse : identifiant de l'analyse
                representation: représentation
                categories {
                    première catégorie {
                        nom : nom de la catégorie (ex : secteurs, energies, usages, type_prod_enr etc.)
                        titre : titre de la catégorie (ex : Par secteurs, Par filières de production etc.)
                        visible : true si on doit l'afficher false sinon
                    }
                    ...
                }
            },
            ...
        ]
    }
    */
    formaterDonneesThematiques(donnees) {
        let donneesformatees = {};
        for (let a in donnees) {
            let graphiques = {};
            for (let g in donnees[a].graphiques) {
                graphiques[donnees[a].graphiques[g].numero_analyse] =
                    donnees[a].graphiques[g];
            }
            donneesformatees[a] = {
                indicateurs: graphiques,
                description_thematique: donnees[a].description,
                titre_thematique: donnees[a].titre,
                ordre: donnees[a].ordre,
            };
        }
        return donneesformatees;
    }

    /**
    Déclenchée sur événement.
    Réinitialise la variable this.props.parentApi.data.tableauBordDonnees
    selon les données du tableau de bord chargé par un.e utilisateur.rice
    * @param {number} id_tableau_bord: identifiant du tableau de bord sélectionné
    */
    chargerTableauBord(id_tableau_bord) {
        let tableauBordCourant = this.state.donneesDuTableauDeBordARestituer;

        tableauBordCourant["donnees"] = this.formaterDonneesThematiques(
            tableauBordCourant.thematiques
        );
        tableauBordCourant["metadonnees"] = {
            titre: tableauBordCourant.titre,
            description: tableauBordCourant.description,
        };
        // delete tableauBordCourant["thematiques"];
        this.props.parentApi.callbacks.chargerTableauBord(tableauBordCourant);
        this.props.parentApi.callbacks.updateAnalysis(
            "creation_tableaux_bord",
            { fromMenu: true },
            tableauBordCourant
        );
    }

    /**
     * Handle toggling territory comparison.
     * @param {object} e event triggered when toggling switch
     */
    toggleTerritoryComparison(e) {
        this.setState({
            displayTerritoriesChoice: !this.state.displayTerritoriesChoice,
        });
    }

    /**
     * Filter URL to retrieve territories.
     */
    getComparedTerritoriesFromUrl() {
        const { urlPartageable } = this.props.parentApi.data;
        const regexp = /&c(\d+)(Zone|Maille|ZoneId)=([^&]*)/g;
        const urlRelevantParts = [...urlPartageable.matchAll(regexp)];
        let distinctTerritories = {};
        urlRelevantParts.forEach((part) => {
            if (!distinctTerritories[part[1]]) {
                distinctTerritories[part[1]] = {};
            }
            distinctTerritories[part[1]][part[2].toLowerCase()] = part[3];
        });
        return distinctTerritories;
    }

    /**
     * Create URL sharing territories comparison.
     */
    getComparedTerritoriesToUrl() {
        const { otherTerritories } = this.state;
        let url = "";
        otherTerritories.forEach((territory, index) => {
            const prefixeComp = "&c" + index;
            url +=
                prefixeComp +
                "Zone=" +
                territory.zone.zone +
                prefixeComp +
                "Maille=" +
                territory.zone.maille +
                prefixeComp +
                "ZoneId=" +
                territory.zoneId;
        });
        return url;
    }

    /**
     * Remove territory from territorial comparison.
     * @param {number} index index of territory to remove
     */
    removeTerritory(index) {
        const { otherTerritories } = this.state;
        // we remove the territory from the list of territories to compare
        const poppedTerritory = otherTerritories.splice(index, 1)[0];
        // we update the URL
        const currentTerritoriesInUrl = this.getComparedTerritoriesFromUrl();
        // we first check which index the territory has in the URL
        const indexOfTerritory = Object.keys(currentTerritoriesInUrl).find((key) => {
            return (
                currentTerritoriesInUrl[key].zone === poppedTerritory.zone.zone &&
                currentTerritoriesInUrl[key].maille === poppedTerritory.zone.maille &&
                currentTerritoriesInUrl[key].zoneid === poppedTerritory.zoneId
            );
        });
        // removing the territory from current URL
        let url = this.props.parentApi.data.urlPartageable;
        url = url.replace(
            new RegExp("&c" + indexOfTerritory + "(Zone|Maille|ZoneId)=([^&]+)", "g"),
            ""
        );
        // we shift the other territories in the URL to the left, i.e., every index
        // after the removed territory is decreased by 1.
        // E.g., if we removed the territory with index 2, the territory with
        // index 3 becomes the territory with index 2, the #4 becomes #3 and so on.
        // #0 and #1 do not change.
        Object.keys(currentTerritoriesInUrl).forEach((key) => {
            if (key > indexOfTerritory) {
                url = url.replace(
                    new RegExp("&c" + key + "(Zone|Maille|ZoneId)=([^&]+)", "g"),
                    (match, p1, p2) => {
                        return "&c" + (key - 1) + p1 + "=" + p2;
                    }
                );
            }
        });

        // we finally update state and URL
        this.props.parentApi.callbacks.mettreAJourParametresUrls(
            url,
            "Tableau de bord"
        );
        this.setState({ otherTerritories });
    }

    /**
     * Display territory or territories names.
     * @param {str} zoneInfos current zone name
     * @returns JSX tree show territory choice
     */
    displayTerritoriesNames(zoneInfos) {
        if (zoneInfos.length === 1) {
            return (
                <div className="dashboard-territory-choice">
                    <h2>
                        Territoire{zoneInfos.length > 1 ? "s" : ""} : {zoneInfos[0]}
                    </h2>
                </div>
            );
        }
        return (
            <div
                className={
                    "dashboard-territory-choice territories-are-" + zoneInfos.length
                }
            >
                <h2>Territoire{zoneInfos.length > 1 ? "s" : ""} :</h2>
                <div className="territories-names">
                    {zoneInfos.map((zone, index) => {
                        return (
                            <h3 key={index}>
                                {index + 1} - {zone}{" "}
                                {index > 0 && (
                                    <button
                                        className="btn btn-danger do-not-print"
                                        onClick={() => {
                                            // minus 1 because zoneInfos include the current territory
                                            // whereas the other territories do not
                                            this.removeTerritory(index - 1);
                                        }}
                                    >
                                        X
                                    </button>
                                )}
                            </h3>
                        );
                    })}
                </div>
            </div>
        );
    }

    addNewTerritoryToComparison(territoryInChoice) {
        // if territory already present => no need to add
        if (
            this.state.otherTerritories.filter((territory) => {
                return (
                    territory.zoneId === territoryInChoice.zoneId &&
                    territory.zone.zone === territoryInChoice.zone.zone &&
                    territory.zone.maille === territoryInChoice.zone.maille
                );
            }).length > 0
        ) {
            return;
        }
        // if territory == current territory => no need to add
        if (
            this.props.parentApi.data.currentZone === territoryInChoice.zoneId &&
            this.props.parentApi.data.zone.zone === territoryInChoice.zone.zone &&
            this.props.parentApi.data.zone.maille === territoryInChoice.zone.maille
        ) {
            return;
        }

        // adding the territory to the list of other territories in the URL
        let url = this.props.parentApi.data.urlPartageable;
        const prefixeComp = "&c" + this.state.otherTerritories.length;
        if (
            url.indexOf(prefixeComp + "Zone") === -1 &&
            url.indexOf(prefixeComp + "Maille") === -1 &&
            url.indexOf(prefixeComp + "ZoneId") === -1
        ) {
            url +=
                prefixeComp +
                "Zone=" +
                territoryInChoice.zone.zone +
                prefixeComp +
                "Maille=" +
                territoryInChoice.zone.maille +
                prefixeComp +
                "ZoneId=" +
                territoryInChoice.zoneId;
            this.props.parentApi.callbacks.mettreAJourParametresUrls(
                url,
                "Tableau de bord"
            );
        }
        this.setState({
            territoryInChoice: undefined,
            displayTerritoriesChoice: false,
            otherTerritories: [...this.state.otherTerritories, territoryInChoice],
        });
    }

    addCurrentTerritoryToComparison() {
        const { territoryInChoice } = this.state;
        // if no territory selected => no need to add
        if (territoryInChoice === null) return;
        // if not regional level => need to choose a precise territory
        if (territoryInChoice.zone.zone !== "region" && !territoryInChoice.zoneId) {
            return;
        }
        // we add current territory to comparisons
        this.addNewTerritoryToComparison(territoryInChoice);
    }

    updateOtherTerritoryChoice(newTerritory) {
        if (this.state.territoryInChoice === newTerritory) return;
        this.setState({
            territoryInChoice: {
                zone: { zone: newTerritory.zoneType, maille: newTerritory.zoneMaille },
                zoneId: newTerritory.zoneId,
            },
        });
    }

    /**
     * Display territory or territories names.
     * @returns JSX tree show territory choice
     */
    displayTerritoryChoice() {
        return (
            <div>
                <ZoneSelect
                    parentApi={this.props.parentApi}
                    onSelect={this.updateOtherTerritoryChoice.bind(this)}
                />
                <button
                    className="btn btn-primary"
                    onClick={this.addCurrentTerritoryToComparison.bind(this)}
                >
                    Ajouter
                </button>
            </div>
        );
    }

    launchPrint() {
        if (this.state.otherTerritories.length > 0) {
            var style = document.createElement("style");
            style.innerHTML = "@media print { @page {size: landscape} }";
            document.head.appendChild(style);
            window.onafterprint = () => {
                if (style) {
                    document.head.removeChild(style);
                    style = undefined;
                }
            };
        }
        window.print();
    }

    render() {
        if (this.state.isGettingData) {
            return (
                <div className="plan-actions widgets full-screen-widget">
                    <div className="loader centered-widget"></div>
                </div>
            );
        }

        if (!this.state.donneesDuTableauDeBordARestituer) {
            return (
                <div className="plan-actions widgets full-screen-widget">
                    <SEO
                        settings={this.props.parentApi.data.settings["seo"]}
                        page="dashboard"
                        seoDetails={this.state.dataReferencement}
                    />
                    <Link
                        className="back-to-map"
                        to={"/" + this.props.parentApi.data.urlPartageable}
                    >
                        <button
                            type="button"
                            className="close close-big"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Link>{" "}
                    {/* Retour à la carte */}
                    <div className="pdf-pages">
                        <div className="creation-tableaux-bord">
                            <div className="title centered-row">
                                <p className="action-title">
                                    Tableau de bord non disponible
                                </p>
                            </div>
                            <p>
                                Ce tableau n'existe pas, n'a pas été publié ou encore
                                n'est pas accessible à cette échelle.
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
        // Territorial data
        const currentZone = this.props.parentApi.data.zone;
        const currentZoneType = this.props.parentApi.data.zone.zone;
        const currentMaille = this.props.parentApi.data.zone.maille;
        const currentZoneId = this.props.parentApi.data.currentZone;

        // Données du tableau
        let titreTableauBord = this.state.donneesDuTableauDeBordARestituer.titre;
        let descriptionTableauBord = (
            <div className="description-tableau-bord">
                <TextBlock
                    content={this.state.donneesDuTableauDeBordARestituer.description}
                />
            </div>
        );
        let zoneInfos = "";
        let zoneName = this.props.parentApi.controller.zonesManager.getZoneName(
            currentZoneId,
            currentZone
        );

        // Affichage des informations sur la zone géographique
        if (currentZoneType === "region" && this.state.otherTerritories.length > 0) {
            zoneInfos =
                this.props.parentApi.controller.zonesManager.getZoneLevelName(
                    currentZone
                );
        } else if (currentZoneType === "region") {
            zoneInfos = "Région";
        } else if (!zoneName && currentZoneType !== "region") {
            zoneInfos = this.props.parentApi.data.nomTerritoire;
        } else {
            zoneInfos = zoneName;
        }

        // Main region graph
        let mainGraphs = this.getGraphsByGroupOneTerritory(
            currentZoneType,
            currentZoneId,
            currentMaille
        );
        // add other territories names
        let allZones = [zoneInfos];
        let allGraphs = [mainGraphs];
        this.state.otherTerritories.forEach((territory) => {
            // Add names
            let otherZoneName = "";
            if (territory.zone.zone === "region") {
                otherZoneName =
                    this.props.parentApi.controller.zonesManager.getZoneLevelName(
                        territory.zone
                    );
            } else {
                otherZoneName =
                    this.props.parentApi.controller.zonesManager.getZoneName(
                        territory.zoneId,
                        territory.zone
                    );
            }
            allZones.push(otherZoneName);

            // Add graphs
            const graphs = this.getGraphsByGroupOneTerritory(
                territory.zone.zone,
                territory.zoneId,
                territory.zone.maille
            );
            allGraphs.push(graphs);
        });

        let thematicGroups = this.state.donneesDuTableauDeBordARestituer.thematiques;

        const restitutionFinale = this.displayDashboard(allGraphs, thematicGroups);

        // Création des outils pour l'export en PDF
        let pdfUrl = createPdfMethodoLink(
            config.methodo_url,
            this.props.parentApi.data.region,
            configData.methodoCreationTableauBord
        );

        // Ajout lien de modification
        let modifButton = "";
        if (this.state.donneesDuTableauDeBordARestituer.user_table) {
            modifButton = (
                <button
                    className={
                        "btn btn-success edit-button-top-tableau bouton-personnalisable-" +
                        this.props.parentApi.data.theme
                    }
                    onClick={() =>
                        this.chargerTableauBord(
                            this.state.donneesDuTableauDeBordARestituer.id
                        )
                    }
                >
                    Modifier le tableau
                </button>
            );
        }
        let dataReferencement = titreTableauBord + " - " + zoneName;
        return (
            <div
                key={"tableauxBord"}
                className="plan-actions widgets full-screen-widget"
            >
                <SEO
                    settings={this.props.parentApi.data.settings["seo"]}
                    page="dashboard"
                    seoDetails={dataReferencement}
                />
                <Link
                    className="back-to-map"
                    to={"/" + this.props.parentApi.data.urlPartageable}
                >
                    <button
                        type="button"
                        className="close close-big"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Link>{" "}
                <div className="img only-print">
                    <img
                        src="../../img/logo_TerriSTORY_region_aura_small.png"
                        alt="Logo de TerriSTORY"
                    ></img>
                </div>
                {/* Retour à la carte */}
                <div className="export-tdb-methodo-pdf">
                    <a href={pdfUrl} target="_blank" rel="noreferrer">
                        <div className="help" style={{ marginTop: "11.5px" }}></div>
                    </a>
                    <button
                        type="button"
                        className={
                            "btn download download-" + this.props.parentApi.data.theme
                        }
                        onClick={() => this.launchPrint()}
                        disabled={this.state.isLoadingPDF}
                    >
                        {this.state.isLoadingPDF && (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm me-1"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </>
                        )}
                        Télécharger PDF
                    </button>
                    {modifButton}
                </div>
                <div className="pdf-pages">
                    <div className="creation-tableaux-bord">
                        {this.state.displayPdfVersion && (
                            <div
                                className={
                                    "pdf-logo pdf-logo-" +
                                    this.props.parentApi.data.theme
                                }
                            ></div>
                        )}
                        <div className="title centered-row">
                            <h1 className="action-title">{titreTableauBord}</h1>
                        </div>
                        {descriptionTableauBord}
                        {this.state.otherTerritories.length < 2 && (
                            <div className="toggle-comparison do-not-print">
                                <button
                                    className="btn btn-warning"
                                    onClick={(e) => this.toggleTerritoryComparison(e)}
                                >
                                    {this.state.displayTerritoriesChoice
                                        ? "Cacher le choix de territoire"
                                        : "Comparer avec un autre territoire"}
                                </button>
                                {this.state.displayTerritoriesChoice &&
                                    this.displayTerritoryChoice()}
                            </div>
                        )}
                    </div>
                    {this.displayTerritoriesNames(allZones)}
                    <p className="only-print background-warning">
                        Attention : Si les couleurs ne s'affichent pas dans les
                        légendes, vérifiez que l'impression des arrière-plans est bien
                        activée
                    </p>
                    {restitutionFinale}
                    {this.state.displayPdfVersion && (
                        <div className="footer-charts">
                            Données diffusées par TerriSTORY®{" "}
                            {this.props.parentApi.data.regionLabel},{" "}
                            {new Date().getFullYear()}.
                        </div>
                    )}
                    <div className="do-not-print" style={{ height: "300px" }}></div>
                </div>
                <div className="only-print">
                    <p>
                        Ce tableau de bord a été exporté de TerriSTORY®. Il est
                        disponible à{" "}
                        <a href={window.location.href}>l'adresse suivante</a>.
                    </p>
                </div>
            </div>
        );
    }
}

export default DashboardShow;
